import { AppBar, Box, Button, CircularProgress, Collapse, IconButton, InputAdornment, MenuItem, Paper, Select, Slide, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import Service from '../../Networking/networkutils';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import moment from 'moment';
// import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WorkIcon from '@mui/icons-material/Work';
import Allocation from '../../Allocation/Allocation';
import FilterListIcon from '@mui/icons-material/FilterList';
import { getDarkMode } from '../../Helper/CommonThings';
import { getMode } from '../../MainDashBoard/ColorTheme/ColorTheme';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

const token = getCookie("bb_ts_token");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let P6Columns = [
  { name: 'P6 ID', width: 150, minWidth: 150 },
  { name: 'P6 Description' },
  { name: 'P6 Baseline Start/Finish', type: 'date' },
  { name: 'P6 %', type: 'number', width: 100, minWidth: 100 },
  { name: 'P6 Expected Start/Finish', type: 'date' },
  { name: '', width: 50, minWidth: 50 },
  { name: 'Updated %', type: 'date', width: 100, minWidth: 100 },
  { name: 'Updated Expected Start/Finish', type: 'date' },
  { name: 'Reason For Change', width: 400, minWidth: 400 },
]

let P6DataColumns = [
  { name: 'p6_id' },
  { name: 'name' },
  {
    name: 'from_date',
    render: (row) => <Box display={'flex'} flexDirection='column' justifyContent={'center'} width={250} padding="5px">
      <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%'>
        <b>Start:</b>&nbsp;{row.from_date ? moment(row.from_date).format('DD-MM-YYYY') : null}
      </Box>
      <Box display={'flex'} flexDirection='row' marginTop='5px' alignItems={'center'} width='100%'>
        <b>Finish:</b>&nbsp; {row.to_date ? moment(row.to_date).format('DD-MM-YYYY') : null}
      </Box>
    </Box>
  },
  { name: 'percent_complete', render: (row) => Number(row.percent_complete) > -1 ? row.percent_complete + '%' : null, type: 'number' },
  {
    name: 'act_exp_start',
    render: (row) => <Box display={'flex'} flexDirection='column' justifyContent={'center'} width={250} padding="5px">
      <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%'>
        <b>Start:</b>&nbsp;{row.act_exp_start ? moment(row.act_exp_start).format('DD-MM-YYYY') : null}
      </Box>
      <Box display={'flex'} flexDirection='row' marginTop='5px' alignItems={'center'} width='100%'>
        <b>Finish:</b>&nbsp;{row.act_exp_finish ? moment(row.act_exp_finish).format('DD-MM-YYYY') : null}
      </Box>
    </Box>
  },
  {
    name: "", field: 'p6', filtering: false, width: 20,
    render: (rowData) =>
      <Tooltip title="Update as Configured" placement="top">
        <IconButton size='small' onClick={() => window.PIMHandle.updateAsConfigured(rowData)}>
          <TrackChangesIcon fontSize='small' />
        </IconButton>
      </Tooltip>
  },
  { name: 'updated_comp_percnt', render: (row) => row.updated_comp_percnt !== null ? row.updated_comp_percnt + '%' : null, type: 'number' },
  {
    name: 'updated_act_exp_start',
    render: (row) => <Box display={'flex'} flexDirection='column' justifyContent={'center'} width={250} padding="5px">
      <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%'>
        <b>Start:</b>&nbsp;{row.updated_act_exp_start ? moment(row.updated_act_exp_start).format('DD-MM-YYYY') : null}
      </Box>
      <Box display={'flex'} flexDirection='row' marginTop='5px' alignItems={'center'} width='100%'>
        <b>Finish:</b>&nbsp;{row.updated_act_exp_finish ? moment(row.updated_act_exp_finish).format('DD-MM-YYYY') : null}
      </Box>
    </Box>
  },
  { name: 'reason_for_change', },
]

let PercentageComplete = [
  {
    value: 0, label: '0% - Not started.'
  },
  {
    value: 5, label: '5% - Preparation of documents/drawings.'
  },
  {
    value: 10, label: '10% - Preparation of documents/drawings.'
  },
  {
    value: 20, label: '20% - Preparation of documents/drawings.'
  },
  {
    value: 30, label: '30% - Preparation of documents/drawings.'
  },
  {
    value: 40, label: '40% - Preparation of documents/drawings.'
  },
  {
    value: 50, label: '50% - Preparation of documents/drawings.'
  },
  {
    value: 60, label: '60% - Checked within Department.'
  },
  {
    value: 65, label: '65% - Approved within Department.'
  },
  {
    value: 70, label: '70% - Site comments received, reviewed.'
  },
  {
    value: 80, label: '80% - Multi-disciplinary Reviewed & Commented.'
  },
  {
    value: 90, label: '90% - Design verified.'
  },
  {
    value: 100, label: '100% - Design assured.'
  },
]

class PIMHandle extends Component {
  constructor(props) {
    super(props)
    window.PIMHandle = this
    this.state = {
      data: [],
      isLoading: true,
      openDateUpdateDialog: false,
      row: {},
      open: false,
      isP6: true,
      isEdit: false,
      listItemEditData: [],
      rowID: [],
      openEditDialog: false,
      p6LinkedData: null,
      openTaskLevelAllocation: false,
      allData: null,
      filters: {},
      copyData: [],
      expand: false,
      zoom: localStorage.getItem('zoom_level') ? JSON.parse(localStorage.getItem('zoom_level')) : 1,
    }
  }

  getData() {
    Service.get(`/bb/api/p6/activity/update/get_p6_activity_details/?project_id=${this.props.data.id}`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        res.data.forEach(element => {
          element.p6_data.isEdit = false
          element.p6_data.copy_reason_for_change = element.p6_data.reason_for_change
        });

        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if (this.state.rowID.includes(element.p6_data.id)) {
            element.open = true
          } else {
            element.open = false
          }
          for (let i = 0; i < element.p6_data.list_task.length; i++) {
            const element1 = element.p6_data.list_task[i];
            element1.isEdit = false
            element1.copy_act_exp_start = element1.act_exp_start
            element1.copy_act_exp_finish = element1.act_exp_finish
            element1.copy_comp_percnt = element1.comp_percnt
          }
        }

        this.setState({
          isEdit: false,
          data: res.data,
          copyData: res.data,
          isLoading: false
        }, () => {
          this.handleFilterChange(null)
        });
      })
      .catch(error => {
        errorMessage(this.props, error)
      });
  }

  getUsers = () => {
    Service.get(`/bb/api/users/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        this.setState({
          usersData: res.data
        });
      })
      .catch(error => {
        errorMessage(this.props, error)
      });
  }

  componentDidMount() {
    this.getUsers()
    this.getData()
    if (this.props.getExpand) {
      this.props.getExpand(this.state.expand)
    }
    localStorage.setItem('zoom_level', this.state.zoom)
  }

  openListTasks = (row, i) => {
    let data = this.state.data
    data[i].open = !data[i].open

    this.setState({
      data: data,
      isEdit: false,
    })
    if (row.open) {
      let rowID = this.state.rowID
      if (!rowID.includes(row.p6_data.id)) {
        rowID.push(row.p6_data.id)
        this.setState({
          rowID: rowID
        })
      }
    } else {
      let rowID = this.state.rowID
      rowID = rowID.filter((item) => item !== row.p6_data.id)
      this.setState({
        rowID: rowID
      })
    }
  }

  updateActualExpectedDates = () => {
    if (this.state.row.p6_data.updated_act_exp_start === null || this.state.row.p6_data.updated_act_exp_finish === null || this.state.row.p6_data.reason_for_change === null || this.state.row.p6_data.percent_complete === null) {
      this.props.enqueueSnackbar("Please fill all the fields", { variant: 'warning' })
      return
    } else {
      Service.patch(`/bb/api/p6/activity/update/update_p6_activity_data/`, {
        headers: {
          Authorization: "Token " + token,
        },
        data: {
          item_id: this.state.row.p6_data.id,
          percent_complete: this.state.row.p6_data.percent_complete,
          updated_act_exp_start: this.state.row.p6_data.updated_act_exp_start,
          updated_act_exp_finish: this.state.row.p6_data.updated_act_exp_finish,
          reason_for_change: this.state.row.p6_data.reason_for_change
        }
      })
        .then(res => {
          this.getData(this.state.rowID)
          this.setState({
            openDateUpdateDialog: false,
            row: {}
          })
          this.props.enqueueSnackbar("Updated Successfully", { variant: 'success' })
        })
        .catch(error => {
          errorMessage(this.props, error)
        });
    }
  }

  editListTaskDates = (i, j) => {
    let data = this.state.data
    data[i].p6_data.list_task[j].isEdit = !data[i].p6_data.list_task[j].isEdit
    this.setState({ data })
  }

  revokeChanges = (i, j) => {
    let data = this.state.data
    data[i].p6_data.list_task[j].isEdit = !data[i].p6_data.list_task[j].isEdit
    data[i].p6_data.list_task[j].act_exp_start = data[i].p6_data.list_task[j].copy_act_exp_start
    data[i].p6_data.list_task[j].act_exp_finish = data[i].p6_data.list_task[j].copy_act_exp_finish
    data[i].p6_data.list_task[j].comp_percnt = data[i].p6_data.list_task[j].copy_comp_percnt
    this.setState({ data })
  }

  bulkEditListItems = (data) => {
    Service.patch('/bb/api/tender/wbs/tasks/bulk_update_workbook/', {
      headers: {
        Authorization: "Token " + token,
      },
      data: data,
    })
      .then(res1 => {
        this.setState({
          openEditDialog: false,
        })
        this.getData(this.state.rowID)
        this.props.enqueueSnackbar('Data Updated Successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        console.log(error)
        errorMessage(this.props, error)
      });
  }

  editListTask = (rowData, key, value, row) => {
    for (let index = 0; index < rowData.length; index++) {
      const element = rowData[index];
      if (element.id === row.id) {
        element[key] = value
      }
    }
    this.setState({
      listItemEditData: rowData
    })
  }

  handleEditP6Data = (key, value) => {
    this.setState({
      row: {
        ...this.state.row,
        p6_data: {
          ...this.state.row.p6_data,
          [key]: value
        }
      }
    })
  }

  handleFilterChange = (e) => {
    let filters = this.state.filters
    if (e) {
      filters[e.target.id] = e.target.value
    }
    this.setState({
      filters: filters
    })
    // debugger
    const filteredData = this.state.copyData.filter((row) => {
      // If all filters are empty, return true for all rows
      const allFiltersEmpty = Object.values(this.state.filters).every(value => value === '');

      if (allFiltersEmpty) {
        return true;
      }

      // Otherwise, check if all filters match
      return Object.keys(filters).every(key => {
        if (filters[key] !== '' && row.p6_data[key] && (key === 'percent_complete' || key === 'updated_comp_percnt')) {
          return row.p6_data[key] === parseInt(filters[key]);
        }
        if (filters[key] !== '' && row.p6_data[key]) {
          return row.p6_data[key].toLowerCase().includes(filters[key].toLowerCase());
        }
        // If filter is empty, return true
        return true;
      });
    });

    this.setState({
      data: filteredData
    })
  }

  getFilterColumns = (column) => {
    if (column.filtering === false) {
      return null
    }
    return <TextField
      type={column.type ? column.type : 'text'}
      id={column.name}
      value={this.state.filters[column.name]}
      variant="standard"
      onChange={(e) => this.handleFilterChange(e)}
      InputProps={{
        startAdornment: column.type !== 'date' ? (
          <InputAdornment position="start">
            <FilterListIcon fontSize='small' />
          </InputAdornment>
        ) : null,
      }}
    />
  }

  setDefault = (data) => {
    Service.patch('/bb/api/p6/activity/update/update_p6_activity_date/', {
      headers: {
        Authorization: "Token " + token,
      },
      data: data,
    })
      .then(res => {
        this.getData(this.state.rowID)
        this.props.enqueueSnackbar('Data Updated Successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        console.log(error)
        errorMessage(this.props, error)
      });
  }

  openReasonEdit = (i) => {
    let data = this.state.data
    data[i].p6_data.isEdit = !data[i].p6_data.isEdit
    this.setState({ data })
  }

  handleReasonChange = (e, i) => {
    let data = this.state.data
    data[i].p6_data.reason_for_change = e.target.value
    this.setState({
      data: data
    })
  }

  discardChanges = (i) => {
    let data = this.state.data
    data[i].p6_data.reason_for_change = data[i].p6_data.copy_reason_for_change
    data[i].p6_data.isEdit = false
    this.setState({ data })
  }

  saveChanges = (i) => {
    let data = this.state.data

    if (!data[i].p6_data.reason_for_change) {
      this.props.enqueueSnackbar("Please add reason for change", { variant: 'warning' })
    } else {
      Service.patch(`/bb/api/p6/activity/update/update_p6_activity_data/`, {
        headers: {
          Authorization: "Token " + token,
        },
        data: {
          item_id: data[i].p6_data.id,
          // percent_complete: this.state.row.p6_data.percent_complete,
          // updated_act_exp_start: this.state.row.p6_data.updated_act_exp_start,
          // updated_act_exp_finish: this.state.row.p6_data.updated_act_exp_finish,
          reason_for_change: data[i].p6_data.reason_for_change
        }
      })
        .then(res => {
          this.getData(this.state.rowID)
          this.props.enqueueSnackbar("Updated Successfully", { variant: 'success' })
        })
        .catch(error => {
          console.log(error)
          errorMessage(this.props, error)
        });
    }
  }

  setExpand = () => {
    let data = this.state.data
    let rowID = []
    data.forEach(element => {
      element.open = !this.state.expand
      if (element.open) {
        rowID.push(element.p6_data.id)
      }
    });
    this.setState({
      expand: !this.state.expand,
      data: data,
      rowID: rowID
    })
  }

  decreaseZoom = () => {
    let zoom = (Number(this.state.zoom) - 0.1).toFixed(1);
    if (zoom < 0.5) return;
    this.setState({ zoom: zoom, })
    localStorage.setItem('zoom_level', zoom)
  }

  increaseZoom = () => {
    let zoom = (Number(this.state.zoom) + 0.1).toFixed(1);
    if (zoom > 1.3) return;
    this.setState({ zoom: zoom, })
    localStorage.setItem('zoom_level', zoom)
  }

  saveDisabled = (listRow) => {
    if ((!moment(listRow.act_exp_start).isValid()) || (listRow.timesheet_start === null ? false : listRow.timesheet_start !== null && moment(listRow.act_exp_start).format("YYYY-MM-DD") > moment(listRow.timesheet_start).format("YYYY-MM-DD"))) {
      return true
    }
    if ((!moment(listRow.act_exp_finish).isValid()) || (moment(listRow.act_exp_finish).toDate() < moment(listRow.act_exp_start).toDate())) {
      return true
    }
    return false
  }

  updateAsConfigured = (rowData) => {
    Service.get(`/bb/api/p6/activity/update/update_p6_data/?p6_id=${rowData.id}`, {
      headers: { Authorization: "Token " + token, },
    })
      .then(res => {
        let response = res.data
        var fields = ''
        if (response.default_baseline_comp_percnt) {
          fields = 'Complete %'
        }
        if (response.default_act_exp_start) {
          fields = fields ? fields + ', Expected Start' : 'Expected Start'
        }
        if (response.default_act_exp_finish) {
          fields = fields ? fields + ', Expected Finish' : 'Expected Finish'
        }
        if (fields) {
          this.props.enqueueSnackbar(`${fields} Updated Successfully!`, { variant: 'success' })
          if (this.props.refreshChart) {
            this.props.refreshChart()
          }
        } else {
          this.props.enqueueSnackbar('No updates found', { variant: 'info' })
        }
        this.setState({ updateDisabled: false })
        this.getData()
      })
      .catch(error => {
        console.log(error)
        this.setState({ updateDisabled: false })
        errorMessage(this.props, error)
      });

  }

  getBgColor = (row) => {
    if (row?.p6_data?.data_missing) {
      return getMode() ? '#ff0000' : '#ffb3b3'
    }
    // else if (row?.p6_data?.new_data) {
    //   return getMode() ? '#b3b300' : '#ffff00'
    // }
    return ''
  }

  render() {
    return (
      <div >
        <Box marginTop={'10px'}>
          {this.state.isLoading ?
            <Box display='flex' justifyContent='center' alignItems={'center'}>
              <CircularProgress />
            </Box> :
            <TableContainer component={Paper}>
              <Table stickyHeader size='small' sx={{ minWidth: 650, zoom: this.state.zoom - 0.1 }} aria-label="collapsible table">
                <TableHead sx={{ textAlign: 'left' }}>
                  <TableRow>
                    <TableCell sx={{ width: 20, minwidth: 20 }}>
                    </TableCell>
                    {P6Columns.map((column) => (
                      <TableCell sx={{ width: column.width, minWidth: column.minWidth, textAlign: 'left' }}>
                        {column?.name}
                      </TableCell>))}
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    {P6DataColumns.map((column) => (
                      <TableCell>
                        {this.getFilterColumns(column)}
                      </TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data.map((row, i) => (
                    <React.Fragment key={i}>
                      <TableRow
                        sx={{ '& > *': { borderBottom: 'unset' }, cursor: "pointer", background: this.getBgColor(row) }}
                        onClick={() => this.openListTasks(row, i)}>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => { e.stopPropagation(); this.openListTasks(row, i) }}>
                            {row.open ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                          </IconButton>
                        </TableCell>
                        {P6DataColumns.map((column) => (
                          column.name === 'reason_for_change' ?
                            <TableCell onClick={(e) => e.stopPropagation()} style={{ minWidth: 200 }}>
                              {row?.p6_data?.isEdit ?
                                <Box display="flex" flexDirection='row' onClick={(e) => e.stopPropagation()}>
                                  <TextField
                                    size='small'
                                    fullWidth
                                    label="Reason"
                                    variant="outlined"
                                    value={row?.p6_data?.reason_for_change}
                                    onChange={(e) => { e.stopPropagation(); this.handleReasonChange(e, i) }} />
                                  <Tooltip title="Save Changes">
                                    <IconButton size='small' onClick={(e) => { e.stopPropagation() }}>
                                      <CheckIcon fontSize="small" onClick={(e) => {
                                        e.stopPropagation()
                                        this.saveChanges(i)
                                      }} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Cancel Changes">
                                    <IconButton size='small' onClick={(e) => {
                                      e.stopPropagation()
                                      this.discardChanges(i)
                                    }}>
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                                :
                                <Box flexWrap={'wrap'} minWidth={200} minHeight={20} onClick={(e) => { e.stopPropagation(); this.openReasonEdit(i) }} fontSize={'12px'} display={'flex'} flexDirection={'row'} alignItems='center' style={{ borderBottom: '2px dotted', width: "100%", overflow: "hidden", textOverflow: "ellipsis", }}>
                                  {!row.p6_data.reason_for_change ? '' : row.p6_data.reason_for_change}
                                </Box>}
                            </TableCell> :
                            <TableCell>
                              {column.render ? column.render(row.p6_data) : row.p6_data[column.name]}
                            </TableCell>
                        ))}
                      </TableRow>
                      <TableRow key={i}>
                        <TableCell style={{ padding: 0 }} colSpan={14}>
                          <Collapse in={row.open} timeout="auto" unmountOnExit>
                            <Box sx={{ backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' }}>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>DS Name</TableCell>
                                    <TableCell>Task ID</TableCell>
                                    <TableCell>Task Name</TableCell>
                                    <TableCell>Complete %</TableCell>
                                    <TableCell>Expected Start</TableCell>
                                    <TableCell>Expected Finish</TableCell>
                                    <TableCell sx={{ width: 50 }} align='center'>Actions</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row.p6_data.list_task.map((listRow, j) => (
                                    <TableRow key={j}>
                                      <TableCell>{listRow.ds_name}</TableCell>
                                      <TableCell>{listRow.id}</TableCell>
                                      <TableCell>{listRow.task_name}</TableCell>
                                      <TableCell>
                                        {listRow.isEdit ?
                                          <Select
                                            size='small'
                                            value={listRow.comp_percnt}
                                            onChange={(e) => {
                                              this.editListTask(row.p6_data.list_task, 'comp_percnt', e.target.value, listRow)
                                            }}>
                                            {PercentageComplete.map((item) => (
                                              <MenuItem value={item.value}>{item.label}</MenuItem>))}
                                          </Select>
                                          : listRow.comp_percnt !== null ?
                                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                              <Checkbox
                                                color='primary'
                                                size='small'
                                                id='default_baseline_percentage'
                                                checked={listRow.default_baseline_comp_percnt}
                                                onChange={(e) => this.setDefault({ item_id: listRow.link_id, 'default_baseline_comp_percnt': !listRow.default_baseline_comp_percnt })} />
                                              {listRow.comp_percnt + "%"}
                                            </Box> : null}
                                      </TableCell>
                                      <TableCell>{listRow.isEdit ?
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          type='date'
                                          helperText={!moment(listRow.act_exp_start).isValid() ? "Invalid date format" : listRow.timesheet_start !== null && moment(listRow.act_exp_start).format("YYYY-MM-DD") > moment(listRow.timesheet_start).format("YYYY-MM-DD") ? "Actual Start date should be before or equals to " + moment(listRow.timesheet_start).format('DD/MM/YYYY') : ''}
                                          error={(!moment(listRow.act_exp_start).isValid()) || (listRow.timesheet_start === null ? false : listRow.timesheet_start !== null && moment(listRow.act_exp_start).format("YYYY-MM-DD") > moment(listRow.timesheet_start).format("YYYY-MM-DD"))}
                                          defaultValue={moment(listRow.act_exp_start).format('yyyy-MM-DD')}
                                          onChange={(e) => {
                                            this.editListTask(row.p6_data.list_task, 'act_exp_start', e.target.value, listRow)
                                          }}
                                        /> : listRow.act_exp_start ?
                                          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Checkbox
                                              size='small'
                                              color='primary'
                                              id='default_act_exp_start'
                                              checked={listRow.default_act_exp_start}
                                              disabled={!listRow.act_exp_start}
                                              onChange={(e) => this.setDefault({ item_id: listRow.link_id, 'default_act_exp_start': !listRow.default_act_exp_start })} />
                                            {moment(listRow.act_exp_start).format("DD-MM-YYYY")}
                                          </Box> : null}
                                      </TableCell>
                                      <TableCell>{listRow.isEdit ?
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          type='date'
                                          helperText={(!moment(listRow.act_exp_finish).isValid()) ? "Invalid date format" : moment(listRow.act_exp_finish).toDate() < moment(listRow.act_exp_start).toDate() ? "Actual/Expected Finish is greater than Actual/Expected Start" : ''}
                                          error={(!moment(listRow.act_exp_finish).isValid()) || (moment(listRow.act_exp_finish).toDate() < moment(listRow.act_exp_start).toDate())}
                                          defaultValue={moment(listRow.act_exp_finish).format('yyyy-MM-DD')}
                                          onChange={(e) => {
                                            this.editListTask(row.p6_data.list_task, 'act_exp_finish', e.target.value, listRow)
                                          }} /> : listRow.act_exp_finish ?
                                          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Checkbox
                                              color='primary'
                                              size='small'
                                              id='default_act_exp_finish'
                                              checked={listRow.default_act_exp_finish}
                                              disabled={!listRow.act_exp_finish}
                                              onChange={(e) => this.setDefault({ item_id: listRow.link_id, 'default_act_exp_finish': !listRow.default_act_exp_finish })} />
                                            {moment(listRow.act_exp_finish).format("DD-MM-YYYY")}
                                          </Box> : null}
                                      </TableCell>
                                      <TableCell align='center' sx={{ width: 50 }}>
                                        <Box display='flex' justifyContent='center' alignItems={'center'}>
                                          <IconButton size='small' onClick={() => this.setState({ allData: listRow, openTaskLevelAllocation: true })}>
                                            <WorkIcon fontSize="small" />
                                          </IconButton>
                                          <Box>
                                            {listRow.isEdit ?
                                              <Box display="flex" flexDirection='row'>
                                                <Tooltip title="Save Changes">
                                                  <IconButton size='small' disabled={this.saveDisabled(listRow)} onClick={() => this.bulkEditListItems(this.state.listItemEditData)}>
                                                    <CheckIcon fontSize="small" />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Cancel Changes">
                                                  <IconButton size='small' onClick={() => {
                                                    this.revokeChanges(i, j)
                                                  }}>
                                                    <CloseIcon fontSize="small" />
                                                  </IconButton>
                                                </Tooltip>
                                              </Box> :
                                              <Tooltip title="Edit Expected Dates">
                                                <IconButton size='small' onClick={() => this.editListTaskDates(i, j)}>
                                                  <EditNoteIcon fontSize="small" />
                                                </IconButton>
                                              </Tooltip>
                                            }
                                          </Box>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Box>

        <Dialog disableEnforceFocus open={this.state.openDateUpdateDialog} onClose={() => this.setState({ open: false })}>
          <DialogTitle>{this.state.isP6 ? 'Update P6 Activity' : 'Update List Task Item'}</DialogTitle>
          <DialogContent>
            <Box display='flex' flexDirection='column'>
              <Box marginTop={'10px'}>
                <TextField
                  id="date"
                  label="Completed Percentage"
                  // type="number"
                  defaultValue={this.state.row?.p6_data?.percent_complete}
                  onChange={(e) => { this.handleEditP6Data('percent_complete', e.target.value) }}
                  variant='outlined'
                  style={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box marginTop={'10px'}>
                <TextField
                  id="date"
                  label="Expected Start Date"
                  type="date"
                  defaultValue={this.state.isP6 ? moment(this.state.row?.p6_data?.updated_act_exp_start).format('yyyy-MM-DD') : moment(this.state.row?.act_exp_start).format('yyyy-MM-DD')}
                  onChange={(e) => { this.handleEditP6Data('updated_act_exp_start', e.target.value) }}
                  variant='outlined'
                  style={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box marginTop={'10px'}>
                <TextField
                  id="date"
                  label="Expected Finish Date"
                  type="date"
                  variant='outlined'
                  defaultValue={this.state.isP6 ? moment(this.state.row?.p6_data?.updated_act_exp_finish).format('yyyy-MM-DD') : moment(this.state.row?.act_exp_finish).format('yyyy-MM-DD')}
                  onChange={(e) => { this.handleEditP6Data('updated_act_exp_finish', e.target.value) }}
                  style={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              {this.state.isP6 ?
                <Box marginTop={'10px'}>
                  <TextField
                    style={{ width: 220 }}
                    label="Reason for Change"
                    variant="outlined"
                    autoFocus
                    defaultValue={this.state.row?.p6_data?.reason_for_change}
                    onChange={(e) => { this.handleEditP6Data('reason_for_change', e.target.value) }}
                  />
                </Box> : null}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDateUpdateDialog: false, row: {} })} color="primary">Close</Button>
            <Button onClick={() => this.updateActualExpectedDates()} color="primary">Submit</Button>
          </DialogActions>
        </Dialog>

        <Dialog disableEnforceFocus open={this.state.openEditDialog} onClose={() => this.setState({ openEditDialog: false })} maxWidth='lg'>
          <DialogTitle>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Edit List Task</Typography>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => this.setState({ openEditDialog: false })}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <Box margin={4} >
            <Box display={'flex'} justifyContent={'center'} alingItems='center'>
              <Stack direction='row' spacing={2}>
                <TextField
                  id="date"
                  label="Completed Percentage"
                  type="number"
                  defaultValue={this.state.p6LinkedData?.comp_percnt}
                  onChange={(e) => {
                    this.setState({
                      p6LinkedData: {
                        ...this.state.p6LinkedData,
                        comp_percnt: e.target.value
                      }
                    })
                  }}
                  variant='outlined'
                  style={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="Expected Start Date"
                  type="date"
                  defaultValue={moment(this.state.p6LinkedData?.act_exp_start).format('yyyy-MM-DD')}
                  onChange={(e) => {
                    this.setState({
                      p6LinkedData: {
                        ...this.state.p6LinkedData,
                        act_exp_start: e.target.value
                      }
                    })
                  }}
                  variant='outlined'
                  style={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="Expected Finish Date"
                  type="date"
                  defaultValue={moment(this.state.p6LinkedData?.act_exp_finish).format('yyyy-MM-DD')}
                  onChange={(e) => {
                    this.setState({
                      p6LinkedData: {
                        ...this.state.p6LinkedData,
                        act_exp_finish: e.target.value
                      }
                    })
                  }}
                  variant='outlined'
                  style={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button onClick={() => this.bulkEditListItems([this.state.p6LinkedData])}>
                  Submit
                </Button>
              </Stack>
            </Box>
            <Box marginTop={5}>
              <Table size="small" aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {P6Columns.map((column) => (
                      <TableCell>
                        {column.name}
                      </TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state?.p6LinkedData?.p6_linked.map((row) => (
                    <TableRow>
                      {P6DataColumns.map((column) => (
                        <TableCell>
                          {column.render ? column.render(row[column.name]) : row[column.name]}
                        </TableCell>
                      ))}
                    </TableRow>))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          fullScreen
          open={this.state.openTaskLevelAllocation}
          onClose={() => this.setState({ openTaskLevelAllocation: false })}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: 'relative' }} color='primary'>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.setState({ openTaskLevelAllocation: false })}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" >
                {(this.state.allData) ? this.state.allData.project_name + " -> " + this.state.allData.id + " " + this.state.allData.task_name : null}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent >
            <div style={{ marginTop: '10px' }}>
              <Allocation value={this.state.allData} usersData={this.state.usersData} />
            </div>
          </DialogContent>
        </Dialog>
      </div >
    )
  }
}

export default withSnackbar(PIMHandle)


