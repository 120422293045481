import React, { Component } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import '../../WorkHours/TeamWorkloadGantt/Gantt.css'
import { withSnackbar } from 'notistack';
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import { Divider } from '@material-ui/core';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import Slider from '@mui/material/Slider';
import { withStyles } from '@material-ui/core'
import Service from '../../Networking/networkutils';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import moment from 'moment';
import { CoffeeLoading } from 'react-loadingg';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getMode } from '../../MainDashBoard/ColorTheme/ColorTheme';
import { screenSizeCheck } from '../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import PendingTasksButton from '../../SecuredWorks/PendingTasksFlow/PendingTasksButton';

const token = getCookie("bb_ts_token");

var chartMinPercentage = 25
var chartMaxPercentage = 75
var defaultChartWidth = 50
var chartMinWidth = window.innerWidth * (chartMinPercentage / 100) //means 25% of screen width
var chartMaxWidth = window.innerWidth * (chartMaxPercentage / 100) //means 75% of screen width
var chartDividerMovableWidth = chartMaxWidth - chartMinWidth
var perPercentWidth = chartDividerMovableWidth * 0.01 //means 1% of screen width
var chartWidth = chartMinWidth + (perPercentWidth * 50)

var task = []

const useStyles = (theme) => ({
});

const darkTheme = createTheme({
    palette: {
        mode: getMode() ? 'dark' : 'light',
    },
});

class AWPIMGanttChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chartWidthProgress: 50,
            expand: true,
            chartWidth: Math.floor(chartMinWidth + (perPercentWidth * defaultChartWidth)),
            showLinked: false,
            zoom: localStorage.getItem('zoom_level') ? JSON.parse(localStorage.getItem('zoom_level')) : 1,
            showOptions: true,
            coffeeLoading: true,
        }
    }

    componentDidMount() {
        if (localStorage.getItem('chartWidth')) {
            chartWidth = chartMinWidth + (perPercentWidth * localStorage.getItem('chartWidth'))
            this.setState({ chartWidth: Math.floor((chartMinWidth + (perPercentWidth * defaultChartWidth))), chartWidthProgress: localStorage.getItem('chartWidth') })
        } else {
            localStorage.setItem('chartWidth', defaultChartWidth)
            this.setState({ chartWidth: Math.floor((chartMinWidth + (perPercentWidth * defaultChartWidth))) })
        }
        this.fetchData('start')
        if (this.props.getExpand) {
            this.props.getExpand(this.state.expand)
        }
        localStorage.setItem('zoom_level', this.state.zoom)
    }

    componentWillUnmount() {
        gantt.clearAll();
    }

    fetchData(type) {
        var showAll = this.state.showLinked
        task = []
        this.setState({ coffeeLoading: true });
        gantt.clearAll();
        var url = `/bb/api/p6/activity/update/get_project_task_wbs_data/?project_id=${this.props.project.id}`
        if (showAll) {
            url += `&show_all=true`
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.formatData(res.data, showAll, type)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    formatData(data, showAll, type) {
        task = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element) {
                let data = {}
                data.id = element.id
                data.text = element.name
                data.type = "project"
                data.open = true

                if (element?.children.length > 0 || element?.activity.length > 0) {
                    data.tree = true
                }

                task.push(data)
                if (element?.children && element?.children.length > 0) {
                    this.formatChildData(element, showAll)
                } else {
                    for (let k = 0; k < element.activity.length; k++) {
                        const activity = element.activity[k];
                        let activity_data = {}

                        if (activity.type === 'Start Milestone') {
                            activity_data.type = 'milestone'
                            activity_data.start_date = !activity.updated_act_exp_start ? moment(activity.act_exp_start).format('DD-MM-YYYY') : moment(activity.updated_act_exp_start).format('DD-MM-YYYY')
                            activity_data.milestone_type = 'Start Milestone'
                        } else if (activity.type === 'Finish Milestone') {
                            activity_data.type = 'milestone'
                            activity_data.start_date = !activity.updated_act_exp_finish ? moment(activity.act_exp_finish).format('DD-MM-YYYY') : moment(activity.updated_act_exp_finish).format('DD-MM-YYYY')
                            activity_data.milestone_type = 'Finish Milestone'
                        } else {
                            activity_data.start_date = !activity.updated_act_exp_start ? moment(activity.act_exp_start).format('DD-MM-YYYY') : moment(activity.updated_act_exp_start).format('DD-MM-YYYY')
                            activity_data.end_date = !activity.updated_act_exp_finish ? moment(activity.act_exp_finish).format('DD-MM-YYYY') : moment(activity.updated_act_exp_finish).format('DD-MM-YYYY')
                        }

                        if (activity?.new_data) {
                            activity_data.new_data = activity.new_data
                        }
                        activity_data.id = activity.id
                        activity_data.text = activity.name
                        // activity_data.start_date = !activity.updated_act_exp_start ? moment(activity.act_exp_start).format('DD-MM-YYYY') : moment(activity.updated_act_exp_start).format('DD-MM-YYYY')
                        // activity_data.end_date = !activity.updated_act_exp_finish ? moment(activity.act_exp_finish).format('DD-MM-YYYY') : moment(activity.updated_act_exp_finish).format('DD-MM-YYYY')
                        activity_data.updated_act_exp_start = !activity.updated_act_exp_start ? '' : moment(activity.updated_act_exp_start).format('DD-MM-YYYY')
                        activity_data.updated_act_exp_finish = !activity.updated_act_exp_finish ? '' : moment(activity.updated_act_exp_finish).format('DD-MM-YYYY')
                        activity_data.from_date = moment(activity.from_date).format('DD-MM-YYYY')
                        activity_data.to_date = moment(activity.to_date).format('DD-MM-YYYY')
                        activity_data.percent = activity.percent_complete + '%'
                        activity_data.updated_percent = activity.updated_comp_percnt !== null ? (activity.updated_comp_percnt + '%') : ""
                        activity_data.duration = activity.planned_duration
                        activity_data.parent = element.id
                        activity_data.reason_for_change = activity.reason_for_change
                        activity_data.p6_id = activity.p6_id
                        activity_data.is_linked = activity.list_task.length > 0 ? true : false
                        activity_data.object_id = activity.object_id
                        activity_data.data_missing = activity.data_missing
                        activity_data.date_created = moment(activity.date_created).format('DD-MM-YYYY')

                        if (activity.list_task.length > 0 && showAll) {
                            activity_data.is_linked = activity.list_task.length > 0 ? true : false
                        }
                        task.push(activity_data)
                    }
                }
            }
        }
        if (task.length === 0) {
            this.props.enqueueSnackbar("No P6 Activity linked", { variant: 'warning' });
        }

        this.getData(type)
        if (this.state.openMenu) {
            if (this.ganttRef && this.ganttRef.current) {
                this.ganttRef.current.showOptions(false)
            }
        }
    }

    formatChildData(childData, showAll) {
        for (let j = 0; j < childData?.children?.length; j++) {
            const childElement = childData.children[j];
            if (childElement) {
                let data = {}
                data.id = childElement.id
                data.text = childElement.name
                // data.type = "project"
                data.open = true
                data.parent = childData.id
                if ((childElement?.children && childElement?.children.length > 0) || childElement?.activity.length > 0) {
                    data.tree = true
                }
                task.push(data)
                if (childElement?.children && childElement?.children.length > 0) {
                    this.formatChildData(childElement, showAll)
                } else {
                    for (let k = 0; k < childElement.activity.length; k++) {
                        const activity = childElement.activity[k];
                        let activity_data = {}

                        if (activity.type === 'Start Milestone') {
                            activity_data.type = 'milestone'
                            activity_data.start_date = !activity.updated_act_exp_start ? moment(activity.act_exp_start).format('DD-MM-YYYY') : moment(activity.updated_act_exp_start).format('DD-MM-YYYY')
                            activity_data.milestone_type = 'Start Milestone'
                        } else if (activity.type === 'Finish Milestone') {
                            activity_data.type = 'milestone'
                            activity_data.start_date = !activity.updated_act_exp_finish ? moment(activity.act_exp_finish).format('DD-MM-YYYY') : moment(activity.updated_act_exp_finish).format('DD-MM-YYYY')
                            activity_data.milestone_type = 'Finish Milestone'
                        } else {
                            activity_data.start_date = !activity.updated_act_exp_start ? moment(activity.act_exp_start).format('DD-MM-YYYY') : moment(activity.updated_act_exp_start).format('DD-MM-YYYY')
                            activity_data.end_date = !activity.updated_act_exp_finish ? moment(activity.act_exp_finish).format('DD-MM-YYYY') : moment(activity.updated_act_exp_finish).format('DD-MM-YYYY')
                        }
                        if (activity?.new_data) {
                            activity_data.new_data = activity.new_data
                        }
                        activity_data.id = activity.id
                        activity_data.text = activity.name
                        // activity_data.start_date = !activity.updated_act_exp_start ? moment(activity.act_exp_start).format('DD-MM-YYYY') : moment(activity.updated_act_exp_start).format('DD-MM-YYYY')
                        // activity_data.end_date = !activity.updated_act_exp_finish ? moment(activity.act_exp_finish).format('DD-MM-YYYY') : moment(activity.updated_act_exp_finish).format('DD-MM-YYYY')
                        activity_data.updated_act_exp_start = !activity.updated_act_exp_start ? '' : moment(activity.updated_act_exp_start).format('DD-MM-YYYY')
                        activity_data.updated_act_exp_finish = !activity.updated_act_exp_finish ? '' : moment(activity.updated_act_exp_finish).format('DD-MM-YYYY')
                        activity_data.from_date = moment(activity.from_date).format('DD-MM-YYYY')
                        activity_data.to_date = moment(activity.to_date).format('DD-MM-YYYY')
                        activity_data.percent = activity.percent_complete + '%'
                        activity_data.updated_percent = activity.updated_comp_percnt !== null ? (activity.updated_comp_percnt + '%') : ""
                        activity_data.duration = activity.planned_duration
                        activity_data.parent = childElement.id
                        activity_data.reason_for_change = activity.reason_for_change
                        activity_data.p6_id = activity.p6_id
                        activity_data.is_linked = activity.list_task.length > 0 ? true : false
                        activity_data.object_id = activity.object_id
                        activity_data.data_missing = activity.data_missing
                        activity_data.date_created = moment(activity.date_created).format('DD-MM-YYYY')

                        if (activity.list_task.length > 0 && showAll) {
                            activity_data.is_linked = activity.list_task.length > 0 ? true : false
                        }
                        task.push(activity_data)
                    }
                }
            }
        }
    }

    getData(type) {
        gantt.resetLayout();
        gantt.config.drag_move = false;
        gantt.config.readonly = true;
        // gantt.skin = "dark";
        // gantt.plugins({ tooltip: true, marker: true })

        gantt.config.columns = [
            { name: "text", label: 'P6 Tasks', align: "left", tree: true, minWidth: 250, width: 250 },
            { name: "p6_id", label: 'P6 ID', align: "center", minWidth: 200, width: 200 },
            { name: "start_date", label: "Expected Start", align: "center", minWidth: 120, width: 130 },
            { name: "end_date", label: "Expected Finish", align: "center", minWidth: 120, width: 130 },
            // { name: "duration", label: "Duration", align: "center", minWidth: 120, width: 120 },
            { name: "percent", label: "P6 %", align: "center", minWidth: 40, width: 40 },
            { name: "updated_percent", label: "Updated %", align: "center", minWidth: 95, width: 95 },
            { name: "updated_act_exp_start", label: "Updated Expected Start", align: "center", minWidth: 200, width: 200 },
            { name: "updated_act_exp_finish", label: "Updated Expected Finish", align: "center", minWidth: 200, width: 200 },
            { name: "reason_for_change", label: "Reason for Change", align: "center", minWidth: 150, width: 150 },
            { name: "date_created", label: "Imported On", align: "center", minWidth: 110, width: 110 },
        ]
        var showLinked = this.state.showLinked
        if (this.state.showLinked) {
            gantt.config.columns.unshift({ name: "is_linked", label: "Linked", align: "center", minWidth: 50, width: 50, template: this.myFunc })
        }

        gantt.config.layout = {
            css: "gantt_container",
            cols: [
                {
                    width: chartWidth,
                    // minWidth: 300,
                    maxWidth: chartWidth,
                    rows: [
                        { view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer" },

                        // horizontal scrollbar for the grid
                        { view: "scrollbar", id: "gridScroll", group: "horizontal" }
                    ]
                },
                { resizer: true, width: 1 },
                {
                    rows: [
                        { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },

                        // horizontal scrollbar for the timeline
                        { view: "scrollbar", id: "scrollHor", group: "horizontal" }
                    ]
                },
                { view: "scrollbar", id: "scrollVer" }
            ]
        };

        var tasks = {
            data: task
        }

        var zoomConfig = {
            levels: [
                {
                    name: "day",
                    scale_height: 40,
                    min_column_width: 80,
                    scales: [
                        { unit: "day", step: 1, format: "%d %M" }
                    ]
                },
                {
                    name: "week",
                    scale_height: 50,
                    min_column_width: 50,
                    scales: [
                        {
                            unit: "week", step: 1, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%d %M");
                                var endDate = gantt.date.add(date, 6, "day");
                                var weekNum = gantt.date.date_to_str("%W")(date);
                                return "Week " + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        },
                        { unit: "day", step: 1, format: "%j %D" }
                    ]
                },
                {
                    name: "month",
                    scale_height: 50,
                    min_column_width: 120,
                    scales: [
                        { unit: "month", format: "%F, %Y" },
                        // { unit: "week", format: "Week %W" }
                        {
                            unit: "week", format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%d %M");
                                var endDate = gantt.date.add(date, 6, "day");
                                // var weekNum = gantt.date.date_to_str("%W")(date);
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        }
                    ]
                },
                {
                    name: "quarter",
                    height: 50,
                    min_column_width: 90,
                    scales: [
                        { unit: "month", step: 1, format: "%M" },
                        {
                            unit: "quarter", step: 1, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%M");
                                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        }
                    ]
                },
                {
                    name: "year",
                    scale_height: 50,
                    min_column_width: 30,
                    scales: [
                        { unit: "year", step: 1, format: "%Y" }
                    ]
                }
            ]
        };

        var filterValue = "";
        var delay;

        gantt.$doFilter = function (value) {
            // console.log(value, 'do filter')
            filterValue = value;
            clearTimeout(delay);
            delay = setTimeout(function () {
                gantt.render();
            }, 200)
        }

        gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
            // console.log(filterValue,'filtervalue before task')
            if (!filterValue) return true;
            if (!task.p6_id) return false
            var searchvalue = task.text + " " + task.p6_id;
            var normalizedText = searchvalue.toLowerCase();
            var normalizedValue = filterValue.toLowerCase();
            return normalizedText.indexOf(normalizedValue) > -1;
        });


        gantt.config.lightbox.sections = [
            { name: "description", height: 70, map_to: "text", type: "textarea" },
            { name: "type", type: "typeselect", map_to: "type" },
            { name: "time", height: 72, type: "duration", map_to: "auto" }
        ];

        gantt.templates.rightside_text = function (start, end, task) {
            if (task.type === gantt.config.types.milestone) {
                return task.milestone_type;
            }
            return "";
        };
        gantt.config.order_branch = true;

        // gantt.attachEvent("onTaskClick", (id, e) => {
        //     const task = gantt.getTask(id)
        //     if (task.hasOwnProperty('p6_id')) {
        //         this.postData(task.p6_id)
        //     } else if (!task.tree) {
        //         this.props.enqueueSnackbar('No P6 ID Found', { variant: 'warning' })
        //     }
        //     return true;
        // }, { id: "my-click" });


        gantt.attachEvent("onTaskClick", (id, e) => {
            const task = gantt.getTask(id)
            if (task.p6_id) {
                this.openMenu(task)
            }
            return true;
        }, { id: "my-click" });

        gantt.templates.grid_row_class = function (start, end, task) {
            if (task.data_missing) {
                return 'gantt_missing_color'
            } else if (task.is_linked && showLinked) {
                return 'gantt_bg_color'
            }
        };

        this.setState({ coffeeLoading: false });
        gantt.config.row_height = 30;
        gantt.config.show_unscheduled = true;
        gantt.ext.zoom.init(zoomConfig);
        gantt.ext.zoom.setLevel("month");
        gantt.init('gantt_here');
        gantt.parse(tasks);
    }

    zoomIn() {
        gantt.ext.zoom.zoomIn()
    }

    zoomOut() {
        gantt.ext.zoom.zoomOut()
    }

    expandAll() {
        gantt.eachTask(function (task) {
            task.$open = true
        });
        gantt.render();
    }

    collapseAll() {
        gantt.eachTask(function (task) {
            task.$open = false
        });
        gantt.render();
    }

    search = (value) => {
        gantt.$doFilter(value)
    }

    setExpand = () => {
        this.setState({ expand: !this.state.expand }, () => {
            if (this.state.expand === true) {
                this.expandAll()
            } else {
                this.collapseAll()
            }
        })
    }

    openMenu = (data) => {
        if (this.props.openMenu) {
            this.props.openMenu(data)
            // gantt.detachEvent("my-click");
            // this.handleChange(null, 100)
            setTimeout(() => {
                this.setState({ chartWidth: window.innerWidth - this.props.drawerWidth }, () => {
                    chartWidth = window.innerWidth - this.props.drawerWidth
                    gantt.config.layout.cols[0].width = chartWidth
                    gantt.config.layout.cols[0].maxWidth = chartWidth
                    gantt.resetLayout()
                })
            }, this.props.theme.transitions.duration.enteringScreen + 20)
        }
    }

    myFunc = (task) => {
        // console.log(task)
        if (task.is_linked) {
            return '<span class="material-icons" style=cursor:pointer;fontsize:small" data-action="edit">check</span>'
        } else if (task.p6_id && !task.is_linked) {
            // return '<span class="material-icons" style=cursor:pointer;fontsize:small" data-action="link">add</span>'
            return null
        }
    }

    decreaseZoom = () => {
        let zoom = (Number(this.state.zoom) - 0.1).toFixed(1);
        if (zoom < 0.5) return;
        this.setState({ zoom: zoom, }, () => {
            gantt.resetLayout()
        })
        localStorage.setItem('zoom_level', zoom)
    }

    increaseZoom = () => {
        let zoom = (Number(this.state.zoom) + 0.1).toFixed(1);
        if (zoom > 1.3) return;
        this.setState({ zoom: zoom, }, () => {
            gantt.resetLayout()
        })
        localStorage.setItem('zoom_level', zoom)
    }

    handleChange = (event, newValue) => {
        this.setState({ chartWidth: chartMinWidth + (perPercentWidth * newValue), chartWidthProgress: newValue }, () => {
            chartWidth = (chartMinWidth + (perPercentWidth * newValue))
            gantt.config.layout.cols[0].width = chartWidth
            gantt.config.layout.cols[0].maxWidth = chartWidth
            gantt.resetLayout()
            localStorage.setItem('chartWidth', newValue)
        })
    }

    showOptions = (show) => {
        this.setState({ showOptions: show })
    }

    render() {
        // const { classes } = this.props;

        const handleCheckChange = (e) => {
            this.setState({ showLinked: e.target.checked }, () => {
                this.fetchData('update')
            })
        }

        return (
            <div style={{ zoom: screenSizeCheck() ? 0.9 : 1 }}>
                {this.state.coffeeLoading ? <CoffeeLoading /> :
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%", padding: '5px', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="Search"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => this.search(e.target.value)}
                                    style={{ marginRight: '10px' }}
                                />
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox
                                        size='small'
                                        checked={this.state.showLinked}
                                        onChange={handleCheckChange}
                                        inputProps={{ 'aria-label': 'primary checkbox' }} />}
                                    label="Show All"
                                    labelPlacement="end"
                                />
                                <PendingTasksButton project={this.props.project} />
                            </Box>
                            {this.state.showOptions ?
                                <>
                                    <Box sx={{ width: 150, marginRight: '10px', fontSize: '12px' }}>
                                        Table width: {this.state.chartWidthProgress}%
                                        <Slider size='small' value={this.state.chartWidthProgress} aria-label="Default" valueLabelDisplay="off" onChange={(e, val) => this.handleChange(e, val)} />
                                    </Box>
                                    <Divider orientation="vertical" flexItem style={{ background: getMode() ? 'white' : "black" }} />
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px', marginLeft: '10px' }}>
                                        Gantt Zoom:&nbsp;
                                        <ThemeProvider theme={darkTheme}>
                                            <ToggleButtonGroup
                                                exclusive
                                                aria-label="text alignment"
                                                style={{ marginleft: "10px", float: 'right' }}
                                                size='small'
                                                color='primary'>
                                                <ToggleButton value="weeks" aria-label="by week" onClick={() => this.zoomOut()} >
                                                    <ZoomOut />
                                                </ToggleButton>
                                                <ToggleButton value="months" aria-label="by month" onClick={() => this.zoomIn()}>
                                                    <ZoomIn />
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </ThemeProvider>
                                        {/* <Button variant="outlined" onClick={() => this.zoomOut()} size='small' startIcon={<ZoomOut />} style={{ marginRight: '10px' }}>Zoom Out</Button>
                                        <Button variant="outlined" onClick={() => this.zoomIn()} size='small' startIcon={<ZoomIn />} style={{ marginRight: '10px' }}>Zoom IN</Button> */}
                                    </Box>
                                </> : null}
                        </Box>
                        <Box sx={{ height: '86vh' }}>
                            <div
                                id='gantt_here'
                                style={{ height: '100%', zoom: this.state.zoom }}
                            ></div>
                        </Box></>}
            </div >
        );
    }
}

export default withStyles(useStyles, { withTheme: true })(withSnackbar(AWPIMGanttChart));
