import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { Tooltip, withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CardMedia from '@material-ui/core/CardMedia';
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InputIcon from '@material-ui/icons/Input';
import Template from '../Estimate/Template/Template';
import TenderProjects from './Tender/TenderProjects';
// import RolesTemplate from './Template/RolesTemplate';
import { getCookie, setCookie, deleteCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Clients from './Clients/Clients';
import Tender from './Tender/Tender';
// import RateTemplate from './Template/RateTemplate';
import ReactGA from 'react-ga';
import Tracker from './OpportunitiesTracker/Tracker';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Approval from './Approval/Approval';
import RateCardTemplateTab from './Template/RateCardTemplateTab';
import Region from './Template/Region';
import MCRTimelineTabs from '../MCRReport/MCRTimelineTabs';
import { accessReloadOrRefresh, getPrimaryColor } from '../Helper/CommonThings';
import RolesTemplateMain from './RoleGroup/RolesTemplateMain';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ProjectRequest from './ProjectRequest/ProjectRequest';
import Company from './Company/Company';
import PIMTimelineTab from '../PIM/PIMTimeline/PIMTimelineTab';
// import ReactGA from 'react-ga';

const drawerWidth = 240;

const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        width: `calc(100% - ${drawerWidth}px)`,
        padding: theme.spacing(2),
    },
});

const StyledListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#CFE9F8"
        }
    }
})(ListItem);

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// const loadergot = getCookie("load_got_user");
var email = '';

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openDialog: false,
            firstname: '',
            lastname: '',
            subButton: true,
            openDrawer: false,
            userData: {},
            viewClass: 'Tender',
            menus: [],
            isLoading: true,
            userAccess: {},
        }
    }

    componentDidMount() {
        var pageView = window.location.pathname + "/#TenderHome";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        } else {
            Service.get(`/rest-auth/user/`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    // console.log(!((access && access.access && access.access.l5a && access.access.l5a.l5a_a) || (access && access.access && access.access.l0a && access.access.l0a.l0a_b)))
                    if (!((access && access.access && access.access.l5a && access.access.l5a.l5a_a) || (access && access.access && access.access.l0a && access.access.l0a.l0a_b))) {
                        window.location.href = '/'
                    }
                    // console.log(res.data)
                    setCookie('bb_ts_user', JSON.stringify(res.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                    email = res.data.email;
                    var names = false;
                    if ((res.data.first_name === "" && res.data.last_name === "") || (res.data.first_name === null || res.data.last_name === null)) {
                        names = true;
                    } else {
                        this.props.enqueueSnackbar('Welcome back ' + res.data.first_name + '!', {
                            variant: 'info'
                        });
                    }
                    this.setState({
                        isLoading: false,
                        userData: res.data,
                        openDialog: names,
                    });

                    // this.getAccess(res.data);
                })
                .catch(error => {
                    errorMessage(this.props, error)

                });

            this.getAccess();

            this.createMenu();

        }
    }

    getAccess() {
        Service.get(`/bb/api/token/access/`, {
            headers: {
                Authorization: "Token " + getCookie("bb_ts_token"),
            },
        })
            .then(res => {
                if (res.data && res.data[0]) {
                    if (access) {
                        if (access.last_updated !== res.data[0].last_updated) {
                            setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                            accessReloadOrRefresh();
                        }
                    } else {
                        setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                        accessReloadOrRefresh();
                    }

                } else {
                    setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                    alert("This user doesn't have basic access")
                }


            })
            .catch(error => {
                setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
            });
    }

    createMenu() {
        // console.log(access.access)
        // ['Tender', 'Unsecured Work', 'Opportunities Tracker', 'Deliverables Template', 'Rate Card Template', 'Roles', 'Clients']
        var accessBuild = [];
        var defaultView = '';
        var hereMenu = [];

        var hashDone = false;

        var viewHash = window.location.hash.replace('#', '').replace('%20', ' ').replace('%20', ' ');

        if (access && access.access) {

            if (access.access.l8a && access.access.l8a.l8a_a) {
                if (viewHash === 'Opportunities Tracker') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Opportunities Tracker')
                if (defaultView === '') {
                    defaultView = 'Opportunities Tracker'
                }
            }
            if (access.access.l7a && access.access.l7a.l7a_a) {
                if (viewHash === 'Unsecured Workbook') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Unsecured Workbook')
                if (defaultView === '') {
                    defaultView = 'Unsecured Workbook'
                }
            }
            if (access.access.l6a && access.access.l6a.l6a_a) {
                if (viewHash === 'Estimates') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Estimates')
                defaultView = 'Estimates'
            }

            if (access.access.l6a && access.access.l6a.l6a_q) {
                if (viewHash === 'Project Request') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Project Request')
                if (defaultView === '') {
                    defaultView = 'Project Request'
                }
            }


            if (access.access.l6a && access.access.l6a.l6a_p) {
                if (viewHash === 'Tender Approval') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Tender Approval')
                if (defaultView === '') {
                    defaultView = 'Tender Approval'
                }

            }

            if (access.access.l9a && access.access.l9a.l9a_a) {
                if (viewHash === 'Deliverables Template') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Deliverables Template')
                if (defaultView === '') {
                    defaultView = 'Deliverables Template'
                }
            }
            if (access.access.l10a && access.access.l10a.l10a_a) {
                if (viewHash === 'Rate Card Template') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Rate Card Template')
                if (defaultView === '') {
                    defaultView = 'Rate Card Template'
                }
            }
            if (access.access.l21a && access.access.l21a.l21a_a) {
                if (viewHash === 'MCR Timeline') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('MCR Timeline')
                if (defaultView === '') {
                    defaultView = 'MCR Timeline'
                }
            }
            if (access.access.l21a && access.access.l21a.l21a_a) {
                if (viewHash === 'PIM Timeline') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('PIM Timeline')
                if (defaultView === '') {
                    defaultView = 'PIM Timeline'
                }
            }
            if (access.access.l11a && access.access.l11a.l11a_a) {
                if (viewHash === 'Roles') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Roles')
                if (defaultView === '') {
                    defaultView = 'Roles'
                }
            }
            if (access.access.l12a && access.access.l12a.l12a_a) {
                if (viewHash === 'Clients') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Clients')
                if (defaultView === '') {
                    defaultView = 'Clients'
                }
                if (viewHash === 'Regions') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Regions')
                if (defaultView === '') {
                    defaultView = 'Regions'
                }
            }

            if (access.access.l24a && access.access.l24a.l24a_a) {
                if (viewHash === 'SBU') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('SBU')
                if (defaultView === '') {
                    defaultView = 'SBU'
                }
            }
        }

        accessBuild.push(hereMenu)

        if (hashDone) {
            this.setState({
                menus: accessBuild,
            })
        } else {
            this.setState({
                menus: accessBuild,
                viewClass: defaultView,
            })
        }

    }

    handleTextChange(e) {
        if (e.target.id === 'firstname') {
            this.setState({
                firstname: e.target.value,
            })
        } else if (e.target.id === 'lastname') {
            this.setState({
                lastname: e.target.value,
            })
        }
        if (this.state.firstname !== '' && this.state.lastname !== '') {
            this.setState({
                subButton: false,
            })
        } else {
            this.setState({
                subButton: true,
            })
        }
    }

    handleClose(e) {
        this.setState({
            subButton: false,
        })
        const json = {
            email: email,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
        }

        Service.put(`/rest-auth/user/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {
                deleteCookie("bb_ts_user");
                window.location.href = '/'
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    render() {
        const { classes } = this.props;

        const handleDrawerOpen = () => {
            this.setState({
                openDrawer: true,
            })
        };

        const handleDrawerClose = () => {
            this.setState({
                openDrawer: false,
            })
        };

        const handleViewChange = (e, val) => {

            var pageView = window.location.pathname + "/#" + val;
            ReactGA.set({ page: pageView, });
            ReactGA.pageview(pageView);

            if (!getCookie("bb_ts_token")) {
                window.location.href = '/'
            }
            if (val === "Back to Home") {
                window.location.href = "/home/#Dashboard";
            } else {
                window.location.hash = val;
                this.setState({
                    viewClass: val,
                })
            }
        };

        return (
            <>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar
                            position="fixed"
                            className={clsx(classes.appBar, {
                                [classes.appBarShift]: this.state.openDrawer,
                            })}
                        >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    className={clsx(classes.menuButton, {
                                        [classes.hide]: this.state.openDrawer,
                                    })}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" noWrap>
                                    Estimates
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: this.state.openDrawer,
                                [classes.drawerClose]: !this.state.openDrawer,
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: this.state.openDrawer,
                                    [classes.drawerClose]: !this.state.openDrawer,
                                }),
                            }}
                        >
                            <div className={classes.toolbar}>
                                <CardMedia
                                    component="img"
                                    alt="Balfour Beatty"
                                    image={process.env.PUBLIC_URL + '/bb.png'}
                                    title="Balfour Beatty"
                                    style={{ width: '170px' }}
                                />
                                <IconButton onClick={handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            <List>
                                {['Back to Home'].map((text, index) => (
                                    <ListItem button key={text} onClick={e => handleViewChange(e, text)}>
                                        <ListItemIcon>{index === 0 ? <ArrowBackIosIcon /> : <AllInboxIcon />}</ListItemIcon>
                                        <ListItemText primary={text} />
                                    </ListItem>
                                ))}
                            </List>
                            <Divider />
                            <List>
                                {this.state.menus[0].map((text, index) => (
                                    <Tooltip title={text} aria-label={text} key={text}>
                                        <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                            <ListItemIcon>{text === 'Project Request' ? <LibraryBooksIcon /> : text === "Opportunities Tracker" ? <ArtTrackIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === "Unsecured Workbook" ? <AllInboxIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === "Estimates" ? <InputIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === "Deliverables Template" ? <AssignmentIndIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === "Tender Approval" ? <PlaylistAddCheckIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <PersonPinIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                            <ListItemText primary={text} />
                                        </StyledListItem>
                                    </Tooltip>
                                ))}
                            </List>

                            <Divider />

                        </Drawer>
                        <main className={classes.content}>
                            <div className={classes.toolbar} />
                            {
                                (!this.state.isLoading) ? (
                                    (this.state.viewClass === 'Estimates') ? (
                                        <TenderProjects />
                                    ) : (this.state.viewClass === 'Project Request') ? (
                                        <ProjectRequest />
                                    ) : (this.state.viewClass === 'Deliverables Template') ? (
                                        <Template />
                                    ) : (this.state.viewClass === 'Opportunities Tracker') ? (
                                        <Tracker />
                                    ) : (this.state.viewClass === 'Roles') ? (
                                        <RolesTemplateMain />
                                    ) : (this.state.viewClass === 'Rate Card Template') ? (
                                        <RateCardTemplateTab editable={true} />
                                    ) : (this.state.viewClass === 'Unsecured Workbook') ? (
                                        <Tender />

                                    ) : (this.state.viewClass === 'Clients') ? (
                                        <Clients />
                                    ) : (this.state.viewClass === 'Regions') ? (
                                        <Region />
                                    ) : (this.state.viewClass === 'SBU') ? (
                                        <Company />
                                    ) : (this.state.viewClass === 'Tender Approval') ? (
                                        <Approval />
                                    ) : (this.state.viewClass === 'MCR Timeline') ? (
                                        <MCRTimelineTabs />
                                    ) : (this.state.viewClass === 'PIM Timeline') ? (
                                        <PIMTimelineTab />
                                    ) : (null)
                                ) : (
                                    null
                                )
                            }
                        </main>

                        <Dialog open={this.state.openDialog} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">User Detail Form</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    You need to fill below fields to continue.
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="firstname"
                                    label="First Name"
                                    type="name"
                                    onChange={e => this.handleTextChange(e)}
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    id="lastname"
                                    label="Last Name"
                                    type="name"
                                    onChange={e => this.handleTextChange(e)}
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button size='small' onClick={e => this.handleClose(e)} disabled={this.state.subButton} color="primary">
                                    Continue
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                )
                }

            </>
        )
    }
}


export default withStyles(useStyles)(withSnackbar(Home));
