import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../../Helper/CookieHelper';
import { Box, Button, IconButton, Typography } from '@mui/material';
import PendingTasksFlow from './PendingTasksFlow';
import { errorMessage } from '../../Helper/ErrorMessage';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Service from '../../Networking/networkutils';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import moment from 'moment';

const token = getCookie("bb_ts_token");

class PendingTasksButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingTasks: [],
            pendingCount: 0,
            checkPendingTasksDialog: false,
        }
        this.getCount = this.getCount.bind(this);
    }

    componentDidMount() {
        this.fetchPendingTasks();
    }

    getCount(count) {
        this.setState({ pendingCount: count })
    }

    fetchPendingTasks = () => {

        var url = `/bb/api/tender/wbs/tasks/get_task_flow_data/`;

        if (this.props.project) {
            url += `?project_id=${this.props.project.id}`;
        } else if (this.props.ds) {
            url += `?ds_id=${this.props.ds.id}`;
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                res.data.forEach(element => {
                    element.old_act_exp_start = element.act_exp_start
                    element.old_act_exp_finish = element.act_exp_finish
                });
                this.setState({ pendingTasks: res.data, pendingCount: res.data.length })
                if (res.data.length > 0) {
                    var key = ''
                    if (this.props.project) {
                        key = 'project_' + this.props.project.id
                    } else {
                        key = 'ds_' + this.props.ds.id
                    }
                    var lastVisit = localStorage.getItem(key)
                    if (lastVisit) {
                        var lastVisitDate = moment(lastVisit)
                        var currentDate = moment()
                        var diff = currentDate.diff(lastVisitDate, 'days')
                        if (diff > 1) {
                            this.setState({ alertDialog: true })
                        }
                    } else {
                        this.setState({ alertDialog: true })
                    }
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    closeAlert() {
        this.setState({ alertDialog: false })
        var key = ''
        if (this.props.project) {
            key = 'project_' + this.props.project.id
        } else {
            key = 'ds_' + this.props.ds.id
        }
        localStorage.setItem(key, moment().format('YYYY-MM-DD HH:mm:ss'))
    }

    render() {
        return (
            <div>
                {this.state.pendingTasks.length > 0 ?
                    <Button color='primary' size='small' variant='outlined' startIcon={<PlayCircleIcon fontSize='small' />} onClick={() => this.setState({ checkPendingTasksDialog: true })}>
                        Pending Actions
                    </Button> : null}
                <Dialog
                    open={this.state.checkPendingTasksDialog}
                    fullWidth={true}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description" >
                    <DialogTitle id="alert-dialog-title">
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <Box flexGrow={1}>
                                <Typography variant='h6'>Pending actions on {this.state.pendingCount} PaRaM tasks</Typography>
                            </Box>
                            <IconButton size='medium' onClick={() => this.setState({ checkPendingTasksDialog: false })}>
                                <CloseIcon fontSize='medium' />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        {
                            this.props.project ?
                                <PendingTasksFlow
                                    project={this.props.project}
                                    pendingTasks={this.state.pendingTasks}
                                    getCount={this.getCount}
                                />
                                :
                                <PendingTasksFlow
                                    ds={this.props.value}
                                    usersData={this.props.usersData}
                                    pendingTasks={this.state.pendingTasks}
                                    dateChangeNouns={this.props.dateChangeNouns}
                                    dateChangeAdjectives={this.props.dateChangeAdjectives}
                                    getCount={this.getCount}
                                />
                        }
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.alertDialog}
                    fullWidth={true}
                    maxWidth={'xs'}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description" >
                    <DialogTitle id="alert-dialog-title">
                        There are pending actions on {this.state.pendingCount} PaRaM tasks.
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.closeAlert()} color="primary">
                            Skip
                        </Button>
                        <Button onClick={() => this.setState({ alertDialog: false, checkPendingTasksDialog: true })} color="primary">
                            Start Actioning
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(PendingTasksButton);