import React, { Component } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { Box, Grid, Paper, Button, InputLabel, FormControl, Select, Dialog, DialogContent, DialogActions, CircularProgress, IconButton, Typography, DialogTitle } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Service from "../../Networking/networkutils"
import { getCookie } from '../../Helper/CookieHelper';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import Tenderers from './Tenderers';
import { errorMessage } from '../../Helper/ErrorMessage';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class TenderDetail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            usersData: [],
            crm: '',
            gate_stage: 0,
            opportunity: '',
            client: '',
            bid_lead: null,
            pdm: null,
            lde: null,
            lead_engineer: null,
            contract_value: 0,
            itt_start: null,
            gate_3: null,
            tender_submission: null,
            contract_award: null,
            contract_completion: null,
            tender_weeks: 0,
            lead_discipline: '',
            edc_dicipline: '',
            access_to_edit: null,
            openTenderers: false,
            isLoading: true,
            organisationData: [],
            selectedOrganisation: null,
            copySelectedOrganisation: null,
            openOrganisation: false,
            project_portal_organisation_id: null,
            project_portal_organisation_name: null,
            addDisabled: false,
            organisationLoading: true,
            closeDisabled: false,
            companies: [],
        }
    }

    componentDidMount() {
        // console.log(this.props.value)
        this.fetchOps();
        this.fetchOrganisationData();
        this.fetchCompanies()
    }

    fetchCompanies() {
        Service.get('/bb/api/company/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ companies: res.data })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchOrganisationData() {

        var url = `/bb/api/p6/wbs/get_organisation_data/`
        if (this.props.value.crm !== null) {
            url = `/bb/api/p6/wbs/get_organisation_data/?crm_id=${this.props.value.crm}`
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    selectedOrganisation: res.data.crm_index,
                    copySelectedOrganisation: res.data.crm_index,
                    organisationData: res.data.project_portal_data,
                    organisationLoading: false,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get('/bb/api/tender/bid/' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                Service.get(`/bb/api/users/`, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {
                        this.setState({ usersData: res1.data })
                        // console.log(jsondata)
                        this.setState({
                            bid_lead: res.data.bid_lead_data,
                            pdm: res.data.pdm_data,
                            lde: res.data.lde_data,
                            lead_engineer: res.data.lead_engineer_data,
                            contract_value: res.data.value,
                            itt_start: res.data.itt_start,
                            gate_3: res.data.gate_3,
                            tender_submission: res.data.submission,
                            contract_award: res.data.contract_award,
                            contract_completion: res.data.contract_completion,
                            tender_weeks: res.data.weeks,
                            edc_dicipline: res.data.edc_dicipline,
                            isLoading: false,
                            project_portal_organisation_id: res.data.project_portal_organisation_id,
                            project_portal_organisation_name: res.data.project_portal_organisation_name,
                            company: res.data.company,
                            // access_to_edit: res.data.access_to_edit,
                        })
                        // console.log(res.data.access_to_edit)
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }

    prevPut() {
        var goon = true;
        var dataPut = {
            value: this.state.contract_value,
            itt_start: this.state.itt_start,
            gate_3: this.state.gate_3,
            submission: this.state.tender_submission,
            contract_award: this.state.contract_award,
            contract_completion: this.state.contract_completion,
            weeks: this.state.tender_weeks,
            edc_dicipline: this.state.edc_dicipline,
            access_to_edit: this.state.access_to_edit,
        }
        if (!this.state.company) {
            this.props.enqueueSnackbar('SBU should not be empty!', {
                variant: 'warning'
            });
            goon = false
        }else{
            dataPut.company = this.state.company;
        }
        try {
            dataPut.bid_lead = this.state.bid_lead.id;
        } catch (error) {
            dataPut.bid_lead = null;
        }

        try {
            dataPut.pdm = this.state.pdm.id;
        } catch (error) {
            this.props.enqueueSnackbar('EM should not be empty!', {
                variant: 'warning'
            });
            dataPut.pdm = null;
            goon = false
        }
        try {
            dataPut.lde = this.state.lde.id;
        } catch (error) {
            dataPut.lde = null;
        }
        try {
            dataPut.lead_engineer = this.state.lead_engineer.id;
        } catch (error) {
            dataPut.lead_engineer = null;
        }
        if (goon) {
            this.putData(dataPut)
        }

        // console.log(this.state.bid_lead)
    }

    putData(data) {
        this.setState({ isLoading: true })
        Service.put('/bb/api/tender/bid/' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Client updated successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: true, })
                errorMessage(this.props, error)
                this.fetchOps();
            });
    }

    onTagsChange = (event, values) => {
        // console.log(event.target.id.split("-")[0])
        this.setState({ [event.target.id.split("-")[0]]: values })
    }

    onOrganisationChange = (event, values) => {
        this.setState({ selectedOrganisation: values })
    }

    linkOrganisation() {
        if (!this.state.selectedOrganisation) {
            this.props.enqueueSnackbar('Organisation should not be empty!', {
                variant: 'warning'
            });
        } else {
            this.setState({ addDisabled: true })
            Service.patch('/bb/api/tender/bid/create_project_organisation_details/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    item_id: this.props.value.id,
                    organisation_id: this.state.selectedOrganisation.organisation_id,
                    organisation_name: this.state.selectedOrganisation.project_name,
                },
            })
                .then(res => {
                    this.setState({ openOrganisation: false, addDisabled: false })
                    this.props.enqueueSnackbar('Organisation updated successfully', {
                        variant: 'success'
                    });
                    this.fetchOps();
                })
                .catch(error => {
                    this.setState({ addDisabled: true, })
                    errorMessage(this.props, error)
                    this.fetchOps();
                });
        }
    }

    deLinkOrganisation() {
        this.setState({ closeDisabled: true })
        Service.patch('/bb/api/tender/bid/delete_project_organisation_details/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                item_id: this.props.value.id,
            },
        })
            .then(res => {
                this.setState({ closeDisabled: false })
                this.props.enqueueSnackbar('Organisation removed successfully', {
                    variant: 'success'
                });
                this.fetchOps();
            })
            .catch(error => {
                this.setState({ closeDisabled: false, })
                errorMessage(this.props, error)
                this.fetchOps();
            });
    }

    render() {
        const { classes } = this.props;

        const handleTyping = (e) => {
            this.setState({ [e.target.id]: e.target.value })
        };

        const handleIttDate = (e) => {
            // console.log(e)
            this.setState({ itt_start: e })
        };
        const handleGate3Date = (e) => {
            // console.log(e)
            this.setState({ gate_3: e })
        };
        const handleSubmitionDate = (e) => {
            // console.log(e)
            this.setState({ tender_submission: e })
        };
        const handleAwardDate = (e) => {
            // console.log(e)
            this.setState({ contract_award: e })
        };
        const handleCompletionDate = (e) => {
            // console.log(e)
            this.setState({ contract_completion: e })
        };

        const handleSelect = (e) => {
            // console.log(e)
            this.setState({ edc_dicipline: e.target.value })
        };

        const handleCloseTenderers = () => {
            this.setState({ openTenderers: false, });
        };

        const handleCloseOrganisation = () => {
            this.setState({ openOrganisation: false, });
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                        <CircularProgress color={'primary'} />
                    </Box>
                ) : (
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Paper className={classes.paper}>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <TextField
                                            fullWidth
                                            // variant='outlined'
                                            select
                                            value={this.state.company}
                                            label={"SBU"}
                                            onChange={(e) => {
                                                this.setState({ company: e.target.value })
                                            }} >
                                            {this.state.companies.map((element, index) =>
                                                <MenuItem key={index} value={element.id}>
                                                    {element.name}
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <Autocomplete
                                            id="bid_lead"
                                            options={this.state.usersData}
                                            value={(this.state.bid_lead === "") ? (null) : (this.state.bid_lead)}
                                            getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                            style={{ width: '100%' }}
                                            disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                            onChange={this.onTagsChange}
                                            getOptionSelected={(option, { multiple, value }) => {
                                                if (!multiple) {
                                                    /*
                                                     * PROPOSAL for single selection, be able to provide own logic.
                                                     */
                                                    return (option.id === this.state.bid_lead.id);
                                                }

                                                return false;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Bid Lead" variant="outlined" />}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <Autocomplete
                                            id="pdm"
                                            options={this.state.usersData}
                                            value={(this.state.pdm === "" || this.state.pdm === undefined) ? (null) : (this.state.pdm)}
                                            getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                            style={{ width: '100%' }}
                                            disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                            onChange={this.onTagsChange}
                                            getOptionSelected={(option, { multiple, value }) => {
                                                if (!multiple) {
                                                    /*
                                                     * PROPOSAL for single selection, be able to provide own logic.
                                                     */
                                                    return (option.id === this.state.pdm.id);
                                                }

                                                return false;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="EM" variant="outlined" />}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <Autocomplete
                                            id="lde"
                                            options={this.state.usersData}
                                            value={(this.state.lde === "") ? (null) : (this.state.lde)}
                                            getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                            style={{ width: '100%' }}
                                            disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                            onChange={this.onTagsChange}
                                            getOptionSelected={(option, { multiple, value }) => {
                                                if (!multiple) {
                                                    /*
                                                     * PROPOSAL for single selection, be able to provide own logic.
                                                     */
                                                    return (option.id === this.state.lde.id);
                                                }

                                                return false;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="LDE" variant="outlined" />}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">

                                        {/* <Autocomplete
                                                multiple
                                                id="access_to_edit"
                                                options={this.state.usersData}
                                                disableCloseOnSelect
                                                value={this.state.access_to_edit ? this.state.access_to_edit : []}
                                                getOptionLabel={(option) => option.last_name + ',' + option.first_name}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.last_name + ',' + option.first_name}
                                                    </React.Fragment>
                                                )}
                                                getOptionSelected={(option, value) => option.id === value.id }
                                                onChange={this.onTagsChange}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label="Tenderers" placeholder=" Select Tenderers" />
                                                )}
                                            /> */}
                                        <Button size='small' onClick={() => this.setState({ openTenderers: true })} color='primary' variant='outlined'>Manage Tenderers</Button>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper className={classes.paper}>

                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <TextField fullWidth id="contract_value" label="Contract Value (£m)"
                                            value={this.state.contract_value}
                                            type="number"
                                            disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                            onChange={handleTyping} />
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="itt_start"
                                                label="ITT Start"
                                                format="dd/MM/yyyy"
                                                value={this.state.itt_start}
                                                onChange={handleIttDate}
                                                disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="gate_3"
                                                label="Gate 3"
                                                format="dd/MM/yyyy"
                                                value={this.state.gate_3}
                                                onChange={handleGate3Date}
                                                disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="tender-submission"
                                                label="Tender Submission"
                                                format="dd/MM/yyyy"
                                                value={this.state.tender_submission}
                                                onChange={handleSubmitionDate}
                                                disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>


                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper className={classes.paper}>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="contract_award"
                                                label="Contract Award"
                                                format="dd/MM/yyyy"
                                                value={this.state.contract_award}
                                                onChange={handleAwardDate}
                                                disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>

                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="contract_completion"
                                                label="Contract Completion"
                                                format="dd/MM/yyyy"
                                                value={this.state.contract_completion}
                                                onChange={handleCompletionDate}
                                                disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <TextField fullWidth id="tender_weeks" label="Tender Weeks"
                                            value={this.state.tender_weeks}
                                            type="number"
                                            disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                            onChange={handleTyping} />
                                    </Box>
                                    {(this.props.value.lead_dicipline === 'edc') ? (
                                        <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                                <InputLabel id="edc_dicipline">EDC Discipline</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="edc_dicipline"
                                                    value={this.state.edc_dicipline}
                                                    onChange={handleSelect}
                                                    disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                                    label="Lead Discipline"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"mep"}>MEP</MenuItem>
                                                    <MenuItem value={"bim"}>BIM</MenuItem>
                                                    <MenuItem value={"dig"}>DIG</MenuItem>
                                                    <MenuItem value={"pln"}>PLN</MenuItem>
                                                    <MenuItem value={"csd"}>CSD</MenuItem>
                                                    <MenuItem value={"gis"}>GIS</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    ) : (
                                        null
                                    )}
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper" alignItems={'center'}>
                                        <Box flexGrow={1} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Typography variant="h6">Organisation:&nbsp;</Typography>
                                            <Typography variant="body1">{this.state.project_portal_organisation_name ? this.state.project_portal_organisation_name : ""}</Typography>
                                        </Box>
                                        <IconButton size='small' onClick={() => this.setState({ openOrganisation: true, selectedOrganisation: this.state.copySelectedOrganisation })} disabled={this.state.closeDisabled}>
                                            <EditIcon fontSize='small' />
                                        </IconButton>
                                        {
                                            (this.state.project_portal_organisation_name) ? (
                                                <IconButton size='small' onClick={() => this.deLinkOrganisation()} disabled={this.state.closeDisabled}>
                                                    <CloseIcon fontSize='small' color='error' />
                                                </IconButton>
                                            ) : (null)
                                        }
                                    </Box>
                                    {/*<Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel id="gate_stage">Gate Stage</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label1"
                                        id="gate_stage"
                                        value={this.state.gate_stage}
                                        onChange={handleSelect}
                                        label="Gate Stage"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={0}>Prospect</MenuItem>
                                        <MenuItem value={1}>Go</MenuItem>
                                        <MenuItem value={2}>No Go</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box> */}
                                </Paper>
                            </Grid>

                        </Grid>
                        {/* <Alert severity="warning">This is a warning alert — check it out!</Alert> */}
                        <Box display="flex" justifyContent="center" m={3} p={1} bgcolor="background.paper">

                            <Button size='small' variant="contained" disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                color="primary" onClick={(e) => { this.prevPut() }}>Save</Button>
                        </Box >

                        <Dialog
                            open={this.state.openTenderers}
                            fullWidth="true" maxWidth="xl"
                            onClose={handleCloseTenderers}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>
                                <Tenderers value={this.props.value} />
                            </DialogContent>
                            <DialogActions>
                                <Button size='small' onClick={handleCloseTenderers} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openOrganisation}
                            fullWidth="true" maxWidth="sm"
                            onClose={handleCloseOrganisation}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description" >
                            <DialogTitle id="alert-dialog-slide-title">{"Add Organisation"}</DialogTitle>
                            <DialogContent>
                                {
                                    (this.state.organisationLoading) ? (
                                        <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                                            <CircularProgress color={'primary'} />
                                        </Box>
                                    ) :
                                        <Autocomplete
                                            id="organisation"
                                            options={this.state.organisationData}
                                            value={(!this.state.selectedOrganisation) ? (null) : (this.state.selectedOrganisation)}
                                            getOptionLabel={(option) => option.project_name + (!option.crm_id ? "" : " (" + option.crm_id + ")")}
                                            style={{ width: '100%' }}
                                            onChange={this.onOrganisationChange}
                                            getOptionSelected={(option, { multiple, value }) => {
                                                if (!multiple) {
                                                    return (option.organisation_id === this.state.project_portal_organisation_id);
                                                }
                                                return false;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Organisation Name/CRM" variant="outlined" />}
                                        />}
                            </DialogContent>
                            <DialogActions>
                                <Button variant='contained' disabled={this.state.addDisabled} size='small' onClick={() => this.linkOrganisation()} color="primary">
                                    Add
                                </Button>
                                <Button variant='contained' size='small' onClick={handleCloseOrganisation} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div >
                )
                }
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(TenderDetail));