import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../../Helper/CookieHelper';
import Service from '../../Networking/networkutils';
import { errorMessage } from '../../Helper/ErrorMessage';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { CoffeeLoading } from 'react-loadingg';
import { Alert, AppBar, Autocomplete, Button, CircularProgress, Dialog, DialogContent, IconButton, Slide, TextField, Toolbar } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DateChangeReqManage from '../../DateChangeRequest/DateChangeReqManage';
import WorkIcon from '@mui/icons-material/Work';
import CloseIcon from '@mui/icons-material/Close';
import Allocation from '../../Allocation/Allocation';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AdditianalHoursManage from '../AdditianalHoursManage';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");

const steps = [
    {
        label: 'Timesheets',
        description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
    },
    {
        label: 'Date Change Requests',
        description:
            'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
        label: 'Hours Requests',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Hours Allocation',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
    {
        label: 'Updation',
        description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    }
];
class PendingTasksFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingTasks: this.props.pendingTasks,
            selectedTask: null,
            selectedTaskIndex: 0,
            activeStep: 0,
            isLoading: true,
            pendingReq: null,
            stepperLoading: true,
            dateRquestDialog: false,
            openTaskLevelAllocation: false,
            dateChangeNouns: [],
            dateChangeAdjectives: [],
            usersData: [],
            selectedNoun: null,
            selectedAdjective: null,
            openHourRequest: false,
            delayReason: false,
            saveDisabled: false
        }
    }

    componentDidMount() {
        if (this.props.usersData && this.props.dateChangeNouns && this.props.dateChangeAdjectives) {
            this.setState({ usersData: this.props.usersData, dateChangeNouns: this.props.dateChangeNouns, dateChangeAdjectives: this.props.dateChangeAdjectives }, () => {
                this.getPendingReqForTasks(this.state.pendingTasks[this.state.selectedTaskIndex], true);
            })
        } else {
            this.fetchNounsAdjectivesAndUsers();
        }

        if (this.state.pendingTasks.length !== 0) {
            this.setState({ selectedTask: this.state.pendingTasks[this.state.selectedTaskIndex] })
        }
    }

    fetchPendingTasks = (type) => {

        var url = `/bb/api/tender/wbs/tasks/get_task_flow_data/`
        if (this.props.ds) {
            url = `/bb/api/tender/wbs/tasks/get_task_flow_data/?ds_id=${this.props.ds.id}`
        } else if (this.props.project) {
            url = `/bb/api/tender/wbs/tasks/get_task_flow_data/?project_id=${this.props.project.id}`
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                res.data.forEach(element => {
                    element.old_act_exp_start = element.act_exp_start
                    element.old_act_exp_finish = element.act_exp_finish
                });

                if (this.props.getCount) {
                    this.props.getCount(res.data.length)
                }
                this.setState({ pendingTasks: res.data, isLoading: false, }, () => {
                    if (this.state.pendingTasks.length !== 0) {
                        this.getPendingReqForTasks(this.state.pendingTasks[this.state.selectedTaskIndex], type);
                        this.setState({ selectedTask: this.state.pendingTasks[this.state.selectedTaskIndex], delayReason: false, selectedAdjective: null, selectedNoun: null })
                    }
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchNounsAdjectivesAndUsers() {
        Promise.all([
            this.getNouns(),
            this.getAdjectives(),
            this.getUsers()
        ])
            .then(res => {
                this.setState({ dateChangeNouns: res[0], dateChangeAdjectives: res[1], usersData: res[2] }, () => {
                    this.getPendingReqForTasks(this.state.pendingTasks[this.state.selectedTaskIndex], true);
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    getNouns() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/nouns/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getAdjectives() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/adjective/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getUsers() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/users/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getPendingReqForTasks(task, type) {
        this.setState({ stepperLoading: true });
        Service.get(`/bb/api/tender/wbs/tasks/get_task_pending_data/?task_id=${task.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ pendingReq: res.data }, () => {
                    if (type) {
                        this.goToStep();
                    } else {
                        this.setState({ stepperLoading: false, isLoading: false });
                    }
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    goToStep = () => {
        let { pendingReq } = this.state;
        if (pendingReq?.pending_timesheet?.is_timesheet_pending) {
            this.setState({ activeStep: 0, stepperLoading: false, isLoading: false });
        } else if (pendingReq?.pending_date_change?.is_date_change_pending) {
            this.setState({ activeStep: 1, stepperLoading: false, isLoading: false });
        } else if (pendingReq?.pending_hours_request?.is_hours_request_pending) {
            this.setState({ activeStep: 2, stepperLoading: false, isLoading: false });
        } else {
            this.setState({ activeStep: 3, stepperLoading: false, isLoading: false });
        }
    }

    nextTask = () => {
        this.setState({
            selectedTaskIndex: this.state.selectedTaskIndex + 1,
            selectedTask: this.state.pendingTasks[this.state.selectedTaskIndex + 1],
            pendingReq: null,
            delayReason: false,
            selectedAdjective: null,
            selectedNoun: null
        }, () => {
            this.getPendingReqForTasks(this.state.pendingTasks[this.state.selectedTaskIndex], true);
        });
    }

    handleNext = () => {
        let { pendingReq } = this.state;
        let activeStep = JSON.parse(JSON.stringify(this.state.activeStep));
        activeStep = parseInt(activeStep);
        this.setState({ activeStep: null }, () => {
            setTimeout(() => {
                if (activeStep === 0) {
                    if (pendingReq?.pending_date_change?.is_date_change_pending) {
                        this.setState({ activeStep: 1 });
                    } else if (pendingReq?.pending_hours_request?.is_hours_request_pending) {
                        this.setState({ activeStep: 2 });
                    } else {
                        this.setState({ activeStep: 3 });
                    }
                } else if (activeStep === 1) {
                    if (pendingReq?.pending_hours_request?.is_hours_request_pending) {
                        this.setState({ activeStep: 2 });
                    } else {
                        this.setState({ activeStep: 3 });
                    }
                } else {
                    this.setState({ activeStep: activeStep + 1 });
                }
            }, 500);
        })

    }

    handleBack = () => {
        let { pendingReq } = this.state;
        let activeStep = JSON.parse(JSON.stringify(this.state.activeStep));
        activeStep = parseInt(activeStep);
        this.setState({ activeStep: null }, () => {
            setTimeout(() => {
                if (activeStep === 3) {
                    if (pendingReq?.pending_hours_request?.is_hours_request_pending) {
                        this.setState({ activeStep: 2 });
                    } else if (pendingReq?.pending_date_change?.is_date_change_pending) {
                        this.setState({ activeStep: 1 });
                    } else if (pendingReq?.pending_timesheet?.is_timesheet_pending) {
                        this.setState({ activeStep: 0 });
                    } else {
                        this.setState({ activeStep });
                        this.props.enqueueSnackbar("No more steps to go back", { variant: 'info' });
                    }
                } else if (activeStep === 2) {
                    if (pendingReq?.pending_date_change?.is_date_change_pending) {
                        this.setState({ activeStep: 1 });
                    } if (pendingReq?.pending_timesheet?.is_timesheet_pending) {
                        this.setState({ activeStep: 0 });
                    } else {
                        this.setState({ activeStep });
                        this.props.enqueueSnackbar("No more steps to go back", { variant: 'info' });
                    }
                } else if (activeStep === 1) {
                    if (pendingReq?.pending_timesheet?.is_timesheet_pending) {
                        this.setState({ activeStep: 0 });
                    } else {
                        this.setState({ activeStep });
                        this.props.enqueueSnackbar("No more steps to go back", { variant: 'info' });
                    }
                } else {
                    this.setState({ activeStep: activeStep - 1 });
                }
            }, 500);
        })
    }

    onNounChange = (event, values) => {
        this.setState({
            selectedNoun: values
        })
    }

    onAdjectiveChange = (event, values) => {
        this.setState({
            selectedAdjective: values
        })
    }

    updation = () => {
        if ((!moment(this.state.selectedTask.act_exp_start).isValid()) || (this.state.selectedTask.timesheet_start === null ? false : this.state.selectedTask.timesheet_start !== null && moment(this.state.selectedTask.act_exp_start).format("YYYY-MM-DD") > moment(this.state.selectedTask.timesheet_start).format("YYYY-MM-DD"))) {
            this.props.enqueueSnackbar((!moment(this.state.selectedTask.act_exp_start).isValid() ? "Invalid date format" : this.state.selectedTask.timesheet_start !== null && moment(this.state.selectedTask.act_exp_start).format("YYYY-MM-DD") > moment(this.state.selectedTask.timesheet_start).format("YYYY-MM-DD") ? "Actual Start date should be before or equals to " + moment(this.state.selectedTask.timesheet_start).format('DD/MM/YYYY') : ''), {
                variant: 'warning'
            })
        } else if ((!moment(this.state.selectedTask.act_exp_finish).isValid()) || (moment(this.state.selectedTask.act_exp_finish).toDate() < moment(this.state.selectedTask.act_exp_start).toDate())) {
            this.props.enqueueSnackbar((!moment(this.state.selectedTask.act_exp_finish).isValid()) ? "Invalid date format" : moment(this.state.selectedTask.act_exp_finish).toDate() < moment(this.state.selectedTask.act_exp_start).toDate() ? "Actual/Expected Finish is greater than Actual/Expected Start" : '', {
                variant: 'warning'
            })
        } else if (this.state.delayReason && (!this.state.selectedNoun || !this.state.selectedAdjective)) {
            this.props.enqueueSnackbar('Please select Noun and Adjective', {
                variant: 'warning'
            })
        } else {
            this.setState({ saveDisabled: true })
            let patchData = {
                id: this.state.selectedTask.id,
                act_exp_start: this.state.selectedTask.act_exp_start,
                act_exp_finish: this.state.selectedTask.act_exp_finish,
                comp_percnt: this.state.selectedTask.comp_percnt,
            }
            if (this.state.delayReason) {
                patchData['noun'] = this.state.selectedNoun.id
                patchData['adjective'] = this.state.selectedAdjective.id
            }
            Service.patch(`/bb/api/tender/wbs/tasks/update_single_item_workbook/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: patchData
            })
                .then(res => {
                    this.props.enqueueSnackbar('Task Updated Successfully', {
                        variant: 'success'
                    })
                    this.setState({ delayReason: false, saveDisabled: false, selectedAdjective: null, selectedNoun: null })
                    this.fetchPendingTasks(false);
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    onTaskChange = (event, values) => {
        if (values) {
            let index = this.state.pendingTasks.findIndex(x => x.id === values.id)
            let pendingTasks = this.state.pendingTasks
            const temp = pendingTasks[this.state.selectedTaskIndex];
            pendingTasks[this.state.selectedTaskIndex] = pendingTasks[index]
            pendingTasks[index] = temp;
            this.setState({ pendingTasks, selectedTask: pendingTasks[this.state.selectedTaskIndex] }, () => {
                this.getPendingReqForTasks(this.state.pendingTasks[this.state.selectedTaskIndex], true);
            })
        } else {
            this.setState({ selectedTask: null })
        }
    }

    render() {

        const showStepContent = (step) => {
            let { pendingReq } = this.state;
            switch (step) {
                case 0:
                    return <Box display={'flex'} flexDirection={'column'} >
                        <Alert severity="warning" >
                            {`There are ${pendingReq?.pending_timesheet?.num_of_pending_timesheet} hours pending for this task`}&nbsp;&nbsp;
                            <Button variant='outlined' color="inherit" size="small" onClick={() => window.open('/home/#Your%20Portfolio')}>
                                open
                            </Button>
                        </Alert>
                    </Box>;
                case 1:
                    return <Box display={'flex'} flexDirection={'column'} >
                        <Alert severity="warning">
                            {`There are ${pendingReq?.pending_date_change?.num_of_pending_date_change} datechange requests pending for this task`}&nbsp;&nbsp;
                            <Button variant='outlined' color="inherit" size="small" onClick={() => this.setState({ dateRquestDialog: true })}>
                                open
                            </Button>
                        </Alert>
                    </Box>;
                case 2:
                    return <Box display={'flex'} flexDirection={'column'} >
                        <Alert severity="warning">
                            {`There are ${pendingReq?.pending_hours_request?.num_of_pending_hours_request} hours requests pending for this task`}&nbsp;&nbsp;
                            <Button variant='outlined' color="inherit" size="small" onClick={() => this.setState({ openHourRequest: true })}>
                                Open
                            </Button>
                        </Alert>
                    </Box>;
                case 3:
                    return <Box display={'flex'} flexDirection={'column'} >
                        <Alert severity="info">
                            {`If you want to allocate hours for this task, click on open`}&nbsp;&nbsp;
                            <Button variant='outlined' color="inherit" size="small" startIcon={<WorkIcon />} onClick={() => this.setState({ openTaskLevelAllocation: true })}>
                                Open
                            </Button>
                        </Alert>
                    </Box>;
                case 4:
                    return <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
                            <Box><b>Baseline Start: </b>{moment(this.state.selectedTask.from_date).format('DD-MM-YYYY')}</Box>
                            <Box><b>Baseline Finish: </b>{moment(this.state.selectedTask.to_date).format('DD-MM-YYYY')}</Box>
                            <Box><b>P6 Last Updated: </b>{!this.state.selectedTask.latest_p6_for_task ? "-" : moment(this.state.selectedTask.latest_p6_for_task).format('DD-MM-YYYY')}</Box>
                            <Box><b>Hours %: </b>{this.state.selectedTask.hours_percentage + "%"}</Box>
                            <Box><b>Duration %:</b> {this.state.selectedTask.days_percentage === null ? '-' : (this.state.selectedTask.days_percentage + "%")}</Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} marginTop={'5px'}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant='inline'
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    style={{ width: 370, marginRight: '10px' }}
                                    id="date-picker-inline"
                                    helperText={!moment(this.state.selectedTask.act_exp_start).isValid() ? "Invalid date format" : this.state.selectedTask.timesheet_start !== null && moment(this.state.selectedTask.act_exp_start).format("YYYY-MM-DD") > moment(this.state.selectedTask.timesheet_start).format("YYYY-MM-DD") ? "Actual Start date should be before or equals to " + moment(this.state.selectedTask.timesheet_start).format('DD/MM/YYYY') : ''}
                                    error={(!moment(this.state.selectedTask.act_exp_start).isValid()) || (this.state.selectedTask.timesheet_start === null ? false : this.state.selectedTask.timesheet_start !== null && moment(this.state.selectedTask.act_exp_start).format("YYYY-MM-DD") > moment(this.state.selectedTask.timesheet_start).format("YYYY-MM-DD"))}
                                    value={this.state.selectedTask.act_exp_start ? this.state.selectedTask.act_exp_start : null}
                                    FormHelperTextProps={{ style: { color: 'red' } }}
                                    onChange={(e) => {
                                        let selectedTask = this.state.selectedTask;
                                        selectedTask.act_exp_start = e;
                                        this.setState({ selectedTask })
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    label="Actual/Expected Start"
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant='inline'
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    style={{ width: 380, marginRight: '10px' }}
                                    id="date-picker-inline"
                                    helperText={(!moment(this.state.selectedTask.act_exp_finish).isValid()) ? "Invalid date format" : moment(this.state.selectedTask.act_exp_finish).toDate() < moment(this.state.selectedTask.act_exp_start).toDate() ? "Actual/Expected Finish is greater than Actual/Expected Start" : ''}
                                    error={(!moment(this.state.selectedTask.act_exp_finish).isValid()) || (moment(this.state.selectedTask.act_exp_finish).toDate() < moment(this.state.selectedTask.act_exp_start).toDate())}
                                    value={this.state.selectedTask.act_exp_finish ? this.state.selectedTask.act_exp_finish : null}
                                    FormHelperTextProps={{ style: { color: 'red' } }}
                                    onChange={(e) => {
                                        let selectedTask = this.state.selectedTask;
                                        selectedTask.act_exp_finish = e;
                                        this.setState({ selectedTask }, () => {
                                            let element = this.state.selectedTask
                                            let date1 = !element.old_act_exp_finish ? moment(element.to_date) : moment(element.old_act_exp_finish)
                                            let date2 = moment(element.act_exp_finish)
                                            if (moment(date2).isAfter(date1)) {
                                                this.setState({ delayReason: true })
                                            } else {
                                                this.setState({ delayReason: false, selectedAdjective: null, selectedNoun: null })
                                            }
                                        })
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    label="Actual/Expected Finish"
                                />
                            </MuiPickersUtilsProvider>
                            <FormControl fullWidth >
                                <InputLabel fullWidth id="demo-simple-select-label">% Complete</InputLabel>
                                <Select
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    fullWidth
                                    value={this.state.selectedTask ? this.state.selectedTask.comp_percnt : 0}
                                    onChange={handlePercChange}
                                    label='% Complete'>
                                    <MenuItem value={0}>0% - Not started.</MenuItem>
                                    <MenuItem value={5}>5% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={10}>10% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={20}>20% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={30}>30% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={40}>40% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={50}>50% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={60}>60% - Checked within Department.</MenuItem>
                                    <MenuItem value={65}>65% - Approved within Department.</MenuItem>
                                    <MenuItem value={70}>70% - Site comments received, reviewed.</MenuItem>
                                    <MenuItem value={80}>80% - Multi-disciplinary Reviewed & Commented.</MenuItem>
                                    <MenuItem value={90}>90% - Design verified.</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        {this.state.delayReason ?
                            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} marginTop={'10px'}>
                                <Autocomplete
                                    size='small'
                                    id={"nouns"}
                                    fullWidth
                                    style={{ width: 300, marginRight: '10px' }}
                                    value={this.state.selectedNoun}
                                    options={this.state.dateChangeNouns}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(e, v) => this.onNounChange(e, v)}
                                    renderInput={(params) => <TextField {...params} label="Select Noun" variant="outlined" />}
                                />
                                <Autocomplete
                                    size='small'
                                    id={"adjectives"}
                                    fullWidth
                                    style={{ width: 300 }}
                                    value={this.state.selectedAdjective}
                                    options={this.state.dateChangeAdjectives}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(e, v) => this.onAdjectiveChange(e, v)}
                                    renderInput={(params) => <TextField {...params} label="Select Adjective" variant="outlined" />}
                                />
                            </Box> : null}
                    </Box>;
                default:
                    return '';
            }
        }

        const showIcon = (index) => {
            let { pendingReq } = this.state;
            let activeStep = JSON.parse(JSON.stringify(this.state.activeStep));
            if (index === 0 && activeStep !== 0 && pendingReq?.pending_timesheet?.is_timesheet_pending) {
                return <WarningAmberIcon color='warning' />;
            } else if (index === 1 && activeStep !== 1 && pendingReq?.pending_date_change?.is_date_change_pending) {
                return <WarningAmberIcon color='warning' />;
            } else if (index === 2 && activeStep !== 2 && pendingReq?.pending_hours_request?.is_hours_request_pending) {
                return <WarningAmberIcon color='warning' />;
            } else {
                return null;
            }
        }

        const handleDateChangeReqClose = () => {
            this.setState({
                dateRquestDialog: false,
            })
        };

        const handlePercChange = (e) => {
            let selectedTask = this.state.selectedTask;
            selectedTask.comp_percnt = e.target.value
            this.setState({ selectedTask })
        };

        const handleHourRequestClose = () => {
            this.setState({
                openHourRequest: false,
            })
        };

        return (
            <div>
                {
                    this.state.isLoading ?
                        <Box m={5} p={2}>
                            <CoffeeLoading />
                        </Box> :
                        this.state.pendingTasks.length === 0 ?
                            <Box m={5} p={2}>
                                <Typography variant="h6" gutterBottom>
                                    No Pending Tasks
                                </Typography>
                            </Box> :
                            this.state.stepperLoading ?

                                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                    <CircularProgress color='primary' />
                                </Box> :

                                <Box display={'flex'} flexDirection={'column'}>
                                    <Box display={'flex'} flexDirection={'row'} mb={1}>
                                        <Box flexGrow={1}>
                                            <Autocomplete
                                                size='small'
                                                id={"task"}
                                                fullWidth
                                                value={this.state.selectedTask}
                                                options={this.state.pendingTasks}
                                                getOptionLabel={(option) => this.props.project ? (option.ds_name + ' - ' + option.name) : option.name}
                                                onChange={(e, v) => this.onTaskChange(e, v)}
                                                renderInput={(params) => <TextField {...params} label={this.props.project ? "DS - Tasks" : "Tasks"} variant="outlined" />}
                                            />
                                        </Box>
                                        {(this.state.selectedTaskIndex === this.state.pendingTasks.length - 1) ? null :
                                            <Button
                                                size='small'
                                                color="primary"
                                                onClick={() => this.nextTask()} >
                                                Skip Task
                                            </Button>}
                                    </Box>
                                    {!this.state.selectedTask ?
                                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                            Select the task to proceed
                                        </Box> :
                                        <Stepper activeStep={this.state.activeStep} orientation="vertical">
                                            {steps.map((step, index) => (
                                                <Step key={step.label}>
                                                    <StepLabel icon={showIcon(index)} >
                                                        {step.label}
                                                    </StepLabel>
                                                    <StepContent>
                                                        {showStepContent(this.state.activeStep)}
                                                        <Box sx={{ mb: 2 }}>
                                                            {(index === 4 && this.state.selectedTaskIndex === this.state.pendingTasks.length - 1) ? null :
                                                                <Button
                                                                    size='small'
                                                                    variant="contained"
                                                                    onClick={() => { (index === steps.length - 1) ? this.nextTask() : this.handleNext() }}
                                                                    sx={{ mt: 1, mr: 1 }} >
                                                                    {index === steps.length - 1 ? 'Next Task' : 'Continue'}
                                                                </Button>
                                                            }
                                                            {index === 4 ?
                                                                <Button
                                                                    size='small'
                                                                    variant="contained"
                                                                    onClick={() => this.updation()}
                                                                    sx={{ mt: 1, mr: 1 }}
                                                                    disabled={this.state.saveDisabled}>
                                                                    save & Next
                                                                </Button> : null
                                                            }
                                                            {index !== 0 ?
                                                                <Button
                                                                    size='small'
                                                                    disabled={index === 0}
                                                                    onClick={() => this.handleBack()}
                                                                    sx={{ mt: 1, mr: 1 }}>
                                                                    Back
                                                                </Button> : null}
                                                        </Box>
                                                    </StepContent>
                                                </Step>
                                            ))}
                                        </Stepper>}
                                </Box>
                }

                <Dialog
                    open={this.state.dateRquestDialog}
                    maxWidth={"xl"}
                    fullWidth
                    onClose={handleDateChangeReqClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box display="flex" flexDirection="row-reverse">
                        <Button color='primary' onClick={handleDateChangeReqClose}>Close</Button>
                    </Box>
                    <DialogContent>
                        <DateChangeReqManage
                            taskLevel={this.state.selectedTask}
                        />
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullScreen
                    open={this.state.openTaskLevelAllocation}
                    onClose={() => this.setState({ openTaskLevelAllocation: false })}
                    TransitionComponent={Transition}>
                    <AppBar sx={{ position: 'relative' }} color='primary'>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => this.setState({ openTaskLevelAllocation: false })}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                {(this.state.selectedTask) ? (!this.state.selectedTask.decs ? this.state.selectedTask.name : this.state.selectedTask.decs) : ''}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent >
                        <Allocation value={this.state.selectedTask} usersData={this.state.usersData} />
                    </DialogContent>
                </Dialog>

                <Dialog onClose={handleHourRequestClose} aria-labelledby="customized-dialog-title" maxWidth="xl" open={this.state.openHourRequest}>
                    <DialogContent>
                        <Box display="flex" flexDirection="row-reverse">
                            <Button color='primary' onClick={handleHourRequestClose}>Close</Button>
                        </Box>
                        <AdditianalHoursManage task={this.state.selectedTask?.id} />
                    </DialogContent>
                </Dialog>

            </div>
        );
    }
}

export default withSnackbar(PendingTasksFlow);