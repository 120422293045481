import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import PIMHandle from './PIMHandle';
import AWPIMGanttChart from './AWPIMGanttChart';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getCookie } from '../../Helper/CookieHelper';
import Service from '../../Networking/networkutils';
import Drawer from '@material-ui/core/Drawer';
import AWPIMDrawer from './AWPIMDrawer';
import { screenSizeCheck } from '../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { Button, Typography, withStyles } from '@material-ui/core'
import clsx from 'clsx';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { AppBar, Toolbar, IconButton, Box } from '@material-ui/core';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PIMLink from '../PIMLink/PIMLink';

const token = getCookie("bb_ts_token");

const drawerWidth = window.innerWidth / (screenSizeCheck() ? 1.9 : 2.5);

const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

class AWPIMView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'gantt',
            coffeeLoading: true,
            openMenu: false,
            isLoading: true,
            menuData: null,
            expand: true,
        };
        this.ganttRef = React.createRef();
        this.tableRef = React.createRef();
        this.linkRef = React.createRef();
        this.drawerRef = React.createRef();
        this.openMenu = this.openMenu.bind(this)
        this.refreshChart = this.refreshChart.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.checkConditions = this.checkConditions.bind(this)
        this.getExpand = this.getExpand.bind(this)
    }

    changeView = (view) => {
        this.setState({ view: view })
    }

    openMenu(data) {
        if (this.state.openMenu) {
            this.checkConditions('init', data)
        } else {
            this.setState({ menuData: data, openMenu: false }, () => {
                this.setState({ openMenu: true, }, () => {
                    if (this.ganttRef && this.ganttRef.current) {
                        this.ganttRef.current.showOptions(false)
                    }
                });
            })
        }
    }

    getData(data) {
        Service.get(`/bb/api/p6/activity/update/get_p6_activity_details/?project_id=${this.props.data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element && element.p6_data) {
                        if (element.p6_data.object_id === data.object_id) {
                            this.setState({ menuData: element, isLoading: false })
                            break
                        }
                    }
                }
                this.setState({
                    isLoading: false
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    refreshChart() {
        if (this.ganttRef && this.ganttRef.current) {
            this.ganttRef.current.fetchData('update')
        }
    }

    handleClose() {
        if (this.ganttRef && this.ganttRef.current) {
            let width = 50
            if (localStorage.getItem('chartWidth')) {
                width = localStorage.getItem('chartWidth')
            }
            this.ganttRef.current.handleChange(null, width)
            this.ganttRef.current.showOptions(true)
        }
        this.setState({ openMenu: false, menuData: null });
    }

    decreaseZoom = () => {
        if (this.ganttRef && this.ganttRef.current) {
            this.ganttRef.current.decreaseZoom()
        } else if (this.tableRef && this.tableRef.current) {
            this.tableRef.current.decreaseZoom()
        } else if (this.linkRef && this.linkRef.current) {
            this.linkRef.current.decreaseZoom()
        }
    }

    increaseZoom = () => {
        if (this.ganttRef && this.ganttRef.current) {
            this.ganttRef.current.increaseZoom()
        } else if (this.tableRef && this.tableRef.current) {
            this.tableRef.current.increaseZoom()
        } else if (this.linkRef && this.linkRef.current) {
            this.linkRef.current.increaseZoom()
        }
    }

    checkConditions = (type, data) => {
        if (type === 'init') {
            if (this.drawerRef && this.drawerRef.current) {
                this.drawerRef.current.checkConditions(data)
            }
        } else {
            this.setState({ menuData: data, openMenu: false }, () => {
                this.setState({ openMenu: true, }, () => {
                    if (this.ganttRef && this.ganttRef.current) {
                        this.ganttRef.current.showOptions(false)
                    }
                });
            })
        }
    }

    setExpand = () => {
        if (this.ganttRef && this.ganttRef.current) {
            this.ganttRef.current.setExpand()
        } else if (this.tableRef && this.tableRef.current) {
            this.tableRef.current.setExpand()
        } else if (this.linkRef && this.linkRef.current) {
            this.linkRef.current.expandAll(!this.state.expand)
        }
        this.setState({ expand: !this.state.expand })
    }

    getExpand = (expand) => {
        this.setState({ expand: expand })
    }

    render() {

        const { classes } = this.props;

        const handleToggle = (e, newValue) => {
            if (newValue !== null) {
                this.setState({ view: newValue, })
            };
        }

        const handlePimDialogClose = () => {
            if (this.props.handlePimDialogClose) {
                this.props.handlePimDialogClose()
            }
        }


        return (
            <div >
                <AppBar position="fixed">
                    <Toolbar>
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} width={'100%'}>
                            <Box flexGrow={1} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <IconButton edge="start" color="inherit" onClick={handlePimDialogClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" >
                                    {this.props.data ? this.props.data.name : "PIM"}
                                </Typography>
                            </Box>

                            <ThemeProvider theme={darkTheme}>
                                <Button
                                    variant='outlined'
                                    onClick={this.setExpand}
                                    size='small'
                                    color='inherit'
                                    endIcon={this.state.expand === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                    style={{ marginRight: '20px' }}>
                                    {this.state.expand === false ? 'Expand All' : 'Collapse All'}
                                </Button>
                                <ToggleButtonGroup
                                    exclusive
                                    aria-label="text alignment"
                                    style={{ marginRight: "10px", float: 'right' }}
                                    size='small'
                                    color='primary'>
                                    <ToggleButton value="weeks" aria-label="by week" onClick={this.decreaseZoom} >
                                        <ZoomOut />
                                    </ToggleButton>
                                    <ToggleButton value="months" aria-label="by month" onClick={this.increaseZoom}>
                                        <ZoomIn />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <ToggleButtonGroup
                                    size="small"
                                    value={this.state.view}
                                    onChange={handleToggle}
                                    exclusive
                                    aria-label="text formatting"
                                >
                                    <ToggleButton value={"gantt"} aria-label="gantt">
                                        Gantt
                                    </ToggleButton>
                                    <ToggleButton value={"table"} aria-label="table">
                                        Table
                                    </ToggleButton>
                                    <ToggleButton value={"link"} aria-label="table">
                                        Link P6
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </ThemeProvider>
                        </Box>
                    </Toolbar>
                </AppBar>
                {
                    this.state.view === 'table' ?
                        <div style={{ padding: '0px', marginTop: "65px", }}>
                            <PIMHandle
                                ref={this.tableRef}
                                data={this.props.data}
                                getExpand={this.getExpand}
                            />
                        </div> :
                        this.state.view === 'link' ?
                            <div style={{ padding: '0px', marginTop: "65px", }}>
                                <PIMLink
                                    ref={this.linkRef}
                                    projectData={this.props.data}
                                    getExpand={this.getExpand}
                                />
                            </div> :
                            <main
                                className={clsx(classes.content, {
                                    [classes.contentShift]: this.state.openMenu,
                                })}
                                style={{ padding: '0px', marginTop: "65px", width: '100%' }}>
                                <AWPIMGanttChart
                                    ref={this.ganttRef}
                                    openMenu={this.openMenu}
                                    drawerWidth={drawerWidth}
                                    project={this.props.data}
                                    getExpand={this.getExpand}
                                />
                            </main>
                }
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={this.state.openMenu}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    {this.state.openMenu ?
                        <AWPIMDrawer
                            ref={this.drawerRef}
                            data={this.state.menuData}
                            refreshChart={this.refreshChart}
                            handleClose={this.handleClose}
                            checkConditions={this.checkConditions}
                        />
                        : null}
                </Drawer>
            </div>
        );
    }
}

export default withStyles(useStyles)(withSnackbar(AWPIMView));

