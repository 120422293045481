import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UsersTimesheets from './UsersTimesheets';
import TeamsTimesheets from './TeamsTimesheets';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
// import TimesheetSummary from './TimesheetSummary';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

export default function TimesheetSummaryDetailsTab(props) {
  // console.log(props)
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTotalSubmitted = (data) => {
    return data.leave + data.sick + data.bank_holiday + data.training + data.chargeable + data.tenders + data.overheads
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      {(props.memid || props.gid) ?
        <UsersTimesheets value={props.value} uid={props.uid} gid={props.gid} memid={props.memid} company={props.company} location={props.location}/>
        :
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Team Timesheet Report" {...a11yProps(0)} />
              <Tab label="User Timesheet Report" {...a11yProps(1)} />

              {/* <Tab label="Weekly Timesheet Summary Report" {...a11yProps(2)} /> */}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <TeamsTimesheets value={props.value} uid={props.uid} gid={props.gid} company={props.company} location={props.location} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <UsersTimesheets value={props.value} uid={props.uid} gid={props.gid} company={props.company} location={props.location} />
            </TabPanel>
          </SwipeableViews>
        </>
      }

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Leave</TableCell>
              <TableCell align="center">Sick</TableCell>
              <TableCell align="center">Bank Holiday</TableCell>
              <TableCell align="center">Training</TableCell>
              <TableCell align="center">Tenders</TableCell>
              <TableCell align="center">Non-Billable</TableCell>
              <TableCell align="center">Chargeable</TableCell>
              <TableCell align="center">Utilization (Chargeable)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="row">
              <TableCell align="center">Total for all teams</TableCell>
              <TableCell align="center">{props.data.leave}</TableCell>
              <TableCell align="center">{props.data.sick}</TableCell>
              <TableCell align="center">{props.data.bank_holiday}</TableCell>
              <TableCell align="center">{props.data.training}</TableCell>
              <TableCell align="center">{props.data.tenders}</TableCell>
              <TableCell align="center">{props.data.overheads}</TableCell>
              <TableCell align="center">{props.data.chargeable}</TableCell>
              <TableCell align="center">{((props.data.chargeable / getTotalSubmitted(props.data)) * 100).toFixed(1)}%</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
