import React, { Component } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import '../../WorkHours/TeamWorkloadGantt/Gantt.css'
import { withSnackbar } from 'notistack';
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import { Button, Paper } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Box, TextField } from '@mui/material';
import { getDarkMode } from '../../Helper/CommonThings';

// const token = getCookie("bb_ts_token");
class PIMGanttChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expand: true,
        }
    }

    componentDidMount() {
        this.getData([])
    }
    componentWillUnmount() {
        gantt.clearAll();
    }

    getData(taskArray) {
        gantt.resetLayout();
        gantt.config.drag_move = false;
        gantt.config.readonly = true;
        // gantt.plugins({ tooltip: true, marker: true })

        gantt.config.columns = [
            { name: "is_linked", label: 'Action', align: "center", minWidth: 50, width: 50, template: this.myFunc },
            { name: "text", label: 'Project', align: "left", tree: true, minWidth: 250, width: 250 },
            { name: "p6_id", label: 'P6 ID', align: "center", minWidth: 200, width: 200 },
            // { name: "from_date", label: 'Baseline Start', align: "center", minWidth: 120, width: 120 },
            // { name: "to_date", label: 'Baseline Finish', align: "center", minWidth: 120, width: 120 },
            { name: "start_date", label: "Baseline Start", align: "center", minWidth: 120, width: 120 },
            { name: "end_date", label: "Baseline Finish", align: "center", minWidth: 120, width: 120 },
            { name: "duration", label: "Duration", align: "center", minWidth: 120, width: 120 },
            { name: "date_created", label: "Imported On", align: "center", minWidth: 110, width: 110 },
            // { name: "percent", label: "% Complete", align: "center", minWidth: 120, width: 120 },
            // { name: "reason_for_change", label: "Reason for Change", align: "center", minWidth: 150, width: 150 },
        ]

        gantt.config.layout = {
            css: "gantt_container",
            cols: [
                {
                    width: 600,
                    minWidth: 300,
                    maxWidth: 600,
                    rows: [
                        { view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer" },

                        // horizontal scrollbar for the grid
                        { view: "scrollbar", id: "gridScroll", group: "horizontal" }
                    ]
                },
                { resizer: true, width: 1 },
                {
                    rows: [
                        { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },

                        // horizontal scrollbar for the timeline
                        { view: "scrollbar", id: "scrollHor", group: "horizontal" }
                    ]
                },
                { view: "scrollbar", id: "scrollVer" }
            ]
        };

        var tasks = {
            data: taskArray.length === 0 ? this.props.data : taskArray
        }

        var zoomConfig = {
            levels: [
                {
                    name: "day",
                    scale_height: 40,
                    min_column_width: 80,
                    scales: [
                        { unit: "day", step: 1, format: "%d %M" }
                    ]
                },
                {
                    name: "week",
                    scale_height: 50,
                    min_column_width: 50,
                    scales: [
                        {
                            unit: "week", step: 1, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%d %M");
                                var endDate = gantt.date.add(date, 6, "day");
                                var weekNum = gantt.date.date_to_str("%W")(date);
                                return "Week " + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        },
                        { unit: "day", step: 1, format: "%j %D" }
                    ]
                },
                {
                    name: "month",
                    scale_height: 50,
                    min_column_width: 120,
                    scales: [
                        { unit: "month", format: "%F, %Y" },
                        // { unit: "week", format: "Week %W" }
                        {
                            unit: "week", format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%d %M");
                                var endDate = gantt.date.add(date, 6, "day");
                                // var weekNum = gantt.date.date_to_str("%W")(date);
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        }
                    ]
                },
                {
                    name: "quarter",
                    height: 50,
                    min_column_width: 90,
                    scales: [
                        { unit: "month", step: 1, format: "%M" },
                        {
                            unit: "quarter", step: 1, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%M");
                                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        }
                    ]
                },
                {
                    name: "year",
                    scale_height: 50,
                    min_column_width: 30,
                    scales: [
                        { unit: "year", step: 1, format: "%Y" }
                    ]
                }
            ]
        };

        var filterValue = "";
        var delay;

        gantt.$doFilter = function (value) {
            // console.log(value, 'do filter')
            filterValue = value;
            clearTimeout(delay);
            delay = setTimeout(function () {
                gantt.render();
            }, 200)
        }

        gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
            // console.log(filterValue,'filtervalue before task')
            if (!filterValue) return true;
            if (!task.p6_id) return false;
            var searchvalue = task.text + " " + task.p6_id;
            var normalizedText = searchvalue.toLowerCase();
            var normalizedValue = filterValue.toLowerCase();
            return normalizedText.indexOf(normalizedValue) > -1;
        });

        gantt.config.lightbox.sections = [
            { name: "description", height: 70, map_to: "text", type: "textarea" },
            { name: "type", type: "typeselect", map_to: "type" },
            { name: "time", height: 72, type: "duration", map_to: "auto" }
        ];

        gantt.templates.rightside_text = function (start, end, task) {
            if (task.type === gantt.config.types.milestone) {
                return task.milestone_type;
            }
            return "";
        };
        gantt.config.order_branch = true;

        gantt.attachEvent("onTaskClick", (id, e) => {
            const task = gantt.getTask(id)
            var button = e.target.closest("[data-action]")
            if (button) {
                var action = button.getAttribute("data-action");
                switch (action) {
                    case "link":
                        task.is_linked = true
                        this.postData(task.p6_id)
                        // gantt.render()
                        break;
                    default:
                        break;
                }
                return false;
            }
            return true;
        }, { id: "my-click" });
        gantt.templates.grid_row_class = function (start, end, task) {
            if (task.data_missing) {
                return 'gantt_missing_color'
            } else if (task.is_linked) {
                return 'gantt_bg_color'
            } else if (task.new_data) {
                return 'gantt_newlyadded_color'
            } 
        };
        gantt.config.row_height = 30;
        gantt.config.show_unscheduled = true;
        gantt.ext.zoom.init(zoomConfig);
        gantt.ext.zoom.setLevel("month");
        gantt.init('gantt_here');
        gantt.parse(tasks);

    }

    zoomIn() {
        gantt.ext.zoom.zoomIn()
    }

    zoomOut() {
        gantt.ext.zoom.zoomOut()
    }

    expandAll() {
        gantt.eachTask(function (task) {
            task.$open = true
        });
        gantt.render();
    }

    collapseAll() {
        gantt.eachTask(function (task) {
            task.$open = false
        });
        gantt.render();
    }

    search = (value) => {
        gantt.$doFilter(value)
    }

    setExpand = () => {
        this.setState({ expand: !this.state.expand }, () => {
            if (this.state.expand === true) {
                this.expandAll()
            } else {
                this.collapseAll()
            }
        })
    }

    postData = (id) => {
        if (this.props.addData) {
            this.props.addData(id)
            // task.is_linked = true
            // gantt.detachEvent("my-click");
            gantt.render()
        }
    }

    myFunc = (task) => {
        // console.log(task)
        if (task.is_linked) {
            return '<span class="material-icons" style=cursor:pointer;fontsize:small" data-action="edit">check</span>'
        } else if (task.p6_id && !task.is_linked && !task.data_missing) {
            return '<span class="material-icons" style=cursor:pointer;fontsize:small" data-action="link">add</span>'
        }
    }

    render() {

        return (
            <div style={{ height: '100%', marginTop: '70px' }}>
                <Paper elevation={2} style={{ padding: "3px", margin: "3px", backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' }}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-evenly'} >
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <b>Project: </b> &nbsp;
                            {this.props.p6_data?.project_name}
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <b>DS:</b>&nbsp;
                            {this.props.p6_data?.ds_name}
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <b>WBS: </b>&nbsp;
                            {this.props.p6_data?.wbs_name}
                        </Box>
                    </Box>
                </Paper>

                <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%", padding: '5px', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
                        <Button
                            variant='outlined'
                            onClick={this.setExpand}
                            size='small'
                            endIcon={this.state.expand === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                            style={{ marginLeft: '20px', marginRight: '20px' }}>
                            {this.state.expand === false ? 'Expand All' : 'Collapse All'}
                        </Button>
                        <TextField
                            id="outlined-basic"
                            label="Search"
                            variant="outlined"
                            size="small"
                            onChange={(e) => this.search(e.target.value)}
                            style={{ marginRight: '10px' }}
                        />
                    </Box>
                    <Box>
                        <Button variant="outlined" onClick={() => this.zoomOut()} size='small' startIcon={<ZoomOut />} style={{ marginRight: '10px' }}>Zoom Out</Button>
                        <Button variant="outlined" onClick={() => this.zoomIn()} size='small' startIcon={<ZoomIn />} style={{ marginRight: '10px' }}>Zoom IN</Button>
                    </Box>
                </Box>
                <div
                    id='gantt_here'
                    style={{ width: '100%', height: '84vh' }}
                ></div>
            </div>
        );
    }
}

export default withSnackbar(PIMGanttChart);
