import { Box, Button, MenuItem, Select, TextField } from '@mui/material';
import React, { Component } from 'react';
import { percentageComplete } from '../Helper/CommonThings';
import moment from 'moment';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';

const token = getCookie("bb_ts_token");

var percentage_complete = percentageComplete();

class EditTaskDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            task: props.data,
            updateDisabled: false
        }
    }

    editTask(key, value) {
        let task = this.state.task;
        task[key] = value;
        this.setState({ task: task }, () => {
            if ((!moment(this.state.task.act_exp_start).isValid()) || (this.state.task.timesheet_start === null ? false : this.state.task.timesheet_start !== null && moment(this.state.task.act_exp_start).format("YYYY-MM-DD") > moment(this.state.task.timesheet_start).format("YYYY-MM-DD"))) {
                this.setState({ updateDisabled: true })
            } else if ((!moment(this.state.task.act_exp_finish).isValid()) || (moment(this.state.task.act_exp_finish).toDate() < moment(this.state.task.act_exp_start).toDate())) {
                this.setState({ updateDisabled: true })
            } else {
                this.setState({ updateDisabled: false })
            }
        });
    }

    bulkEditListItems = () => {
        this.setState({ updateDisabled: true })
        Service.patch('/bb/api/tender/wbs/tasks/bulk_update_workbook/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: [this.state.task],
        })
            .then(res => {
                if (this.props.refreshData) {
                    this.props.refreshData()
                }
                this.props.enqueueSnackbar('Data Updated Successfully', {
                    variant: 'success'
                });
                this.setState({ updateDisabled: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
                this.setState({ updateDisabled: false })
            });
    }

    render() {
        return (
            <div>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} >
                    <Select
                        fullWidth
                        size='small'
                        value={this.state.task?.comp_percnt}
                        onChange={(e) => {
                            this.editTask('comp_percnt', e.target.value)
                        }}>
                        {percentage_complete.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>))}
                    </Select>
                    <TextField
                        sx={{ marginTop: '10px' }}
                        fullWidth
                        size='small'
                        variant='outlined'
                        type='date'
                        helperText={!moment(this.state.task.act_exp_start).isValid() ? "Invalid date format" : this.state.task.timesheet_start !== null && moment(this.state.task.act_exp_start).format("YYYY-MM-DD") > moment(this.state.task.timesheet_start).format("YYYY-MM-DD") ? "Actual Start date should be before or equals to " + moment(this.state.task.timesheet_start).format('DD/MM/YYYY') : ''}
                        error={(!moment(this.state.task.act_exp_start).isValid()) || (this.state.task.timesheet_start === null ? false : this.state.task.timesheet_start !== null && moment(this.state.task.act_exp_start).format("YYYY-MM-DD") > moment(this.state.task.timesheet_start).format("YYYY-MM-DD"))}
                        defaultValue={moment(this.state.task.act_exp_start).format('yyyy-MM-DD')}
                        onChange={(e) => {
                            this.editTask('act_exp_start', e.target.value)
                        }}
                    />
                    <TextField
                        sx={{ marginTop: '10px' }}
                        fullWidth
                        size='small'
                        variant='outlined'
                        type='date'
                        helperText={(!moment(this.state.task.act_exp_finish).isValid()) ? "Invalid date format" : moment(this.state.task.act_exp_finish).toDate() < moment(this.state.task.act_exp_start).toDate() ? "Actual/Expected Finish is greater than Actual/Expected Start" : ''}
                        error={(!moment(this.state.task.act_exp_finish).isValid()) || (moment(this.state.task.act_exp_finish).toDate() < moment(this.state.task.act_exp_start).toDate())}
                        defaultValue={moment(this.state.task.act_exp_finish).format('yyyy-MM-DD')}
                        onChange={(e) => {
                            this.editTask('act_exp_finish', e.target.value)
                        }} />
                    <Button size='small' disabled={this.state.updateDisabled} sx={{ marginTop: '10px' }} variant="contained" color="primary" onClick={() => this.bulkEditListItems()}>Update</Button>
                </Box>
            </div>
        );
    }
}

export default withSnackbar(EditTaskDetails);