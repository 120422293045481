import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import Accordions from './Accordions';
import { Box } from '@material-ui/core';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';
// import TimeSheetDialog from '../../TimeSheet/TimeSheetDialog';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class UserPermission extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: [],
            comData: {},
            resData: {},
        }

        this.rewriteData = this.rewriteData.bind(this)
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get(`/bb/api/access/?uid=` + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                console.log(res.data)
                this.setState({
                    data: res.data,
                });
                // console.log(res.data)
                this.compileData(res.data);
            })
            .catch(error => {
                this.setState({ isLoading: false })
                errorMessage(this.props, error)
            });
    }

    compileData(response) {
        var loca = {
            l0a: {
                l0a_a: false,
                l0a_b: false,
            },
            l1a: {
                l1a_a: false,
                l1a_b: false,
                l1a_c: false,
            },
            l2a: {
                l2a_a: false,
                l2a_b: false,
            },
            l3a: {
                l3a_a: false,
                l3a_b: false,
                l3a_c: false,
                l3a_d: false,
                l3a_e: false,

            },
            l4a: {
                l4a_a: false,
                l4a_b: false,
                l4a_c: false,
            },
            l5a: {
                l5a_a: false,

            },
            l6a: {
                l6a_a: false,
                l6a_b: false,
                l6a_c: false,
                l6a_d: false,
                l6a_e: false,
                l6a_f: false,
                l6a_g: false,
                l6a_h: false,
                l6a_i: false,
                l6a_j: false,
                l6a_k: false,
                l6a_l: false,
                l6a_m: false,
                l6a_n: false,
                l6a_o: false,
                l6a_p: false,
                l6a_q: false,
                l6a_r: false,
                l6a_s: false,
                l6a_t: false,
                l6a_u: false,
            },
            l7a: {
                l7a_a: false,
                l7a_b: false,
                l7a_c: false,
                l7a_d: false,
            },
            l8a: {
                l8a_a: false,
                l8a_b: false,

            },
            l9a: {
                l9a_a: false,
                l9a_b: false,
            },
            l10a: {
                l10a_a: false,
                l10a_b: false,
            },
            l11a: {
                l11a_a: false,
                l11a_b: false,
            },
            l12a: {
                l12a_a: false,
                l12a_b: false,
            },
            l13a: {
                l13a_a: false,
                l13a_b: false,
                l13a_c: false,
            },
            l14a: {
                l14a_a: false,

            },
            l15a: {
                l15a_a: false,

            },
            l16a: {
                l16a_a: false,

                l16a_i: false,
            },
            l17a: {
                l17a_a: false,
            },
            l18a: {
                l18a_a: false,
            },
            l19a: {
                l19a_a: false,
            },
            l20a: {
                l20a_a: false,
            },
            l21a: {
                l21a_a: false,
            },
            l22a: {
                l22a_a: false,
            },

            l23a: {
                l23a_a: false,
                l23a_b: false,
                l23a_c: false,
                l23a_d: false,
            },

            l24a: {
                l24a_a: false,
                l24a_b: false,
                l24a_c: false,
            },

            l111a: {
                l111a_a: false,
                l111a_b: false,
                l111a_c: false,
            },

        };
        var data = {
            l0a: {
                left: [],
                right: [],
            },
            l1a: {
                left: [],
                right: [],
            },
            l2a: {
                left: [],
                right: [],
            },
            l3a: {
                left: [],
                right: [],
            },
            l4a: {
                left: [],
                right: [],
            },
            l5a: {
                left: [],
                right: [],
            },
            l6a: {
                left: [],
                right: [],
            },
            l7a: {
                left: [],
                right: [],
            },
            l8a: {
                left: [],
                right: [],
            },
            l9a: {
                left: [],
                right: [],
            },
            l10a: {
                left: [],
                right: [],
            },
            l11a: {
                left: [],
                right: [],
            },
            l12a: {
                left: [],
                right: [],
            },
            l13a: {
                left: [],
                right: [],
            },
            l14a: {
                left: [],
                right: [],
            },
            l15a: {
                left: [],
                right: [],
            },
            l16a: {
                left: [],
                right: [],
            },
            l17a: {
                left: [],
                right: [],
            },
            l18a: {
                left: [],
                right: [],
            },
            l19a: {
                left: [],
                right: [],
            },
            l20a: {
                left: [],
                right: [],
            },
            l21a: {
                left: [],
                right: [],
            },
            l22a: {
                left: [],
                right: [],
            },
            l23a: {
                left: [],
                right: [],
            },

            l24a: {
                left: [],
                right: [],
            },


            l111a: {
                left: [],
                right: [],
            },
        };

        if (response.length !== 0) {
            loca = response[0].access;
        }

        if (!loca.l0a) {
            loca.l0a = {}
        }
        if (!loca.l1a) {
            loca.l1a = {}
        }
        if (!loca.l2a) {
            loca.l2a = {}
        }
        if (!loca.l3a) {
            loca.l3a = {}
        }
        if (!loca.l4a) {
            loca.l4a = {}
        }
        if (!loca.l5a) {
            loca.l5a = {}
        }
        if (!loca.l6a) {
            loca.l6a = {}
        }
        if (!loca.l7a) {
            loca.l7a = {}
        }
        if (!loca.l8a) {
            loca.l8a = {}
        }
        if (!loca.l9a) {
            loca.l9a = {}
        }
        if (!loca.l10a) {
            loca.l10a = {}
        }
        if (!loca.l11a) {
            loca.l11a = {}
        }
        if (!loca.l12a) {
            loca.l12a = {}
        }
        if (!loca.l13a) {
            loca.l13a = {}
        }
        if (!loca.l14a) {
            loca.l14a = {}
        }
        if (!loca.l15a) {
            loca.l15a = {}
        }
        if (!loca.l16a) {
            loca.l16a = {}
        }
        if (!loca.l17a) {
            loca.l17a = {}
        }
        if (!loca.l18a) {
            loca.l18a = {}
        }
        if (!loca.l19a) {
            loca.l19a = {}
        }
        if (!loca.l20a) {
            loca.l20a = {}
        }
        if (!loca.l21a) {
            loca.l21a = {}
        }
        if (!loca.l22a) {
            loca.l22a = {}
        }
        if (!loca.l23a) {
            loca.l23a = {}
        }

        if (!loca.l24a) {
            loca.l24a = {}
        }


        if (!loca.l111a) {
            loca.l111a = {}
        }


        if (loca.l0a && loca.l0a.l0a_a) {
            data.l0a.right.push({
                id: 'l0a_a',
                name: 'Basic Param Menus'
            })
        } else if (loca.l0a) {
            data.l0a.left.push({
                id: 'l0a_a',
                name: 'Basic Param Menus'
            })
        }

        if (loca.l0a && loca.l0a.l0a_b) {
            data.l0a.right.push({
                id: 'l0a_b',
                name: 'Estimate Only'
            })
        } else if (loca.l0a) {
            data.l0a.left.push({
                id: 'l0a_b',
                name: 'Estimate Only'
            })
        }

        if (loca.l1a && loca.l1a.l1a_a) {
            data.l1a.right.push({
                id: 'l1a_a',
                name: 'View Tasks and Schedule'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_a',
                name: 'View Tasks and Schedule'
            })
        }
        if (loca.l1a && loca.l1a.l1a_b) {
            data.l1a.right.push({
                id: 'l1a_b',
                name: 'Add and Edit Tasks and Schedule'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_b',
                name: 'Add and Edit Tasks and Schedule'
            })
        }

        if (loca.l1a && loca.l1a.l1a_c) {
            data.l1a.right.push({
                id: 'l1a_c',
                name: 'Request Timesheet'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_c',
                name: 'Request Timesheet'
            })
        }

        if (loca.l1a && loca.l1a.l1a_d) {
            data.l1a.right.push({
                id: 'l1a_d',
                name: 'Assign Task Table'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_d',
                name: 'Assign Task Table'
            })
        }

        if (loca.l2a && loca.l2a.l2a_a) {
            data.l2a.right.push({
                id: 'l2a_a',
                name: 'View Timesheet received'
            })
        } else if (loca.l2a) {
            data.l2a.left.push({
                id: 'l2a_a',
                name: 'View Timesheet received'
            })
        }

        if (loca.l2a && loca.l2a.l2a_b) {
            data.l2a.right.push({
                id: 'l2a_b',
                name: 'Timesheet approver'
            })
        } else if (loca.l2a) {
            data.l2a.left.push({
                id: 'l2a_b',
                name: 'Timesheet approver'
            })
        }

        if (loca.l2a && loca.l2a.l2a_c) {
            data.l2a.right.push({
                id: 'l2a_c',
                name: 'Show All Timesheets'
            })
        } else if (loca.l2a) {
            data.l2a.left.push({
                id: 'l2a_c',
                name: 'Show All Timesheets'
            })
        }

        if (loca.l2a && loca.l2a.l2a_d) {
            data.l2a.right.push({
                id: 'l2a_d',
                name: 'TimeSheet Report'
            })
        } else if (loca.l2a) {
            data.l2a.left.push({
                id: 'l2a_d',
                name: 'TimeSheet Report'
            })
        }

        if (loca.l2a && loca.l2a.l2a_e) {
            data.l2a.right.push({
                id: 'l2a_e',
                name: 'Bulk Approval'
            })
        } else if (loca.l2a) {
            data.l2a.left.push({
                id: 'l2a_e',
                name: 'Bulk Approval'
            })
        }

        if (loca.l3a && loca.l3a.l3a_a) {
            data.l3a.right.push({
                id: 'l3a_a',
                name: 'View Non-Billable'
            })
        } else if (loca.l3a) {
            data.l3a.left.push({
                id: 'l3a_a',
                name: 'View Non-Billable'
            })
        }

        if (loca.l3a && loca.l3a.l3a_b) {
            data.l3a.right.push({
                id: 'l3a_b',
                name: 'Create Categories'
            })
        } else if (loca.l3a) {
            data.l3a.left.push({
                id: 'l3a_b',
                name: 'Create Categories'
            })
        }

        if (loca.l3a && loca.l3a.l3a_c) {
            data.l3a.right.push({
                id: 'l3a_c',
                name: 'Edit Categories'
            })
        } else if (loca.l3a) {
            data.l3a.left.push({
                id: 'l3a_c',
                name: 'Edit Categories'
            })
        }

        if (loca.l3a && loca.l3a.l3a_d) {
            data.l3a.right.push({
                id: 'l3a_d',
                name: 'Create and Edit Task'
            })
        } else if (loca.l3a) {
            data.l3a.left.push({
                id: 'l3a_d',
                name: 'Create and Edit Task'
            })
        }

        if (loca.l3a && loca.l3a.l3a_e) {
            data.l3a.right.push({
                id: 'l3a_e',
                name: 'View All (SBU)'
            })
        } else if (loca.l3a) {
            data.l3a.left.push({
                id: 'l3a_e',
                name: 'View All (SBU)'
            })
        }

        if (loca.l4a && loca.l4a.l4a_a) {
            data.l4a.right.push({
                id: 'l4a_a',
                name: 'View'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_a',
                name: 'View'
            })
        }

        if (loca.l4a && loca.l4a.l4a_b) {
            data.l4a.right.push({
                id: 'l4a_b',
                name: 'Edit'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_b',
                name: 'Edit'
            })
        }

        if (loca.l4a && loca.l4a.l4a_c) {
            data.l4a.right.push({
                id: 'l4a_c',
                name: 'Assign User and EM to Tasks'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_c',
                name: 'Assign User and EM to Tasks'
            })
        }

        if (loca.l4a && loca.l4a.l4a_d) {
            data.l4a.right.push({
                id: 'l4a_d',
                name: 'View all projects'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_d',
                name: 'View all projects'
            })
        }

        if (loca.l4a && loca.l4a.l4a_e) {
            data.l4a.right.push({
                id: 'l4a_e',
                name: 'Edit all projects'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_e',
                name: 'Edit all projects'
            })
        }

        if (loca.l4a && loca.l4a.l4a_f) {
            data.l4a.right.push({
                id: 'l4a_f',
                name: 'Feedback Request'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_f',
                name: 'Feedback Request'
            })
        }

        if (loca.l4a && loca.l4a.l4a_g) {
            data.l4a.right.push({
                id: 'l4a_g',
                name: 'View all OHL'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_g',
                name: 'View all OHL'
            })
        }

        if (loca.l4a && loca.l4a.l4a_h) {
            data.l4a.right.push({
                id: 'l4a_h',
                name: 'View all Cabling'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_h',
                name: 'View all Cabling'
            })
        }

        if (loca.l4a && loca.l4a.l4a_i) {
            data.l4a.right.push({
                id: 'l4a_i',
                name: 'View all Substation'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_i',
                name: 'View all Substation'
            })
        }

        // if (loca.l4a && loca.l4a.l4a_j) {
        //     data.l4a.right.push({
        //         id: 'l4a_j',
        //         name: 'View all Substation Secondary'
        //     })
        // } else if (loca.l4a) {
        //     data.l4a.left.push({
        //         id: 'l4a_j',
        //         name: 'View all Substation Secondary'
        //     })
        // }

        // if (loca.l4a && loca.l4a.l4a_k) {
        //     data.l4a.right.push({
        //         id: 'l4a_k',
        //         name: 'View all Substation Civ Structure'
        //     })
        // } else if (loca.l4a) {
        //     data.l4a.left.push({
        //         id: 'l4a_k',
        //         name: 'View all Substation Civ Structure'
        //     })
        // }

        if (loca.l4a && loca.l4a.l4a_l) {
            data.l4a.right.push({
                id: 'l4a_l',
                name: 'View all Multi Utilities'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_l',
                name: 'View all Multi Utilities'
            })
        }

        if (loca.l4a && loca.l4a.l4a_m) {
            data.l4a.right.push({
                id: 'l4a_m',
                name: 'View all EDC'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_m',
                name: 'View all EDC'
            })
        }

        if (loca.l4a && loca.l4a.l4a_n) {
            data.l4a.right.push({
                id: 'l4a_n',
                name: 'Edit all OHL'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_n',
                name: 'Edit all OHL'
            })
        }

        if (loca.l4a && loca.l4a.l4a_o) {
            data.l4a.right.push({
                id: 'l4a_o',
                name: 'Edit all Cabling'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_o',
                name: 'Edit all Cabling'
            })
        }

        if (loca.l4a && loca.l4a.l4a_p) {
            data.l4a.right.push({
                id: 'l4a_p',
                name: 'Edit all Substation'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_p',
                name: 'Edit all Substation'
            })
        }

        // if (loca.l4a && loca.l4a.l4a_q) {
        //     data.l4a.right.push({
        //         id: 'l4a_q',
        //         name: 'Edit all Substation Secondary'
        //     })
        // } else if (loca.l4a) {
        //     data.l4a.left.push({
        //         id: 'l4a_q',
        //         name: 'Edit all Substation Secondary'
        //     })
        // }

        // if (loca.l4a && loca.l4a.l4a_r) {
        //     data.l4a.right.push({
        //         id: 'l4a_r',
        //         name: 'Edit all Substation Civ Structure'
        //     })
        // } else if (loca.l4a) {
        //     data.l4a.left.push({
        //         id: 'l4a_r',
        //         name: 'Edit all Substation Civ Structure'
        //     })
        // }

        if (loca.l4a && loca.l4a.l4a_s) {
            data.l4a.right.push({
                id: 'l4a_s',
                name: 'Edit all Multi Utilities'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_s',
                name: 'Edit all Multi Utilities'
            })
        }

        if (loca.l4a && loca.l4a.l4a_t) {
            data.l4a.right.push({
                id: 'l4a_t',
                name: 'Edit all EDC'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_t',
                name: 'Edit all EDC'
            })
        }

        if (loca.l4a && loca.l4a.l4a_u) {
            data.l4a.right.push({
                id: 'l4a_u',
                name: 'View their Project Report'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_u',
                name: 'View their Project Report'
            })
        }

        if (loca.l4a && loca.l4a.l4a_v) {
            data.l4a.right.push({
                id: 'l4a_v',
                name: 'View All Enabled Project Report'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_v',
                name: 'View All Enabled Project Report'
            })
        }

        if (loca.l4a && loca.l4a.l4a_ab) {
            data.l4a.right.push({
                id: 'l4a_ab',
                name: 'View All Project Report'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_ab',
                name: 'View All Project Report'
            })
        }

        if (loca.l4a && loca.l4a.l4a_w) {
            data.l4a.right.push({
                id: 'l4a_w',
                name: 'Project Report History Usage'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_w',
                name: 'Project Report History Usage'
            })
        }

        if (loca.l4a && loca.l4a.l4a_x) {
            data.l4a.right.push({
                id: 'l4a_x',
                name: 'View EVP'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_x',
                name: 'View EVP'
            })
        }

        if (loca.l4a && loca.l4a.l4a_y) {
            data.l4a.right.push({
                id: 'l4a_y',
                name: 'View all enabled EVP'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_y',
                name: 'View all enabled EVP'
            })
        }

        if (loca.l4a && loca.l4a.l4a_z) {
            data.l4a.right.push({
                id: 'l4a_z',
                name: 'View all EVP'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_z',
                name: 'View all EVP'
            })
        }

        if (loca.l4a && loca.l4a.l4a_aa) {
            data.l4a.right.push({
                id: 'l4a_aa',
                name: 'Enable Reports for Projects'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_aa',
                name: 'Enable Reports for Projects'
            })
        }



        if (loca.l4a && loca.l4a.l4a_ac) {
            data.l4a.right.push({
                id: 'l4a_ac',
                name: 'Edit Baseline Dates'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_ac',
                name: 'Edit Baseline Dates'
            })
        }

        if (loca.l4a && loca.l4a.l4a_ad) {
            data.l4a.right.push({
                id: 'l4a_ad',
                name: 'View all pending hours request'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_ad',
                name: 'View all pending hours request'
            })
        }

        if (loca.l4a && loca.l4a.l4a_ae) {
            data.l4a.right.push({
                id: 'l4a_ae',
                name: 'Download Project Report'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_ae',
                name: 'Download Project Report'
            })
        }

        if (loca.l4a && loca.l4a.l4a_af) {
            data.l4a.right.push({
                id: 'l4a_af',
                name: 'View all Technical'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_af',
                name: 'View all Technical'
            })
        }

        if (loca.l4a && loca.l4a.l4a_ag) {
            data.l4a.right.push({
                id: 'l4a_ag',
                name: 'Edit all Technical'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_ag',
                name: 'Edit all Technical'
            })
        }

        if (loca.l5a && loca.l5a.l5a_a) {
            data.l5a.right.push({
                id: 'l5a_a',
                name: 'Estimate Menu'
            })
        } else if (loca.l5a) {
            data.l5a.left.push({
                id: 'l5a_a',
                name: 'Estimate Menu'
            })
        }

        if (loca.l6a && loca.l6a.l6a_a) {
            data.l6a.right.push({
                id: 'l6a_a',
                name: 'View Tenders'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_a',
                name: 'View Tenders '
            })
        }

        if (loca.l6a && loca.l6a.l6a_b) {
            data.l6a.right.push({
                id: 'l6a_b',
                name: 'View Deliverables Schedule'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_b',
                name: 'View Deliverables Schedule'
            })
        }

        if (loca.l6a && loca.l6a.l6a_c) {
            data.l6a.right.push({
                id: 'l6a_c',
                name: 'Add Tender'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_c',
                name: 'Add Tender'
            })
        }

        if (loca.l6a && loca.l6a.l6a_d) {
            data.l6a.right.push({
                id: 'l6a_d',
                name: 'Add Deliverables Schedule'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_d',
                name: 'Add Deliverables Schedule'
            })
        }

        if (loca.l6a && loca.l6a.l6a_e) {
            data.l6a.right.push({
                id: 'l6a_e',
                name: 'Manage Tender'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_e',
                name: 'Manage Tender'
            })
        }

        if (loca.l6a && loca.l6a.l6a_f) {
            data.l6a.right.push({
                id: 'l6a_f',
                name: 'Manage Deliverable Shedule'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_f',
                name: 'Manage Deliverable Shedule'
            })
        }

        if (loca.l6a && loca.l6a.l6a_g) {
            data.l6a.right.push({
                id: 'l6a_g',
                name: 'Manage ALL Tender and Deliverables Schedule'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_g',
                name: 'Manage ALL Tender and Deliverables Schedule '
            })
        }

        if (loca.l6a && loca.l6a.l6a_h) {
            data.l6a.right.push({
                id: 'l6a_h',
                name: 'Send Tender to Approval'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_h',
                name: 'Send Tender to Approval'
            })
        }

        if (loca.l6a && loca.l6a.l6a_i) {
            data.l6a.right.push({
                id: 'l6a_i',
                name: 'View Report'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_i',
                name: 'View Report'
            })
        }

        if (loca.l6a && loca.l6a.l6a_j) {
            data.l6a.right.push({
                id: 'l6a_j',
                name: 'Export as xlsx'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_j',
                name: 'Export as xlsx'
            })
        }
        if (loca.l6a && loca.l6a.l6a_k) {
            data.l6a.right.push({
                id: 'l6a_k',
                name: 'Sandpit'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_k',
                name: 'Sandpit'
            })
        }

        if (loca.l6a && loca.l6a.l6a_l) {
            data.l6a.right.push({
                id: 'l6a_l',
                name: 'View Deliverables Schedule Summary'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_l',
                name: 'View Deliverables Schedule Summary'
            })
        }

        if (loca.l6a && loca.l6a.l6a_m) {
            data.l6a.right.push({
                id: 'l6a_m',
                name: 'Override Gate Status of Tender'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_m',
                name: 'Override Gate Status of Tender'
            })
        }

        if (loca.l6a && loca.l6a.l6a_n) {
            data.l6a.right.push({
                id: 'l6a_n',
                name: 'Estimates Effort'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_n',
                name: 'Estimates Effort'
            })
        }

        if (loca.l6a && loca.l6a.l6a_o) {
            data.l6a.right.push({
                id: 'l6a_o',
                name: 'Activate Open WBS'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_o',
                name: 'Activate Open WBS'
            })
        }

        if (loca.l6a && loca.l6a.l6a_p) {
            data.l6a.right.push({
                id: 'l6a_p',
                name: 'Tender Approval View'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_p',
                name: 'Tender Approval View'
            })
        }

        if (loca.l6a && loca.l6a.l6a_q) {
            data.l6a.right.push({
                id: 'l6a_q',
                name: 'New Project Request History'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_q',
                name: 'New Project Request History'
            })
        }

        if (loca.l6a && loca.l6a.l6a_u) {
            data.l6a.right.push({
                id: 'l6a_u',
                name: 'PIM Integration'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_u',
                name: 'PIM Integration'
            })
        }

        // if (loca.l6a && loca.l6a.l6a_p) {
        //     data.l6a.right.push({
        //         id: 'l6a_p',
        //         name: 'Manage all Cabling'
        //     })
        // } else if (loca.l6a) {
        //     data.l6a.left.push({
        //         id: 'l6a_p',
        //         name: 'Manage all Cabling'
        //     })
        // }



        // if (loca.l6a && loca.l6a.l6a_r) {
        //     data.l6a.right.push({
        //         id: 'l6a_r',
        //         name: 'Manage all Substation Secondary'
        //     })
        // } else if (loca.l6a) {
        //     data.l6a.left.push({
        //         id: 'l6a_r',
        //         name: 'Manage all Substation Secondary'
        //     })
        // }

        // if (loca.l6a && loca.l6a.l6a_s) {
        //     data.l6a.right.push({
        //         id: 'l6a_s',
        //         name: 'Manage all Substation Civ Structure'
        //     })
        // } else if (loca.l6a) {
        //     data.l6a.left.push({
        //         id: 'l6a_s',
        //         name: 'Manage all Substation Civ Structure'
        //     })
        // }

        // if (loca.l6a && loca.l6a.l6a_t) {
        //     data.l6a.right.push({
        //         id: 'l6a_t',
        //         name: 'Manage all Multi Utilities'
        //     })
        // } else if (loca.l6a) {
        //     data.l6a.left.push({
        //         id: 'l6a_t',
        //         name: 'Manage all Multi Utilities'
        //     })
        // }

        // if (loca.l6a && loca.l6a.l6a_u) {
        //     data.l6a.right.push({
        //         id: 'l6a_u',
        //         name: 'Manage all EDC'
        //     })
        // } else if (loca.l6a) {
        //     data.l6a.left.push({
        //         id: 'l6a_u',
        //         name: 'Manage all EDC'
        //     })
        // }

        if (loca.l7a && loca.l7a.l7a_a) {
            data.l7a.right.push({
                id: 'l7a_a',
                name: 'View (Needed for View)'
            })
        } else if (loca.l7a) {
            data.l7a.left.push({
                id: 'l7a_a',
                name: 'View (Needed for View)'
            })
        }

        if (loca.l7a && loca.l7a.l7a_b) {
            data.l7a.right.push({
                id: 'l7a_b',
                name: 'Create'
            })
        } else if (loca.l7a) {
            data.l7a.left.push({
                id: 'l7a_b',
                name: 'Create'
            })
        }

        if (loca.l7a && loca.l7a.l7a_c) {
            data.l7a.right.push({
                id: 'l7a_c',
                name: 'View ALL'
            })
        } else if (loca.l7a) {
            data.l7a.left.push({
                id: 'l7a_c',
                name: 'View ALL'
            })
        }

        if (loca.l7a && loca.l7a.l7a_d) {
            data.l7a.right.push({
                id: 'l7a_d',
                name: 'View OHL, Substations, Cabling'
            })
        } else if (loca.l7a) {
            data.l7a.left.push({
                id: 'l7a_d',
                name: 'View OHL, Substations, Cabling'
            })
        }

        if (loca.l7a && loca.l7a.l7a_e) {
            data.l7a.right.push({
                id: 'l7a_e',
                name: 'View EDC'
            })
        } else if (loca.l7a) {
            data.l7a.left.push({
                id: 'l7a_e',
                name: 'View EDC'
            })
        }

        if (loca.l7a && loca.l7a.l7a_f) {
            data.l7a.right.push({
                id: 'l7a_f',
                name: 'Edit (Needed for Edit)'
            })
        } else if (loca.l7a) {
            data.l7a.left.push({
                id: 'l7a_f',
                name: 'Edit (Needed for Edit)'
            })
        }

        if (loca.l7a && loca.l7a.l7a_g) {
            data.l7a.right.push({
                id: 'l7a_g',
                name: 'Sandpit'
            })
        } else if (loca.l7a) {
            data.l7a.left.push({
                id: 'l7a_g',
                name: 'Sandpit'
            })
        }


        if (loca.l8a && loca.l8a.l8a_a) {
            data.l8a.right.push({
                id: 'l8a_a',
                name: 'View'
            })
        } else if (loca.l8a) {
            data.l8a.left.push({
                id: 'l8a_a',
                name: 'View'
            })
        }

        if (loca.l8a && loca.l8a.l8a_b) {
            data.l8a.right.push({
                id: 'l8a_b',
                name: 'Edit'
            })
        } else if (loca.l8a) {
            data.l8a.left.push({
                id: 'l8a_b',
                name: 'Edit'
            })
        }

        if (loca.l9a && loca.l9a.l9a_a) {
            data.l9a.right.push({
                id: 'l9a_a',
                name: 'View'
            })
        } else if (loca.l9a) {
            data.l9a.left.push({
                id: 'l9a_a',
                name: 'View'
            })
        }


        if (loca.l9a && loca.l9a.l9a_b) {
            data.l9a.right.push({
                id: 'l9a_b',
                name: 'Create'
            })
        } else if (loca.l9a) {
            data.l9a.left.push({
                id: 'l9a_b',
                name: 'Create'
            })
        }

        if (loca.l9a && loca.l9a.l9a_c) {
            data.l9a.right.push({
                id: 'l9a_c',
                name: 'Edit'
            })
        } else if (loca.l9a) {
            data.l9a.left.push({
                id: 'l9a_c',
                name: 'Edit'
            })
        }

        if (loca.l10a && loca.l10a.l10a_a) {
            data.l10a.right.push({
                id: 'l10a_a',
                name: 'View'
            })
        } else if (loca.l10a) {
            data.l10a.left.push({
                id: 'l10a_a',
                name: 'View'
            })
        }

        if (loca.l10a && loca.l10a.l10a_b) {
            data.l10a.right.push({
                id: 'l10a_b',
                name: 'Create'
            })
        } else if (loca.l10a) {
            data.l10a.left.push({
                id: 'l10a_b',
                name: 'Create'
            })
        }

        if (loca.l10a && loca.l10a.l10a_c) {
            data.l10a.right.push({
                id: 'l10a_c',
                name: 'Edit'
            })
        } else if (loca.l10a) {
            data.l10a.left.push({
                id: 'l10a_c',
                name: 'Edit'
            })
        }


        if (loca.l11a && loca.l11a.l11a_a) {
            data.l11a.right.push({
                id: 'l11a_a',
                name: 'View'
            })
        } else if (loca.l11a) {
            data.l11a.left.push({
                id: 'l11a_a',
                name: 'View'
            })
        }

        if (loca.l11a && loca.l11a.l11a_b) {
            data.l11a.right.push({
                id: 'l11a_b',
                name: 'Create'
            })
        } else if (loca.l11a) {
            data.l11a.left.push({
                id: 'l11a_b',
                name: 'Create'
            })
        }

        if (loca.l11a && loca.l11a.l11a_c) {
            data.l11a.right.push({
                id: 'l11a_c',
                name: 'Edit'
            })
        } else if (loca.l11a) {
            data.l11a.left.push({
                id: 'l11a_c',
                name: 'Edit'
            })
        }

        if (loca.l12a && loca.l12a.l12a_a) {
            data.l12a.right.push({
                id: 'l12a_a',
                name: 'View'
            })
        } else if (loca.l12a) {
            data.l12a.left.push({
                id: 'l12a_a',
                name: 'View'
            })
        }

        if (loca.l12a && loca.l12a.l12a_b) {
            data.l12a.right.push({
                id: 'l12a_b',
                name: 'Create'
            })
        } else if (loca.l12a) {
            data.l12a.left.push({
                id: 'l12a_b',
                name: 'Create'
            })
        }

        if (loca.l12a && loca.l12a.l12a_c) {
            data.l12a.right.push({
                id: 'l12a_c',
                name: 'Edit'
            })
        } else if (loca.l12a) {
            data.l12a.left.push({
                id: 'l12a_c',
                name: 'Edit'
            })
        }

        if (loca.l13a && loca.l13a.l13a_a) {
            data.l13a.right.push({
                id: 'l13a_a',
                name: 'View'
            })
        } else if (loca.l13a) {
            data.l13a.left.push({
                id: 'l13a_a',
                name: 'View'
            })
        }

        if (loca.l13a && loca.l13a.l13a_b) {
            data.l13a.right.push({
                id: 'l13a_b',
                name: 'Create And Manage All'
            })
        } else if (loca.l13a) {
            data.l13a.left.push({
                id: 'l13a_b',
                name: 'Create And Manage All'
            })
        }

        if (loca.l13a && loca.l13a.l13a_c) {
            data.l13a.right.push({
                id: 'l13a_c',
                name: 'Manage'
            })
        } else if (loca.l13a) {
            data.l13a.left.push({
                id: 'l13a_c',
                name: 'Manage'
            })
        }
        if (loca.l14a && loca.l14a.l14a_a) {
            data.l14a.right.push({
                id: 'l14a_a',
                name: 'Revise Hours'
            })
        } else if (loca.l14a) {
            data.l14a.left.push({
                id: 'l14a_a',
                name: 'Revise Hours'
            })
        }

        if (loca.l15a && loca.l15a.l15a_a) {
            data.l15a.right.push({
                id: 'l15a_a',
                name: 'Report Link Access'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_a',
                name: 'Report Link Access'
            })
        }
        if (loca.l15a && loca.l15a.l15a_b) {
            data.l15a.right.push({
                id: 'l15a_b',
                name: 'User Timesheet Report'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_b',
                name: 'User Timesheet Report'
            })
        }
        if (loca.l15a && loca.l15a.l15a_c) {
            data.l15a.right.push({
                id: 'l15a_c',
                name: 'Demand Vs Capacity Report'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_c',
                name: 'Demand Vs Capacity Report'
            })
        }
        if (loca.l15a && loca.l15a.l15a_d) {
            data.l15a.right.push({
                id: 'l15a_d',
                name: 'Timesheets submission status'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_d',
                name: 'Timesheets submission status'
            })
        }
        if (loca.l15a && loca.l15a.l15a_e) {
            data.l15a.right.push({
                id: 'l15a_e',
                name: 'Demand Vs Capacity View'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_e',
                name: 'Demand Vs Capacity View'
            })
        }
        if (loca.l15a && loca.l15a.l15a_f) {
            data.l15a.right.push({
                id: 'l15a_f',
                name: 'Lookahead Report'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_f',
                name: 'Lookahead Report'
            })
        }

        if (loca.l15a && loca.l15a.l15a_g) {
            data.l15a.right.push({
                id: 'l15a_g',
                name: 'KPI Report'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_g',
                name: 'KPI Report'
            })
        }

        if (loca.l15a && loca.l15a.l15a_h) {
            data.l15a.right.push({
                id: 'l15a_h',
                name: 'Timesheet Download'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_h',
                name: 'Timesheet Download'
            })
        }

        if (loca.l15a && loca.l15a.l15a_i) {
            data.l15a.right.push({
                id: 'l15a_i',
                name: 'HOE Gate6'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_i',
                name: 'HOE Gate6'
            })
        }

        if (loca.l15a && loca.l15a.l15a_j) {
            data.l15a.right.push({
                id: 'l15a_j',
                name: 'Revenue in DvC'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_j',
                name: 'Revenue in DvC'
            })
        }

        if (loca.l15a && loca.l15a.l15a_k) {
            data.l15a.right.push({
                id: 'l15a_k',
                name: 'Members Workload'
            })
        } else if (loca.l15a) {
            data.l15a.left.push({
                id: 'l15a_k',
                name: 'Members Workload'
            })
        }

        if (user.is_hr) {
            if (loca.l16a && loca.l16a.l16a_a) {
                data.l16a.right.push({
                    id: 'l16a_a',
                    name: 'View Employee Data (Menu only)'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_a',
                    name: 'View Employee Data (Menu only)'
                })
            }
            if (loca.l16a && loca.l16a.l16a_b) {
                data.l16a.right.push({
                    id: 'l16a_b',
                    name: 'Edit Employee Data'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_b',
                    name: 'Edit Employee Data'
                })
            }
            if (loca.l16a && loca.l16a.l16a_c) {
                data.l16a.right.push({
                    id: 'l16a_c',
                    name: 'Manage All Users'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_c',
                    name: 'Manage All Users'
                })
            }
            if (loca.l16a && loca.l16a.l16a_d) {
                data.l16a.right.push({
                    id: 'l16a_d',
                    name: 'Manage IN Users'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_d',
                    name: 'Manage IN Users'
                })
            }
            if (loca.l16a && loca.l16a.l16a_e) {
                data.l16a.right.push({
                    id: 'l16a_e',
                    name: 'Manage UK Users'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_e',
                    name: 'Manage UK Users'
                })
            }

            if (loca.l16a && loca.l16a.l16a_j) {
                data.l16a.right.push({
                    id: 'l16a_j',
                    name: 'Manage SL Users'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_j',
                    name: 'Manage SL Users'
                })
            }

            if (loca.l16a && loca.l16a.l16a_f) {
                data.l16a.right.push({
                    id: 'l16a_f',
                    name: 'View Employee Data (IN)'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_f',
                    name: 'View Employee Data (IN)'
                })
            }
            if (loca.l16a && loca.l16a.l16a_g) {
                data.l16a.right.push({
                    id: 'l16a_g',
                    name: 'View Employee Data (UK)'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_g',
                    name: 'View Employee Data (UK)'
                })
            }
            if (loca.l16a && loca.l16a.l16a_h) {
                data.l16a.right.push({
                    id: 'l16a_h',
                    name: 'View Employee Data (ALL)'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_h',
                    name: 'View Employee Data (ALL)'
                })
            }
            if (loca.l16a && loca.l16a.l16a_i) {
                data.l16a.right.push({
                    id: 'l16a_i',
                    name: 'New User Request History'
                })
            } else if (loca.l16a) {
                data.l16a.left.push({
                    id: 'l16a_i',
                    name: 'New User Request History'
                })
            }
        }



        if (loca.l17a && loca.l17a.l17a_a) {
            data.l17a.right.push({
                id: 'l17a_a',
                name: 'View'
            })
        } else if (loca.l17a) {
            data.l17a.left.push({
                id: 'l17a_a',
                name: 'View'
            })
        }
        if (loca.l17a && loca.l17a.l17a_b) {
            data.l17a.right.push({
                id: 'l17a_b',
                name: 'Edit'
            })
        } else if (loca.l17a) {
            data.l17a.left.push({
                id: 'l17a_b',
                name: 'Edit'
            })
        }

        if (loca.l18a && loca.l18a.l18a_a) {
            data.l18a.right.push({
                id: 'l18a_a',
                name: 'Leave through LMS'
            })
        } else if (loca.l18a) {
            data.l18a.left.push({
                id: 'l18a_a',
                name: 'Leave through LMS'
            })
        }
        if (loca.l18a && loca.l18a.l18a_b) {
            data.l18a.right.push({
                id: 'l18a_b',
                name: 'LMS Login'
            })
        } else if (loca.l18a) {
            data.l18a.left.push({
                id: 'l18a_b',
                name: 'LMS Login'
            })
        }
        if (loca.l18a && loca.l18a.l18a_c) {
            data.l18a.right.push({
                id: 'l18a_c',
                name: 'LMS Management'
            })
        } else if (loca.l18a) {
            data.l18a.left.push({
                id: 'l18a_c',
                name: 'LMS Management'
            })
        }

        if (loca.l18a && loca.l18a.l18a_d) {
            data.l18a.right.push({
                id: 'l18a_d',
                name: 'Reject Leaves (Override)'
            })
        } else if (loca.l18a) {
            data.l18a.left.push({
                id: 'l18a_d',
                name: 'Reject Leaves (Override)'
            })
        }

        if (loca.l19a && loca.l19a.l19a_a) {
            data.l19a.right.push({
                id: 'l19a_a',
                name: 'View Future Budget'
            })
        } else if (loca.l19a) {
            data.l19a.left.push({
                id: 'l19a_a',
                name: 'View Future Budget'
            })
        }
        if (loca.l19a && loca.l19a.l19a_b) {
            data.l19a.right.push({
                id: 'l19a_b',
                name: 'View and Edit Hours/Month'
            })
        } else if (loca.l19a) {
            data.l19a.left.push({
                id: 'l19a_b',
                name: 'View and Edit Hours/Month'
            })
        }

        if (loca.l20a && loca.l20a.l20a_a) {
            data.l20a.right.push({
                id: 'l20a_a',
                name: 'Request Slots'
            })
        } else if (loca.l20a) {
            data.l20a.left.push({
                id: 'l20a_a',
                name: 'Request Slots'
            })
        }
        if (loca.l20a && loca.l20a.l20a_b) {
            data.l20a.right.push({
                id: 'l20a_b',
                name: 'Fair Usage'
            })
        } else if (loca.l20a) {
            data.l20a.left.push({
                id: 'l20a_b',
                name: 'Fair Usage'
            })
        }
        if (loca.l20a && loca.l20a.l20a_c) {
            data.l20a.right.push({
                id: 'l20a_c',
                name: 'PMS Management'
            })
        } else if (loca.l20a) {
            data.l20a.left.push({
                id: 'l20a_c',
                name: 'PMS Management'
            })
        }

        if (loca.l21a && loca.l21a.l21a_a) {
            data.l21a.right.push({
                id: 'l21a_a',
                name: 'View Timeline'
            })
        } else if (loca.l21a) {
            data.l21a.left.push({
                id: 'l21a_a',
                name: 'View Timeline'
            })
        }
        if (loca.l21a && loca.l21a.l21a_b) {
            data.l21a.right.push({
                id: 'l21a_b',
                name: 'Edit Timeline'
            })
        } else if (loca.l21a) {
            data.l21a.left.push({
                id: 'l21a_b',
                name: 'Edit Timeline'
            })
        }

        if (loca.l22a && loca.l22a.l22a_a) {
            data.l22a.right.push({
                id: 'l22a_a',
                name: "Change Management"
            })
        } else if (loca.l22a) {
            data.l22a.left.push({
                id: 'l22a_a',
                name: 'Change Management'
            })
        }
        if (loca.l23a && loca.l23a.l23a_a) {
            data.l23a.right.push({
                id: 'l23a_a',
                name: "D – Zero Portal"
            })
        } else if (loca.l23a) {
            data.l23a.left.push({
                id: 'l23a_a',
                name: 'D – Zero Portal'
            })
        }
        if (loca.l23a && loca.l23a.l23a_b) {
            data.l23a.right.push({
                id: 'l23a_b',
                name: "FRACAS Basic"
            })
        } else if (loca.l23a) {
            data.l23a.left.push({
                id: 'l23a_b',
                name: 'FRACAS Basic'
            })
        }
        if (loca.l23a && loca.l23a.l23a_c) {
            data.l23a.right.push({
                id: 'l23a_c',
                name: "FRACAS Committee"
            })
        } else if (loca.l23a) {
            data.l23a.left.push({
                id: 'l23a_c',
                name: 'FRACAS Committee'
            })
        }
        if (loca.l23a && loca.l23a.l23a_d) {
            data.l23a.right.push({
                id: 'l23a_d',
                name: "FRACAS Admin"
            })
        } else if (loca.l23a) {
            data.l23a.left.push({
                id: 'l23a_d',
                name: 'FRACAS Admin'
            })
        }


        if (loca.l24a && loca.l24a.l24a_a) {
            data.l24a.right.push({
                id: 'l24a_a',
                name: "View"
            })
        } else if (loca.l24a) {
            data.l24a.left.push({
                id: 'l24a_a',
                name: 'View'
            })
        }

        if (loca.l24a && loca.l24a.l24a_b) {
            data.l24a.right.push({
                id: 'l24a_b',
                name: "Create"
            })
        } else if (loca.l24a) {
            data.l24a.left.push({
                id: 'l24a_b',
                name: 'Create'
            })
        }

        if (loca.l24a && loca.l24a.l24a_c) {
            data.l24a.right.push({
                id: 'l24a_c',
                name: "Edit"
            })
        } else if (loca.l24a) {
            data.l24a.left.push({
                id: 'l24a_c',
                name: 'Edit'
            })
        }


        ///OTHERS
        if (loca.l111a && loca.l111a.l111a_a) {
            data.l111a.right.push({
                id: 'l111a_a',
                name: 'View all user allocated hours'
            })
        } else if (loca.l111a) {
            data.l111a.left.push({
                id: 'l111a_a',
                name: 'View all user allocated hours'
            })
        }
        if (loca.l111a && loca.l111a.l111a_b) {
            data.l111a.right.push({
                id: 'l111a_b',
                name: 'Support Manager'
            })
        } else if (loca.l111a) {
            data.l111a.left.push({
                id: 'l111a_b',
                name: 'Support Manager'
            })
        }
        if (loca.l111a && loca.l111a.l111a_c) {
            data.l111a.right.push({
                id: 'l111a_c',
                name: 'All Task Report'
            })
        } else if (loca.l111a) {
            data.l111a.left.push({
                id: 'l111a_c',
                name: 'All Task Report'
            })
        }
        if (loca.l111a && loca.l111a.l111a_d) {
            data.l111a.right.push({
                id: 'l111a_d',
                name: 'Covid19 Portal'
            })
        } else if (loca.l111a) {
            data.l111a.left.push({
                id: 'l111a_d',
                name: 'Covid19 Portal'
            })
        }
        if (loca.l111a && loca.l111a.l111a_e) {
            data.l111a.right.push({
                id: 'l111a_e',
                name: 'Document Controller'
            })
        } else if (loca.l111a) {
            data.l111a.left.push({
                id: 'l111a_e',
                name: 'Document Controller'
            })
        }
        // if (loca.l111a && loca.l111a.l111a_f) {
        //     data.l111a.right.push({
        //         id: 'l111a_f',
        //         name: 'new.bbparam.com'
        //     })
        // } else if (loca.l111a) {
        //     data.l111a.left.push({
        //         id: 'l111a_f',
        //         name: 'new.bbparam.com'
        //     })
        // }
        // if (loca.l111a && loca.l111a.l111a_g) {
        //     data.l111a.right.push({
        //         id: 'l111a_g',
        //         name: 'new.bbparam.com Always Allow'
        //     })
        // } else if (loca.l111a) {
        //     data.l111a.left.push({
        //         id: 'l111a_g',
        //         name: 'new.bbparam.com Always Allow'
        //     })
        // }


        console.log(data)

        this.setState({ comData: data, isLoading: false, resData: loca })

    }

    rewriteData(data, loca, response, userData) {

        if (data.l0a) {
            for (let i = 0; i < data.l0a.left.length; i++) {
                const element = data.l0a.left[i];
                loca.l0a[element.id] = false;
            }
            for (let i = 0; i < data.l0a.right.length; i++) {
                const element = data.l0a.right[i];
                loca.l0a[element.id] = true;
            }
        }

        if (data.l1a) {
            for (let i = 0; i < data.l1a.left.length; i++) {
                const element = data.l1a.left[i];
                loca.l1a[element.id] = false;
            }
            for (let i = 0; i < data.l1a.right.length; i++) {
                const element = data.l1a.right[i];
                loca.l1a[element.id] = true;
            }
        }

        if (data.l2a) {
            for (let i = 0; i < data.l2a.left.length; i++) {
                const element = data.l2a.left[i];
                loca.l2a[element.id] = false;
            }
            for (let i = 0; i < data.l2a.right.length; i++) {
                const element = data.l2a.right[i];
                loca.l2a[element.id] = true;
            }
        }

        if (data.l3a) {
            for (let i = 0; i < data.l3a.left.length; i++) {
                const element = data.l3a.left[i];
                loca.l3a[element.id] = false;
            }
            for (let i = 0; i < data.l3a.right.length; i++) {
                const element = data.l3a.right[i];
                loca.l3a[element.id] = true;
            }
        }

        if (data.l4a) {
            for (let i = 0; i < data.l4a.left.length; i++) {
                const element = data.l4a.left[i];
                loca.l4a[element.id] = false;
            }
            for (let i = 0; i < data.l4a.right.length; i++) {
                const element = data.l4a.right[i];
                loca.l4a[element.id] = true;
            }
        }

        if (data.l5a) {
            for (let i = 0; i < data.l5a.left.length; i++) {
                const element = data.l5a.left[i];
                loca.l5a[element.id] = false;
            }
            for (let i = 0; i < data.l5a.right.length; i++) {
                const element = data.l5a.right[i];
                loca.l5a[element.id] = true;
            }
        }

        if (data.l6a) {
            for (let i = 0; i < data.l6a.left.length; i++) {
                const element = data.l6a.left[i];
                loca.l6a[element.id] = false;
            }
            for (let i = 0; i < data.l6a.right.length; i++) {
                const element = data.l6a.right[i];
                loca.l6a[element.id] = true;
            }
        }

        if (data.l7a) {
            for (let i = 0; i < data.l7a.left.length; i++) {
                const element = data.l7a.left[i];
                loca.l7a[element.id] = false;
            }
            for (let i = 0; i < data.l7a.right.length; i++) {
                const element = data.l7a.right[i];
                loca.l7a[element.id] = true;
            }
        }

        if (data.l8a) {
            for (let i = 0; i < data.l8a.left.length; i++) {
                const element = data.l8a.left[i];
                loca.l8a[element.id] = false;
            }
            for (let i = 0; i < data.l8a.right.length; i++) {
                const element = data.l8a.right[i];
                loca.l8a[element.id] = true;
            }
        }

        if (data.l9a) {
            for (let i = 0; i < data.l9a.left.length; i++) {
                const element = data.l9a.left[i];
                loca.l9a[element.id] = false;
            }
            for (let i = 0; i < data.l9a.right.length; i++) {
                const element = data.l9a.right[i];
                loca.l9a[element.id] = true;
            }
        }

        if (data.l10a) {
            for (let i = 0; i < data.l10a.left.length; i++) {
                const element = data.l10a.left[i];
                loca.l10a[element.id] = false;
            }
            for (let i = 0; i < data.l10a.right.length; i++) {
                const element = data.l10a.right[i];
                loca.l10a[element.id] = true;
            }
        }

        if (data.l11a) {
            for (let i = 0; i < data.l11a.left.length; i++) {
                const element = data.l11a.left[i];
                loca.l11a[element.id] = false;
            }
            for (let i = 0; i < data.l11a.right.length; i++) {
                const element = data.l11a.right[i];
                loca.l11a[element.id] = true;
            }
        }

        if (data.l12a) {
            for (let i = 0; i < data.l12a.left.length; i++) {
                const element = data.l12a.left[i];
                loca.l12a[element.id] = false;
            }
            for (let i = 0; i < data.l12a.right.length; i++) {
                const element = data.l12a.right[i];
                loca.l12a[element.id] = true;
            }
        }

        if (data.l13a) {
            for (let i = 0; i < data.l13a.left.length; i++) {
                const element = data.l13a.left[i];
                loca.l13a[element.id] = false;
            }
            for (let i = 0; i < data.l13a.right.length; i++) {
                const element = data.l13a.right[i];
                loca.l13a[element.id] = true;
            }
        }
        if (data.l14a) {
            for (let i = 0; i < data.l14a.left.length; i++) {
                const element = data.l14a.left[i];
                loca.l14a[element.id] = false;
            }
            for (let i = 0; i < data.l14a.right.length; i++) {
                const element = data.l14a.right[i];
                loca.l14a[element.id] = true;
            }
        }
        if (data.l15a) {
            for (let i = 0; i < data.l15a.left.length; i++) {
                const element = data.l15a.left[i];
                loca.l15a[element.id] = false;
            }
            for (let i = 0; i < data.l15a.right.length; i++) {
                const element = data.l15a.right[i];
                loca.l15a[element.id] = true;
            }
        }
        if (data.l16a) {
            for (let i = 0; i < data.l16a.left.length; i++) {
                const element = data.l16a.left[i];
                loca.l16a[element.id] = false;
            }
            for (let i = 0; i < data.l16a.right.length; i++) {
                const element = data.l16a.right[i];
                loca.l16a[element.id] = true;
            }
        }
        if (data.l17a) {
            for (let i = 0; i < data.l17a.left.length; i++) {
                const element = data.l17a.left[i];
                loca.l17a[element.id] = false;
            }
            for (let i = 0; i < data.l17a.right.length; i++) {
                const element = data.l17a.right[i];
                loca.l17a[element.id] = true;
            }
        }
        if (data.l18a) {
            for (let i = 0; i < data.l18a.left.length; i++) {
                const element = data.l18a.left[i];
                loca.l18a[element.id] = false;
            }
            for (let i = 0; i < data.l18a.right.length; i++) {
                const element = data.l18a.right[i];
                loca.l18a[element.id] = true;
            }
        }
        if (data.l19a) {
            for (let i = 0; i < data.l19a.left.length; i++) {
                const element = data.l19a.left[i];
                loca.l19a[element.id] = false;
            }
            for (let i = 0; i < data.l19a.right.length; i++) {
                const element = data.l19a.right[i];
                loca.l19a[element.id] = true;
            }
        }
        if (data.l20a) {
            for (let i = 0; i < data.l20a.left.length; i++) {
                const element = data.l20a.left[i];
                loca.l20a[element.id] = false;
            }
            for (let i = 0; i < data.l20a.right.length; i++) {
                const element = data.l20a.right[i];
                loca.l20a[element.id] = true;
            }
        }
        if (data.l21a) {
            for (let i = 0; i < data.l21a.left.length; i++) {
                const element = data.l21a.left[i];
                loca.l21a[element.id] = false;
            }
            for (let i = 0; i < data.l21a.right.length; i++) {
                const element = data.l21a.right[i];
                loca.l21a[element.id] = true;
            }
        }
        if (data.l22a) {
            for (let i = 0; i < data.l22a.left.length; i++) {
                const element = data.l22a.left[i];
                loca.l22a[element.id] = false;
            }
            for (let i = 0; i < data.l22a.right.length; i++) {
                const element = data.l22a.right[i];
                loca.l22a[element.id] = true;
            }
        }
        if (data.l23a) {
            for (let i = 0; i < data.l23a.left.length; i++) {
                const element = data.l23a.left[i];
                loca.l23a[element.id] = false;
            }
            for (let i = 0; i < data.l23a.right.length; i++) {
                const element = data.l23a.right[i];
                loca.l23a[element.id] = true;
            }
        }


        if (data.l24a) {
            for (let i = 0; i < data.l24a.left.length; i++) {
                const element = data.l24a.left[i];
                loca.l24a[element.id] = false;
            }
            for (let i = 0; i < data.l24a.right.length; i++) {
                const element = data.l24a.right[i];
                loca.l24a[element.id] = true;
            }
        }





        if (data.l111a) {
            for (let i = 0; i < data.l111a.left.length; i++) {
                const element = data.l111a.left[i];
                loca.l111a[element.id] = false;
            }
            for (let i = 0; i < data.l111a.right.length; i++) {
                const element = data.l111a.right[i];
                loca.l111a[element.id] = true;
            }
        }

        var dataTo = {
            uid: userData.id,
            access: loca,
            last_updated: new Date(),
        }

        if (response.length === 0) {
            this.setState({ isLoading: true })
            Service.post(`/bb/api/access/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: dataTo,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Change saved!', {
                        variant: 'success'
                    });
                    this.fetchOps();
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    errorMessage(this.props, error)
                });
        } else {
            Service.put(`/bb/api/access/` + response[0].id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: dataTo,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Change saved!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }

    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {/* <Box bgcolor="grey.300">
                                <Button onClick={() => { this.rewriteData() }} >save</Button>
                            </Box> */}
                        <Box p={3} bgcolor="grey.300">
                            <Accordions value={this.state.comData} resData={this.state.resData} user={this.props.value} data={this.state.data} handleToUpdate={this.rewriteData} />
                        </Box>
                    </div>
                )}
            </div>
        )
    }
}


export default withSnackbar(UserPermission);