import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getPrimaryColor } from '../Helper/CommonThings'
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../Helper/CookieHelper';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, } from '@material-ui/core';
import Service from '../Networking/networkutils'
import { errorMessage } from '../Helper/ErrorMessage';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment/moment';
import { BootstrapDialogTitleV4 } from '../Helper/DialogTitleM5';
import { Alert, Autocomplete } from '@material-ui/lab';

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class DateChangeReqManage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: [],
            selectedRequest: [],
            openRejectConfirm: false,
            openRequest: false,
            updateDisabled: false,
            rejDisabled: false,
            editedDate: null,
            selectedDate: null,
            reasonDelayDialog: false,
            dateChangeNouns: [],
            dateChangeAdjectives: [],
            selectedNoun: null,
            selectedAdjective: null,
            adjectiveLookup: null,
            nounLookup: null
        }
    }

    componentDidMount() {
        this.fetchHistory()
        this.fetchNounsAndAdjectives()
    }

    fetchNounsAndAdjectives() {
        Promise.all([
            this.getNouns(),
            this.getAdjectives()
        ])
            .then(res => {

                var adjectiveLookup = {}
                var nounLookup = {}
                for (let i = 0; i < res[0].length; i++) {
                    const element = res[0][i];
                    nounLookup[element.id] = element.name
                }
                for (let i = 0; i < res[1].length; i++) {
                    const element = res[1][i];
                    adjectiveLookup[element.id] = element.name
                }
                this.setState({ dateChangeNouns: res[0], dateChangeAdjectives: res[1], adjectiveLookup, nounLookup })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    getNouns() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/nouns/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getAdjectives() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/adjective/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    fetchHistory() {
        var url = ''
        if (this.props.taskLevel) {
            url = `/bb/api/estimate/date/request/get_tasks_history/?task_id=${this.props.taskLevel.id}`
        } else if (this.props.dsLevel) {
            url = `/bb/api/estimate/date/request/get_tasks_history/?ds_id=${this.props.dsLevel.id}&user_id=${user.id}`
        } else if (this.props.value.tenderbid_data.pdm === user.id || this.props.value.tenderbid_data.region_hoe.id === user.id) {
            url = `/bb/api/estimate/date/request/get_tasks_history/?tender_id=${this.props.value.id}`
        } else {
            url = `/bb/api/estimate/date/request/get_tasks_history/?tender_id=${this.props.value.id}&user_id=${user.id}`
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    approveData() {
        if (!this.state.editedDate) {
            this.props.enqueueSnackbar('Please select the date!', {
                variant: 'warning'
            });
        } else {
            if (moment(this.state.selectedRequest[0].end_date) < moment(this.state.editedDate)) {
                this.setState({ reasonDelayDialog: true })
            } else {
                let data = {
                    id: this.state.selectedRequest[0].task,
                    requested_date: this.state.editedDate,
                    approval_status: 1
                }
                this.updateDate(data)
            }
        }
    }

    updateDate(data) {
        this.setState({ updateDisabled: true })
        Service.patch('/bb/api/estimate/date/request/approve_and_reject/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.setState({ selectedRequest: [], openRequest: false, updateDisabled: false, editedDate: null, selectedAdjective: null, selectedNoun: null, reasonDelayDialog: false })
                this.fetchHistory()
                this.props.enqueueSnackbar('Changed Successfully!', {
                    variant: 'success'
                });
                if (!this.props.taskLevel) {
                    this.updatedCount()
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({ updateDisabled: false })
                errorMessage(this.props, error)
            });
    }

    updatedCount() {
        var url = ''
        if (this.props.dsLevel) {
            url = `/bb/api/estimate/date/request/get_pending_count/?&ds_id=${this.props.dsLevel.id}`
        } else {
            url = `/bb/api/estimate/date/request/get_pending_count/?&tender_id=${this.props.value.id}`
        }

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (this.props.refreshData) {
                    this.props.refreshData(res.data)
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    setReasonDelay() {
        if (!this.state.selectedNoun || !this.state.selectedAdjective) {
            this.props.enqueueSnackbar('Please select the fields!', {
                variant: 'warning'
            });
        } else {
            let data = {
                id: this.state.selectedRequest[0].task,
                requested_date: this.state.editedDate,
                approval_status: 1,
                noun: this.state.selectedNoun.id,
                adjective: this.state.selectedAdjective.id
            }
            this.updateDate(data)
        }
    }

    rejectData() {
        this.setState({ rejDisabled: true })
        Service.patch('/bb/api/estimate/date/request/approve_and_reject/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: this.state.selectedRequest[0].task,
                approval_status: 1
            }
        })
            .then(res => {
                this.setState({ selectedRequest: [], openRejectConfirm: false, rejDisabled: false, openRequest: false, editedDate: null })
                this.fetchHistory()
                this.props.enqueueSnackbar('Rejected Successfully!', {
                    variant: 'success'
                });
                this.updatedCount()
            })
            .catch(error => {
                console.log(error)
                this.setState({ rejDisabled: false })
                errorMessage(this.props, error)
            });
    }

    fetchRequests(data) {
        Service.get(`/bb/api/estimate/date/request/pending_tasks_request/?task_id=${data.task_id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    selectedRequest: res.data,
                    openRequest: true,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    onNounChange = (event, values) => {
        this.setState({
            selectedNoun: values
        })
    }

    onAdjectiveChange = (event, values) => {
        this.setState({
            selectedAdjective: values
        })
    }

    render() {

        const handleRequestClose = () => {
            this.setState({ openRequest: false, editedDate: null })
        };

        const handleRejectConfirmClose = () => {
            this.setState({ openRejectConfirm: false, })
        };

        const reasondelayDialogClose = () => {
            this.setState({ reasonDelayDialog: false, selectedAdjective: null, selectedNoun: null, editedDate: null })
        };

        return (
            <div>
                {(this.state.isLoading) ? (<LoopCircleLoading color={getPrimaryColor()} />) : (
                    <div style={{ display: 'flex', width: '100%' }}>
                        <MaterialTable
                            style={{ width: '100%' }}
                            title={this.props.taskLevel ? (this.props.taskLevel.name + ' - Date Change Request' ): this.props.dsLevel ? (this.props.dsLevel.name + ' - Date Change Request' ): this.props.value.tender_name + " - Date Change Request"}
                            columns={[
                                {
                                    title: 'DS', field: 'ds_name', editable: 'never',
                                    hidden: this.props.dsLevel
                                },
                                {
                                    title: 'WBS', field: 'wbs_name', editable: 'never',
                                },
                                {
                                    title: 'Task', field: 'task_name', editable: 'never',
                                },
                                {
                                    title: 'Status', field: 'status', editable: 'never',
                                    lookup: { 0: 'Not Reviewed', 1: 'Reviewed', },
                                },
                                {
                                    title: 'No Of Requests', field: 'no_of_requests', editable: 'never',
                                },
                            ]}
                            data={this.state.data}
                            isLoading={this.state.isLoading}
                            options={{
                                padding: "dense",
                                doubleHorizontalScroll: true,
                                paging: false,
                                toolbarButtonAlignment: 'left',
                                searchFieldAlignment: 'left',
                            }}
                            onRowClick={(event, rowData, togglePanel) => { this.fetchRequests(rowData) }}
                        />
                    </div>
                )}

                <Dialog
                    open={this.state.openRejectConfirm}
                    onClose={handleRejectConfirmClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure? Do you want to ignore all requests?"}</DialogTitle>

                    <DialogActions>
                        <Button size='small' onClick={handleRejectConfirmClose} color="primary">
                            Cancel
                        </Button>
                        <Button size='small' disabled={this.state.rejDisabled} onClick={() => this.rejectData()} color="primary" autoFocus>
                            Ignore
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth={"lg"} onClose={handleRequestClose} fullWidth={true} aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                    <BootstrapDialogTitleV4 onClose={handleRequestClose} id="alert-dialog-title">{this.state.selectedRequest.length > 0 ? this.state.selectedRequest[0].project_name + " (" + this.state.selectedRequest[0].ds_name + " --> " + this.state.selectedRequest[0].task_name + ") " : null}</BootstrapDialogTitleV4>
                    <DialogContent>
                        <Box display="flex" flexDirection='row' justifyContent='space-between' width='100%' alignItems='center'>
                            <Box >
                                <b>Current Finish Date: </b>{moment(this.state.selectedRequest[0]?.end_date).format('DD-MM-YYYY')}
                            </Box>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog1"
                                    label="Recommended Date"
                                    format="dd/MM/yyyy"
                                    style={{ width: '300px' }}
                                    minDate={this.state.selectedRequest[0]?.start_date}
                                    value={this.state.editedDate}
                                    onChange={(date) => {
                                        if (!date) {
                                            this.setState({ editedDate: null })
                                            return null
                                        }
                                        if (moment(date).format('YYYY-MM-DD') === moment(this.state.selectedRequest[0]?.end_date).format('YYYY-MM-DD')) {
                                            this.props.enqueueSnackbar('Approving existing finish date is not allowed!', {
                                                variant: 'warning'
                                            });
                                            return null
                                        }
                                        this.setState({ editedDate: moment(date).format('YYYY-MM-DD') })
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    helperText={"Difference: " + ((!this.state.editedDate) ? 0 : moment(this.state.editedDate).diff(moment(this.state.selectedRequest[0]?.end_date), 'days'))}
                                />
                            </MuiPickersUtilsProvider>
                            <Box marginTop='2px' >
                                <Button size='small' style={{ marginRight: '5px' }} disabled={this.state.updateDisabled} onClick={() => this.approveData()} color="primary">
                                    Update
                                </Button>
                                <Button size='small' disabled={this.state.rejDisabled} onClick={() => this.setState({ openRejectConfirm: true })} color="primary">
                                    Ignore
                                </Button>

                                {/* <b>Difference: </b>{!this.state.editedDate ? 0 : moment(this.state.editedDate).diff(moment(this.state.selectedRequest[0]?.end_date), 'days')} */}
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection='row' justifyContent='center' width='100%'>
                            <MaterialTable
                                style={{ width: '100%' }}
                                title={<Alert severity='info' style={{ marginTop: '5px' }} >
                                    <b>Note: </b>You have to click any one of the rows in below table to select the date to update or else pick custom one from <b>Recommended Date</b>
                                </Alert>}
                                columns={[
                                    {
                                        title: 'Requested By', field: 'requested_by',
                                    },
                                    {
                                        title: 'Requested To', field: 'requested_to',
                                    }, {
                                        title: 'Requested End Date', field: 'requested_date', type: 'date', editable: 'never',
                                        render: rowData => !rowData.requested_date ? "NA" : moment(rowData.requested_date).format('DD-MM-YYYY')
                                    },
                                    {
                                        title: 'Status', field: 'status', editable: 'never',
                                        lookup: { 0: 'Not Reviewed', 1: 'Reviewed' },
                                    },
                                    {
                                        title: 'Noun', field: 'noun', editable: 'never',
                                        lookup: this.state.nounLookup,
                                    },
                                    {
                                        title: 'Adjective', field: 'adjective', editable: 'never',
                                        lookup: this.state.adjectiveLookup,
                                    },
                                    {
                                        title: 'Comment', field: 'comment', editable: 'never',
                                    },
                                    // {
                                    //     title: 'Reviewed By', field: 'replied_by', editable: 'never',
                                    // },
                                    {
                                        title: 'Date Created', field: 'date_created', type: 'date', defaultSort: 'desc', editable: 'never',
                                        render: rowData => moment(rowData.date_created).format('DD-MM-YYYY')
                                    },
                                ]}
                                data={this.state.selectedRequest}
                                isLoading={this.state.isLoading}
                                options={{
                                    padding: "dense",
                                    doubleHorizontalScroll: true,
                                    paging: false,
                                    toolbarButtonAlignment: 'left',
                                    searchFieldAlignment: 'right',
                                }}
                                onRowClick={(event, rowData, togglePanel) => {
                                    this.setState({
                                        editedDate: !rowData.requested_date ? null : moment(rowData.requested_date).format('YYYY-MM-DD'),
                                        selectedAdjective: { id: rowData.adjective, name: this.state.adjectiveLookup[rowData.adjective] },
                                        selectedNoun: { id: rowData.noun, name: this.state.nounLookup[rowData.noun] },
                                    })
                                }}
                            />
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullWidth
                    open={this.state.reasonDelayDialog}
                    onClose={reasondelayDialogClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>Select the reason for delay</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            id={"nouns"}
                            fullWidth
                            style={{ marginTop: '15px' }}
                            value={this.state.selectedNoun}
                            options={this.state.dateChangeNouns}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, v) => this.onNounChange(e, v)}
                            renderInput={(params) => <TextField {...params} label="Select Noun" variant="outlined" />}
                        />
                        <Autocomplete
                            id={"adjectives"}
                            fullWidth
                            style={{ marginTop: '15px' }}
                            value={this.state.selectedAdjective}
                            options={this.state.dateChangeAdjectives}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, v) => this.onAdjectiveChange(e, v)}
                            renderInput={(params) => <TextField {...params} label="Select Adjective" variant="outlined" />}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button size='small' color="primary" onClick={reasondelayDialogClose}>
                            cancel
                        </Button>
                        <Button size='small' disabled={this.state.updateDisabled} color="primary" onClick={() => this.setReasonDelay()}>
                            submit
                        </Button>
                    </DialogActions>
                </Dialog>

            </div >
        )
    }
}
export default withSnackbar(DateChangeReqManage)
