import { Box, Chip, IconButton } from '@mui/material';
import React, { Component } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { withSnackbar } from 'notistack';
import { errorMessage } from '../../Helper/ErrorMessage';
import Service from '../../Networking/networkutils';
import { getCookie } from '../../Helper/CookieHelper';

const token = getCookie("bb_ts_token");
class P6LinkChip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
        };
    }

    deleteData(item, index) {
        if (!item.id) {
            this.props.enqueueSnackbar("P6 ID not found", { variant: 'warning' });
            return;
        }
        let data = this.state.data;
        data.p6_activity_link_data.splice(index, 1);
        this.setState({ data: data });
        Service.delete(`/bb/api/p6/activity/update/delete_activity_link/?item_id=${item.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar("P6 ID deleted successfully", { variant: 'success' });
            })
            .catch(error => {
                console.log(error);
                errorMessage(this.props, error)
                if (this.props.reloadData) {
                    this.props.reloadData();
                }
            });
    }

    render() {
        return (
            <div>
                <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                    <Box>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                                if (this.props.getIndex) {
                                    this.props.getIndex(this.state.data)
                                }
                            }}>
                            <AddIcon fontSize='small' />
                        </IconButton>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} width={'100%'} flexWrap={'wrap'}>
                        {
                            this.state.data.p6_activity_link_data.map((item, index) => {
                                return (
                                    <Chip
                                        key={index}
                                        label={item.p6_id}
                                        onDelete={() => {
                                            this.deleteData(item, index);
                                        }}
                                        color="primary"
                                        variant="outlined"
                                        sx={{ m: 0.5 }}
                                        size='small'
                                    />
                                )
                            })
                        }
                    </Box>
                </Box>

            </div >
        );
    }
}

export default withSnackbar(P6LinkChip);