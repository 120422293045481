import React, { Component } from 'react';
import UsersTimesheets from './UsersTimesheets';
import TeamsTimesheets from './TeamsTimesheets';
import TimesheetSummary from './TimesheetSummary';
import TeamTimesheetReport from './TeamTimesheetReport';
import { Box, styled } from '@mui/system';
import { Tabs as TabsUnstyled } from '@mui/base/Tabs';
import { TabsList as TabsListUnstyled } from '@mui/base/TabsList';
import { TabPanel as TabPanelUnstyled } from '@mui/base/TabPanel';
import { Tab as TabUnstyled, tabClasses } from '@mui/base/Tab';
import { getPrimaryColor, getSecondaryColor } from '../../Helper/CommonThings';
import { screenSizeCheck } from '../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { withSnackbar } from 'notistack';
import Service from '../../Networking/networkutils';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LoopCircleLoading } from 'react-loadingg';

const Tab = styled(TabUnstyled)`
  font-family: roboto;
  color: ${getPrimaryColor()};
  cursor: pointer;
  font-size: ${screenSizeCheck() ? '1rem' : '1.2rem'};
  font-weight: bold;
  background-color: transparent;
  padding: 12px 50px;
  margin: 2px 2px 0px 0px ;
  border: none;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  outline:2px solid #a3c2c2;
  box-shadow:1px 1px 1px 1px #a3c2c2;
  &:hover {
    background-color: ${getSecondaryColor()};
  }

  &:focus {
    color: white;
    border-radius: 20px 20px 0px 0px
    // outline: 2px solid ${getPrimaryColor()};
    // outline-offset: 2px;
  }

  &.${tabClasses.selected} {
    background-color: ${getPrimaryColor()};
    color: white;
  }

`;

const TabPanel = styled(TabPanelUnstyled)`
  width: '100%';
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: left;
  justify-content: left;
  align-content: space-between;
`;

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`,
//   };
// }

const token = getCookie("bb_ts_token");

class TimesheetReportTab extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      value: 0,
      companies: [],
      selectedCompany: !props.companyFilter ? 0 : props.companyFilter,
      isLoading: true,
      selectedLocation: props.locFilter > -1 ? props.locFilter : -1
    };
    this.filterCompanyWeekly = React.createRef();
    this.filterCompanyUser = React.createRef();
    this.filterCompanyTeam = React.createRef();
    this.filterCompanyTimesheets = React.createRef();
  }

  componentDidMount() {
    if (this.props.gid) {
      this.setState({ value: 1 });
    }
    this.fetchCompanies();
  }

  fetchCompanies() {
    this.setState({ isLoading: true });
    Service.get(`/bb/api/company/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "All" });
        this.setState({ companies: data, isLoading: false });
      })
      .catch(error => {
        console.log(error);
        errorMessage(this.props, error)
      });
  }
  render() {

    const handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };

    const handleCompanyChange = (event) => {
      this.setState({
        selectedCompany: event.target.value,
      }, () => {
        if (this.state.value === 0 && this.filterCompanyWeekly.current) {
          this.filterCompanyWeekly.current.filterCompany(this.state.selectedCompany);
        } else if (this.state.value === 1 && this.filterCompanyUser.current) {
          this.filterCompanyUser.current.filterCompany(this.state.selectedCompany);
        } else if (this.state.value === 2 && !this.props.gid && this.filterCompanyTeam.current) {
          this.filterCompanyTeam.current.filterCompany(this.state.selectedCompany);
        } else if (this.state.value === 2 && this.props.gid && this.filterCompanyTimesheets.current) {
          this.filterCompanyTimesheets.current.filterCompany(this.state.selectedCompany);
        }
      })
    };

    const handleLocationChange = (event) => {
      this.setState({
        selectedLocation: event.target.value,
      }, () => {
        if (this.state.value === 0 && this.filterCompanyWeekly.current) {
          this.filterCompanyWeekly.current.filterLocation(this.state.selectedLocation);
        } else if (this.state.value === 1 && this.filterCompanyUser.current) {
          this.filterCompanyUser.current.filterLocation(this.state.selectedLocation);
        } else if (this.state.value === 2 && !this.props.gid && this.filterCompanyTeam.current) {
          this.filterCompanyTeam.current.filterLocation(this.state.selectedLocation);
        } else if (this.state.value === 2 && this.props.gid && this.filterCompanyTimesheets.current) {
          this.filterCompanyTimesheets.current.filterLocation(this.state.selectedLocation);
        }
      })
    }

    return (
      <div >
        {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} size="large" /> :
          <TabsUnstyled value={this.state.value} onChange={handleChange} >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <TabsList >
                <Tab value={0}>
                  Weekly Timesheet Summary Report
                </Tab>
                <Tab value={1}>
                  User Timesheet Report
                </Tab>
                {!this.props.gid ?
                  <Tab value={2}>
                    Team Timesheet Report
                  </Tab> : <Tab value={2}>
                    Timesheets
                  </Tab>}
              </TabsList>
              {
                (this.props.companyFilter >= 0) ? null :
                  <Box sx={{ minWidth: 120, marginLeft: '15px' }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">SBU</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectedCompany}
                        label="SBU"
                        onChange={handleCompanyChange}
                      >
                        {this.state.companies.map((company) => (
                          <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>}

              {
                (this.props.locFilter && this.props.locFilter >= -2) ? null :
                  <Box sx={{ minWidth: 120, marginLeft: '15px' }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">Location</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectedLocation}
                        label="Location"
                        onChange={handleLocationChange}
                      >
                        <MenuItem value={-1}>{'All'}</MenuItem>
                        <MenuItem value={0}>{'UK'}</MenuItem>
                        <MenuItem value={1}>{'India'}</MenuItem>
                        <MenuItem value={2}>{'Srilanka'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>}
            </Box>
            <div>
              <TabPanel value={0} > <TimesheetSummary ref={this.filterCompanyWeekly} company={this.state.selectedCompany} uid={this.props.uid} gid={this.props.gid} dateValue={this.props.dateValue} location={this.state.selectedLocation} /></TabPanel>
              <TabPanel value={1} ><UsersTimesheets ref={this.filterCompanyUser} company={this.state.selectedCompany} uid={this.props.uid} gid={this.props.gid} dateValue={this.props.dateValue} location={this.state.selectedLocation} /></TabPanel>
              <TabPanel value={2}>  {!this.props.gid ? <TeamsTimesheets ref={this.filterCompanyTeam} company={this.state.selectedCompany} uid={this.props.uid} gid={this.props.gid} dateValue={this.props.dateValue} location={this.state.selectedLocation} /> : <TeamTimesheetReport ref={this.filterCompanyTimesheets} company={this.state.selectedCompany} gid={this.props.gid} dateValue={this.props.dateValue} location={this.state.selectedLocation} />}</TabPanel>
            </div>
          </TabsUnstyled>}
      </div>
    );
  }
}

export default withSnackbar(TimesheetReportTab);


