import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Service from '../../Networking/networkutils';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { LoopCircleLoading } from 'react-loadingg';
import { getDarkMode, getPrimaryColor } from '../../Helper/CommonThings';
import moment from 'moment';
import P6LinkChip from './P6LinkChip';
import P6DetailPanel from './P6DetailPanel';
import { InputAdornment, Table, TableRow, Tooltip, } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Slide, TextField } from '@mui/material';
import { AppBar, CircularProgress, Dialog, DialogContent, Toolbar } from '@material-ui/core';
import { CoffeeLoading } from 'react-loadingg';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PIMGanttChart from '../PIMGanttChart/PIMGanttChart';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { getMode } from '../../MainDashBoard/ColorTheme/ColorTheme';

const token = getCookie("bb_ts_token");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var tasks = [];

class PIMLink extends Component {
    constructor(props) {
        super(props);
        window.PIMLink = this;
        this.state = {
            data: [],
            isLoading: true,
            columns: [],
            filters: {
                from_date: '',
                to_date: '',
                ds_name: '',
                wbs_name: '',
                id: '',
                name: '',
                p6: ""
            },
            filteredData: [],
            chipLoading: false,
            rowID: [],
            openGantt: false,
            coffeeLoading: false,
            prevDisabled: false,
            nextDisabled: false,
            dataIndex: null,
            selectedData: null,
            zoom: localStorage.getItem('zoom_level') ? localStorage.getItem('zoom_level') : 1
        };
        this.reloadData = this.reloadData.bind(this);
        this.addData = this.addData.bind(this);
        this.reload = this.reload.bind(this);
        this.getIndex = this.getIndex.bind(this);
        this.ganttRef = React.createRef();
    }

    componentDidMount() {
        this.getData('init', '', null)
        if (this.props.getExpand) {
            this.props.getExpand(false)
        }
        localStorage.setItem('zoom_level', 1)
    }

    getData(type, dateType, data) {
        Service.get(`/bb/api/p6/activity/update/pim_estimate_tasks/?t_pid=${this.props.projectData?.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                res.data.forEach(element => {
                    if (this.state.rowID.includes(element.id)) {
                        element.isOpen = true
                    } else {
                        element.isOpen = false
                    }
                    element.p6 = element.p6_activity_link_data.map(item => item.p6_id.toLowerCase()).join(" ");
                });
                this.setState({
                    data: res.data,
                    filteredData: res.data,
                    // filters: {
                    //     from_date: '',
                    //     to_date: '',
                    //     ds_name: '',
                    //     wbs_name: '',
                    //     id: '',
                    //     name: '',
                    //     p6: ""
                    // },
                }, () => {
                    this.getColumns(type, dateType, data)
                });
            })
            .catch(error => {
                console.log(error);
                errorMessage(this.props, error)
            });
    }

    getColumns(type, dateType, data) {
        let columns = [
            // {
            //     title: 'Project', field: 'project_name'
            // },
            {
                title: "PaRaM DS", field: 'ds_name', filtering: true
            },
            {
                title: 'PaRaM WBS', field: 'wbs_name', filtering: true
            },
            {
                title: 'PaRaM Task ID', field: 'id', filtering: true
            },
            {
                title: "PaRaM Task", field: 'name', filtering: true,
                render: rowData => !rowData.decs ? rowData.name : rowData.decs
            },
            {
                title: "Linked P6", field: 'p6', filtering: false,
                render: (rowData) => this.state.chipLoading ? <CircularProgress /> : <P6LinkChip data={rowData} reloadData={this.reloadData} getIndex={this.getIndex} />
            },
            {
                title: "", field: 'p6', filtering: false, width: 20,
                render: (rowData) =>
                    <Tooltip title="Update as Configured" placement="top">
                        <IconButton size='small' onClick={() => this.updateAsConfigured(rowData)}>
                            <TrackChangesIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
            },
            {
                title: 'PaRaM Baseline Start', field: 'from_date', filtering: true, color: (dateType === 'start') ? (getMode() ? '#dac481' : '#AFD6CB') : '', row: data,
                render: (rowData) => rowData.from_date ? <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                    <Box>
                        {!rowData.from_date ? "" : moment(rowData.from_date).format("DD/MM/YYYY")}
                    </Box>
                    <Box fontSize={'small'}>
                        <b>P6:</b> {!rowData.p6_from_date ? "" : moment(rowData.p6_from_date).format("DD/MM/YYYY")}
                    </Box>
                </Box> : ''
            },
            {
                title: 'PaRaM Baseline Finish', field: 'to_date', filtering: true, color: (dateType === 'finish') ? (getMode() ? '#dac481' : '#AFD6CB') : '', row: data,
                render: (rowData) => rowData.to_date ? <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>
                        {(!rowData.to_date ? "" : moment(rowData.to_date).format("DD/MM/YYYY"))}
                    </Box>
                    <Box fontSize={'small'}>
                        <b>P6:</b> {(!rowData.p6_to_date ? "" : moment(rowData.p6_to_date).format("DD/MM/YYYY"))}
                    </Box>
                </Box> : ''
            },
            // {
            //     title: '% Complete', field: 'comp_percnt',
            //     render: (rowData) => rowData.comp_percnt >= 0 ? (rowData.comp_percnt + "%") : ""
            // },
            // {
            //     title: 'Actual Start', field: 'act_exp_start',
            //     render: (rowData) => (!rowData.act_exp_start ? "" : moment(rowData.act_exp_start).format("DD/MM/YYYY"))
            // },
            // {
            //     title: 'Actual Finish', field: 'act_exp_finish',
            //     render: (rowData) => (!rowData.act_exp_finish ? "" : moment(rowData.act_exp_finish).format("DD/MM/YYYY"))
            // },
        ]
        this.setState({ isLoading: false, coffeeLoading: false, columns }, () => {
            if (type === 'rerender') {
                this.fetchData('rerender')
            }
            this.getFilteredData()
        });
    }

    handleRowClick = (index) => {
        let filteredData = this.state.filteredData;
        filteredData[index].isOpen = !filteredData[index].isOpen;
        this.setState({ filteredData: filteredData }, () => {
            if (filteredData[index].isOpen) {
                let rowID = this.state.rowID
                if (!rowID.includes(filteredData[index].id)) {
                    rowID.push(filteredData[index].id)
                    this.setState({
                        rowID: rowID
                    })
                }
            } else {
                let rowID = this.state.rowID
                rowID = rowID.filter((item) => item !== filteredData[index].id)
                this.setState({
                    rowID: rowID
                })
            }
        });
    }

    reloadData() {
        this.setState({ isLoading: true });
        this.getData('init', '', null)
    }

    reload = (dateType, data) => {
        this.getData('init', dateType, data)
    }

    getFilters(column) {
        if (['from_date', 'to_date'].includes(column.field)) {
            return <TextField
                size='small'
                id={column.field}
                type='date'
                variant="standard"
                value={this.state.filters[column.field]}
                onChange={this.handleFilterChange}
            />
        } else if (!column.filtering) {
            return null
        } else {
            return <TextField
                size='small'
                id={column.field}
                onChange={this.handleFilterChange}
                value={this.state.filters[column.field]}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <FilterListIcon fontSize='small' />
                        </InputAdornment>
                    ),
                }}
                variant="standard"
            />
        }
    }

    handleFilterChange = (event) => {
        this.setState({ filters: { ...this.state.filters, [event.target.id]: event.target.value } }, () => {
            this.getFilteredData();
        });
    }

    getFilteredData() {
        let data = JSON.parse(JSON.stringify(this.state.data));
        let filters = this.state.filters;
        let filteredData = data.filter(item => {
            return (
                (filters.ds_name ? item.ds_name.toLowerCase().includes(filters.ds_name.toLowerCase()) : true) &&
                (filters.wbs_name ? item.wbs_name.toLowerCase().includes(filters.wbs_name.toLowerCase()) : true) &&
                (filters.id ? item.id.toString().includes(filters.id) : true) &&
                (filters.name ? item.name.toLowerCase().includes(filters.name.toLowerCase()) : true) &&
                (filters.p6 ? item.p6.toLowerCase().includes(filters.p6.toLowerCase()) : true) &&
                (!item.from_date ? false : filters.from_date ? item.from_date.includes(filters.from_date) : true) &&
                (!item.to_date ? false : filters.to_date ? item.to_date.includes(filters.to_date) : true)
            );
        });
        this.setState({ filteredData: filteredData, chipLoading: true, filters }, () => {
            this.setState({ chipLoading: false });
        });
    }

    expandAll = (open) => {
        let filteredData = this.state.filteredData;
        filteredData.forEach(item => {
            item.isOpen = open;
        })
        this.setState({ filteredData: filteredData });
    }

    rerenderData() {
        this.setState({ isLoading: true }, () => {
            this.getData('rerender', '', null)
        })
    }

    addData(p6_id) {
        // this.setState({ coffeeLoading: true, });
        Service.post(`/bb/api/p6/activity/update/create_activity_link/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                task_id: this.state.selectedData.id,
                p6_id: p6_id
            }
        })
            .then(res => {
                // this.setState({ coffeeLoading: false, });
                this.props.enqueueSnackbar("P6 ID added successfully", { variant: 'success' });
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar("P6 ID already exist", { variant: 'warning' });
                    this.setState({ coffeeLoading: false });
                } else {
                    console.log(error);
                    errorMessage(this.props, error)
                    this.setState({ coffeeLoading: true });
                    this.rerenderData()
                }
            });
    }

    fetchData(type) {
        Service.get(`/bb/api/p6/activity/update/get_p6_wbs_and_activity_data/?project_id=${this.props.projectData.id}&dummy_key=${moment().valueOf()}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (this.state.selectedData !== null && type === 'rerender') {
                    let filteredData = this.state.filteredData
                    for (let i = 0; i < filteredData.length; i++) {
                        const element = filteredData[i];
                        if (element.id === this.state.selectedData.id) {
                            this.setState({ selectedData: element, dataIndex: i })
                            break
                        }
                    }
                    // this.setState({ selectedData: this.state.filteredData[this.state.dataIndex] })
                }
                this.setState({ coffeeLoading: true });
                this.formatData(res.data, type)
            })
            .catch(error => {
                console.log(error)
                this.setState({ coffeeLoading: false });
                errorMessage(this.props, error)
            });
    }

    formatData(data, type) {
        tasks = []
        let linked_p6_ids = this.state.selectedData.p6_activity_link_data.map(item => item.p6_id)
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element) {
                let data = {}
                data.id = element.id
                data.text = element.name
                data.type = "project"
                data.open = true

                if (element.children.length > 0 || element.activity.length > 0) {
                    data.tree = true
                }

                tasks.push(data)
                if (element.children.length > 0) {
                    this.formatChildData(element)
                } else {
                    for (let k = 0; k < element.activity.length; k++) {
                        const activity = element.activity[k];
                        let activity_data = {}

                        if (activity.type === 'Start Milestone') {
                            activity_data.type = 'milestone'
                            activity_data.start_date = !activity.act_exp_start ? moment(activity.from_date).format('DD-MM-YYYY') : moment(activity.act_exp_start).format('DD-MM-YYYY')
                            activity_data.milestone_type = 'Start Milestone'
                        } else if (activity.type === 'Finish Milestone') {
                            activity_data.type = 'milestone'
                            activity_data.start_date = !activity.act_exp_finish ? moment(activity.to_date).format('DD-MM-YYYY') : moment(activity.act_exp_finish).format('DD-MM-YYYY')
                            activity_data.milestone_type = 'Finish Milestone'
                        } else {
                            activity_data.start_date = !activity.act_exp_start ? moment(activity.from_date).format('DD-MM-YYYY') : moment(activity.act_exp_start).format('DD-MM-YYYY')
                            activity_data.end_date = !activity.act_exp_finish ? moment(activity.to_date).format('DD-MM-YYYY') : moment(activity.act_exp_finish).format('DD-MM-YYYY')
                        }

                        if (activity?.new_data) {
                            activity_data.new_data = activity.new_data
                        }

                        activity_data.id = activity.id
                        activity_data.text = activity.name
                        // activity_data.start_date = !activity.act_exp_start ? moment(activity.from_date).format('DD-MM-YYYY') : moment(activity.act_exp_start).format('DD-MM-YYYY')
                        // activity_data.end_date = !activity.act_exp_finish ? moment(activity.to_date).format('DD-MM-YYYY') : moment(activity.act_exp_finish).format('DD-MM-YYYY')
                        activity_data.from_date = moment(activity.from_date).format('DD-MM-YYYY')
                        activity_data.to_date = moment(activity.to_date).format('DD-MM-YYYY')
                        activity_data.percent = activity.percent_complete + '%'
                        activity_data.duration = activity.planned_duration
                        activity_data.parent = element.id
                        activity_data.reason_for_change = activity.reason_for_change
                        activity_data.p6_id = activity.p6_id
                        activity_data.is_linked = linked_p6_ids.includes(activity.p6_id)
                        activity_data.data_missing = activity.data_missing
                        activity_data.date_created = moment(activity.date_created).format('DD-MM-YYYY')

                        tasks.push(activity_data)
                    }
                }
            }
        }
        if (tasks.length === 0) {
            this.props.enqueueSnackbar("No data found", { variant: 'warning' });
            this.setState({ coffeeLoading: false })
        } else {
            this.setState({ coffeeLoading: false, openGantt: true }, () => {
                if (this.ganttRef.current && type === 'rerender') {
                    this.ganttRef.current.getData(tasks)
                }
            })
        }
    }

    formatChildData(childData) {
        let linked_p6_ids = this.state.selectedData.p6_activity_link_data.map(item => item.p6_id)
        for (let j = 0; j < childData.children.length; j++) {
            const childElement = childData.children[j];
            if (childElement) {
                let data = {}
                data.id = childElement.id
                data.text = childElement.name
                // data.type = "project"
                data.open = true
                data.parent = childData.id
                if (childElement.children.length > 0 || childElement.activity.length > 0) {
                    data.tree = true
                }
                tasks.push(data)
                if (childElement.children.length > 0) {
                    this.formatChildData(childElement)
                } else {
                    for (let k = 0; k < childElement.activity.length; k++) {
                        const activity = childElement.activity[k];
                        let activity_data = {}

                        if (activity.type === 'Start Milestone') {
                            activity_data.type = 'milestone'
                            activity_data.start_date = !activity.act_exp_start ? moment(activity.from_date).format('DD-MM-YYYY') : moment(activity.act_exp_start).format('DD-MM-YYYY')
                            activity_data.milestone_type = 'Start Milestone'
                        } else if (activity.type === 'Finish Milestone') {
                            activity_data.type = 'milestone'
                            activity_data.start_date = !activity.act_exp_finish ? moment(activity.to_date).format('DD-MM-YYYY') : moment(activity.act_exp_finish).format('DD-MM-YYYY')
                            activity_data.milestone_type = 'Finish Milestone'
                        } else {
                            activity_data.start_date = !activity.act_exp_start ? moment(activity.from_date).format('DD-MM-YYYY') : moment(activity.act_exp_start).format('DD-MM-YYYY')
                            activity_data.end_date = !activity.act_exp_finish ? moment(activity.to_date).format('DD-MM-YYYY') : moment(activity.act_exp_finish).format('DD-MM-YYYY')
                        }

                        if (activity?.new_data) {
                            activity_data.new_data = activity.new_data
                        }
                        activity_data.id = activity.id
                        activity_data.text = activity.name
                        // activity_data.start_date = !activity.act_exp_start ? moment(activity.from_date).format('DD-MM-YYYY') : moment(activity.act_exp_start).format('DD-MM-YYYY')
                        // activity_data.end_date = !activity.act_exp_finish ? moment(activity.to_date).format('DD-MM-YYYY') : moment(activity.act_exp_finish).format('DD-MM-YYYY')
                        activity_data.from_date = moment(activity.from_date).format('DD-MM-YYYY')
                        activity_data.to_date = moment(activity.to_date).format('DD-MM-YYYY')
                        activity_data.percent = activity.percent_complete + '%'
                        activity_data.duration = activity.planned_duration
                        activity_data.parent = childElement.id
                        activity_data.reason_for_change = activity.reason_for_change
                        activity_data.p6_id = activity.p6_id
                        activity_data.is_linked = linked_p6_ids.includes(activity.p6_id)
                        activity_data.data_missing = activity.data_missing
                        activity_data.date_created = moment(activity.date_created).format('DD-MM-YYYY')

                        tasks.push(activity_data)
                    }
                }
            }
        }
    }

    prevNextChange(action) {
        setTimeout(() => {
            var selectedData = this.state.selectedData
            var filteredData = this.state.filteredData
            var dataIndex = this.state.dataIndex
            for (let i = 0; i < filteredData.length; i++) {
                const element = filteredData[i];
                if (element.id === selectedData.id) {
                    this.setState({ dataIndex: i })
                    break
                }
            }
            if (action === 'prev') {
                dataIndex = dataIndex - 1
                selectedData = filteredData[dataIndex]
                this.setState({ selectedData: selectedData, dataIndex, prevDisabled: dataIndex === 0 ? true : false, nextDisabled: dataIndex === (filteredData.length - 1) ? true : false, coffeeLoading: true }, () => { this.fetchData('rerender') })
            } else if (action === 'next') {
                dataIndex = dataIndex + 1
                selectedData = filteredData[dataIndex]
                this.setState({ selectedData: selectedData, dataIndex, prevDisabled: dataIndex === 0 ? true : false, nextDisabled: dataIndex === (filteredData.length - 1) ? true : false, coffeeLoading: true }, () => { this.fetchData('rerender') })
            }
        }, 500);

    }

    getIndex(data) {
        var filteredData = this.state.filteredData
        var dataIndex = null
        for (let i = 0; i < filteredData.length; i++) {
            const element = filteredData[i];
            if (element.id === data.id) {
                dataIndex = i
                break
            }
        }
        this.setState({
            dataIndex: dataIndex,
            prevDisabled: dataIndex === 0 ? true : false,
            nextDisabled: dataIndex === (filteredData.length - 1) ? true : false,
            selectedData: filteredData[dataIndex]
        }, () => {
            this.setState({ coffeeLoading: true }, () => {
                this.fetchData('init')
            })
        })
    }

    decreaseZoom = () => {
        let zoom = (Number(this.state.zoom) - 0.1).toFixed(1);
        if (zoom < 0.5) return;
        this.setState({ zoom: zoom, })
        localStorage.setItem('zoom_level', zoom)
    }

    increaseZoom = () => {
        let zoom = (Number(this.state.zoom) + 0.1).toFixed(1);
        if (zoom > 1.3) return;
        this.setState({ zoom: zoom, })
        localStorage.setItem('zoom_level', zoom)
    }

    updateAsConfigured = (rowData) => {
        Service.patch(`/bb/api/p6/activity/update/update_task_data/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                task_id: rowData.id,
            }
        })
            .then(res => {
                // this.props.enqueueSnackbar("Data Updated successfully", { variant: 'success' });
                this.getData('init', '', null)
            })
            .catch(error => {
                console.log(error);
                errorMessage(this.props, error)
            });

    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                        <Box sx={{ m: 1 }}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader aria-label="collapsible table" size='small' sx={{ zoom: this.state.zoom }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            {this.state.columns.map((column) => (
                                                <TableCell >{column.title}</TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            {this.state.columns.map((column) => (
                                                <TableCell sx={{ maxWidth: column.width }}>{this.getFilters(column)}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.filteredData.length === 0 ? <TableRow>
                                            <TableCell colSpan={this.state.columns.length + 1} align='center'>No data to display!</TableCell>
                                        </TableRow> :
                                            this.state.filteredData.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow
                                                        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
                                                        onClick={() => this.handleRowClick(index)}>
                                                        <TableCell>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    this.handleRowClick(index)
                                                                }}>
                                                                {row.isOpen ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}
                                                            </IconButton>
                                                        </TableCell>
                                                        {this.state.columns.map((column) => (
                                                            <TableCell onClick={(e) => {
                                                                if (!column.filtering) {
                                                                    e.stopPropagation();
                                                                }
                                                            }} sx={{ background: (column.color && column.row && row.id === column.row.id) ? column.color : '', maxWidth: (column.title === 'P6' ? 300 : '') }}>{column.render ? column.render(row) : row[column.field]}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: getDarkMode() === 'dark' ? '#dac481' : '#AFD6CB' }} colSpan={this.state.columns.length + 1}>
                                                            <Collapse in={row.isOpen} timeout="auto" unmountOnExit>
                                                                <Box sx={{ margin: 1 }}>
                                                                    <Typography variant="h6" gutterBottom component="div">
                                                                        P6 Lists
                                                                    </Typography>
                                                                    <P6DetailPanel data={row} reloadData={this.reload} />
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                }

                <Dialog fullScreen open={this.state.openGantt} TransitionComponent={Transition}>
                    <AppBar position='absolute' color="primary" elevation={0} >
                        <Toolbar>
                            <Box display='flex' flexDirection={'row'} width={'100%'} alignItems='center'>
                                <Box flexGrow={1} display='flex' flexDirection={'row'} alignItems='center'>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        color='inherit'
                                        onClick={() => {
                                            this.setState({ openGantt: false, selectedData: null, dataIndex: null, prevDisabled: false, nextDisabled: false, isLoading: true });
                                            tasks = [];
                                            this.getData('init', '', null)
                                        }}>
                                        <CloseIcon fontSize='small' />
                                    </IconButton>
                                    <Typography variant="h6" >
                                        {!this.state.selectedData?.decs ? this.state.selectedData?.name : this.state.selectedData?.decs}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Button variant="contained" color="secondary" size="small" startIcon={<ArrowBackIosIcon />} style={{ marginRight: '10px' }} disabled={this.state.prevDisabled} onClick={() => this.prevNextChange('prev')}>Prev</Button>
                                    <Button variant="contained" color="secondary" size="small" endIcon={<ArrowForwardIosIcon />} disabled={this.state.nextDisabled} onClick={() => this.prevNextChange('next')}>Next</Button>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <PIMGanttChart ref={this.ganttRef} data={tasks} addData={this.addData} p6_data={this.state.selectedData} />
                </Dialog>

                <Dialog maxWidth="sm" open={this.state.coffeeLoading} aria-labelledby="form-dialog-title" >
                    <DialogContent>
                        <Box m={5} p={2}>
                            <CoffeeLoading />
                        </Box>
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

export default withSnackbar(PIMLink);