import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../../Helper/CookieHelper';
import Service from '../../Networking/networkutils';
import { errorMessage } from '../../Helper/ErrorMessage';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../Helper/CommonThings';
import MaterialTable from 'material-table';
import { Button } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class PIMTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
        }
    }
    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get(`/bb/api/task/process/timeline/?exclude_date__year=${this.props.value}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isLoading: false, data: res.data, })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postOps(data) {
        if (!data.cutoff_date || !data.exclude_date) {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Please select all fields!', {
                    variant: 'warning'
                });
            }, 200)

        } else {
            let postData = {
                cutoff_date: moment(data.cutoff_date).format('YYYY-MM-DD'),
                exclude_date: moment(data.exclude_date).format('YYYY-MM-DD'),
                created_by: user.id,
            }
            Service.post('/bb/api/task/process/timeline/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData,
            })
                .then(res => {
                    this.setState({ isLoading: true, })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Timeline added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    putOps(data) {
        if (!data.cutoff_date || !data.exclude_date) {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Please select all fields!', {
                    variant: 'warning'
                });
            }, 200)

        } else {
            let postData = data
            data.cutoff_date = moment(data.cutoff_date).format('YYYY-MM-DD')
            data.exclude_date = moment(data.exclude_date).format('YYYY-MM-DD')
            Service.put('/bb/api/task/process/timeline/' + data.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Timeline updated successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ isLoading: true, })
                    errorMessage(this.props, error)
                    this.fetchOps();
                });
        }
    }

    deleteOps(data) {
        Service.delete('/bb/api/task/process/timeline/' + data.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Timeline deleted successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({ isLoading: true, })
                errorMessage(this.props, error)
                this.fetchOps();
            });
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            key={3}
                            title="PIM Timelines"
                            columns={[
                                { title: 'Cut Off Date', field: 'cutoff_date', type: 'date' },
                                { title: 'Exclude Date', field: 'exclude_date', type: 'date' },
                                {
                                    title: 'Created By', field: 'created_by', editable: 'never',
                                    render: rowData => rowData.created_by_first_name ? (rowData.created_by_first_name + ", " + rowData.created_by_last_name) : null
                                },
                            ]}
                            data={this.state.data}
                            options={{
                                paging: false,
                                padding: "dense",
                                addRowPosition: "first",
                                // actionsColumnIndex: -1,
                            }}
                            icons={{
                                Add: props => <Button size='small' color='primary' variant="contained" startIcon={<AddIcon />}>
                                    Add Timeline
                                </Button>
                            }}
                            editable={
                                {
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        this.putOps(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 600);
                                        }),

                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    this.postOps(newData);
                                                    data.push(newData);
                                                    // this.postData(newData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600)
                                        }),

                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    this.deleteOps(oldData);
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default withSnackbar(PIMTimeline);