import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Service from '../../Networking/networkutils';
import { Box, Checkbox, CircularProgress, Paper } from '@mui/material';
import moment from 'moment';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getMode } from '../../MainDashBoard/ColorTheme/ColorTheme';

const token = getCookie("bb_ts_token");

class P6DetailPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        Service.get(`/bb/api/p6/activity/update/pim_task_p6data/?task_id=${this.props.data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data }, () => {
                    this.getColumns()
                });
            })
            .catch(error => {
                console.log(error);
                errorMessage(this.props, error)
            });
    }

    checkDefaultStart = (row) => {
        let data = {
            item_id: row.link_id,
            default_baseline_start: !row.default_baseline_start,
        }
        this.changeDefault(data, 'start')
    }

    checkDefaultFinish = (row) => {
        let data = {
            item_id: row.link_id,
            default_baseline_finish: !row.default_baseline_finish,
        }
        this.changeDefault(data, 'finish')
    }

    changeDefault(data, type) {
        Service.patch('/bb/api/p6/activity/update/pim_baseline_dates_update/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.getData()
                if (this.props.reloadData) {
                    this.props.reloadData(type, this.props.data)
                }
            })
            .catch(error => {
                this.setState({ appDisabled: false })
                errorMessage(this.props, error)
            });
    }

    getColumns() {
        let columns = [
            {
                title: "P6 ID", field: 'p6_id'
            },
            {
                title: 'P6 Name', field: 'name'
            },
            {
                title: 'P6 Baseline Start', field: 'from_date',
                render: (row) => <Box display='flex' flexDirection={'row'} alignItems={'center'}>
                    <Checkbox disabled={row.data_missing} size='small' checked={row.default_baseline_start} onClick={() => this.checkDefaultStart(row)} />
                    {moment(row.from_date).format('DD/MM/YYYY')}
                </Box>,
            },
            {
                title: "P6 Baseline Finish", field: 'to_date',
                render: (row) =>
                    <Box display='flex' flexDirection={'row'} alignItems={'center'}>
                        <Checkbox disabled={row.data_missing} size='small' checked={row.default_baseline_finish} onClick={() => this.checkDefaultFinish(row)} />
                        {moment(row.to_date).format('DD/MM/YYYY')}
                    </Box>
            },
            {
                title: "P6 Actual/Expected Start", field: 'act_exp_start',
                render: (row) => (moment(row.act_exp_start).format('DD/MM/YYYY'))
            },
            {
                title: 'P6 Actual/Expected Finish', field: 'act_exp_finish',
                render: (row) => (moment(row.act_exp_finish).format('DD/MM/YYYY'))
            },
            // {
            //     title: 'Updated Actual/Expected Start', field: 'updated_act_exp_start',
            //     render: (row) => !row.updated_act_exp_start ? "" : (moment(row.updated_act_exp_start).format('DD/MM/YYYY'))
            // },
            // {
            //     title: 'Updated Actual/Expected Finish', field: 'updated_act_exp_finish',
            //     render: (row) => !row.updated_act_exp_finish ? "" : (moment(row.updated_act_exp_finish).format('DD/MM/YYYY'))
            // },
            // {
            //     title: 'Reason For Change', field: 'reason_for_change',
            // },
            // {
            //     title: 'Updated On', field: 'updated_on',
            // },
        ]
        this.setState({ columns: columns, isLoading: false });
    }

    getBgColor = (row) => {
        if (row.data_missing) {
            return getMode() ? '#ff0000' : '#ffb3b3'
        } 
        // else if (row.new_data) {
        //     return getMode() ? '#b3b300' : '#ffff00'
        // }
        return ''
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ? <CircularProgress color='primary' />
                        :
                        <Paper>
                            <Table aria-label="collapsible table" size='small'>
                                <TableHead>
                                    <TableRow>
                                        {
                                            this.state.columns.map((column) => (
                                                <TableCell style={{ maxWidth: (column.width ? column.width : '') }}>{column.title}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.data.map((row, index) => (
                                            <React.Fragment>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: this.getBgColor(row) }}>
                                                    {
                                                        this.state.columns.map((column) => (
                                                            <TableCell style={{ maxWidth: (column.width ? column.width : '') }}>{column.render ? column.render(row) : row[column.field]}</TableCell>
                                                        ))
                                                    }
                                                </TableRow>
                                            </React.Fragment>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Paper>
                }
            </div>
        );
    }
}

export default withSnackbar(P6DetailPanel);