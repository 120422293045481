import React, { Component } from 'react';
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { Dialog, AppBar, Toolbar, IconButton, DialogTitle, Box, TextField, Grid, FormControl, Select, MenuItem, ThemeProvider, createTheme, FormControlLabel, Checkbox, } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import { getCookie } from '../Helper/CookieHelper';
import CloseIcon from '@material-ui/icons/Close';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { errorMessage } from '../Helper/ErrorMessage';
import { CoffeeLoading } from 'react-loadingg';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getDarkMode, getPrimaryColor, onlyUniqueArr } from '../Helper/CommonThings';
import ProjectReportsTabs from '../SecuredWorks/ProjectReport/ProjectReportTabs';
import DateFnsUtils from '@date-io/date-fns';
import DownloadReport from '../SecuredWorks/ProjectReport/DownloadReport';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

const bbColorThemered = createTheme({
    palette: {
        primary: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

class PSRReports extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openSnapshot: false,
            postSnapshotName: null,
            postSnapshotDate: null,
            postSnapshotMonth: null,
            postSnapshotDateLimit1: null,
            postSnapshotDateLimit2: null,
            openAddPsrComment: false,
            addPsrComment: "",
            psrSnapshotHistoryId: 0,
            psrSnapshots: [],
            psrSnapshotsYears: [],
            psrSnapshotSelectedYear: 0,
            psrComment: [],
            openComments: false,
            openCoffee: true,
            openDownloadReport: false,
            downloadReportData: {},
            openProjectReport: false,
            psrAuditStatus: 10,
            projectReportData: {},
            isLoading: true,
            checkedEvpSnapshot: false
        }
    }

    componentDidMount() {

        if (token) {
            this.fetchOps()
        } else {
            alert("You don't have access to view this page!.")
            window.close()
        }
    }

    fetchOps() {
        this.setState({
            openCoffee: true
        })

        let filterCheck = false
        var url = "/bb/api/tender/project/get_tender_data/?pid=" + this.props.match.params.pid;
        if ((access && access.access && access.access.l4a && access.access.l4a.l4a_v) || (access && access.access && access.access.l4a && access.access.l4a.l4a_ab)) {
            filterCheck = true
        }

        if (filterCheck) {
            url += "&disable_filter=true";
        } else {
            url += "&disable_filter=false";
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log((access && access.access && access.access.l4a.l4a_x))
                // console.log(res.data.report_manager, res.data.tenderbid_data.pdm, access.access.l4a.l4a_x)
                // ((rowData.psr && ((access && access.access && access.access.l4a.l4a_u) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab))
                // if ([0, 10, 11, 13].includes(res.data.audit_status) && (((res.data.report_manager && res.data.report_manager.length > 0 && res.data.report_manager[0].fields.psr) && ((access && access.access && access.access.l4a.l4a_u) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab))) {
                if ([0, 10, 11, 13].includes(res.data.audit_status) && (((res.data.report_manager && res.data.report_manager.length > 0 && res.data.report_manager[0].fields.psr) && ((access && access.access && access.access.l4a.l4a_u) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab))) {
                    this.setState({
                        downloadReportData: res.data,
                        project: res.data,
                        isLoading2: false
                    })
                    this.projectReport(res.data, parseInt(this.props.match.params.audit))
                } else {
                    alert("Report is unavailable!.")
                    // window.close()
                }

            })
            .catch(error => {
                console.log(error)
                if (error && error.status === 406) {
                    alert("You don't have access to view this page!.")
                    window.close()
                } else {
                    // errorMessage(this.props, error)
                    alert("Some of the data is not updated. Please update the % complete, and estimated end dates to generate the EVP curve.")
                    // window.close()
                }
            });
    }

    projectReport(data, audit_status) {
        if (audit_status !== 11) {
            audit_status = "10,13"
        }

        var url = "/bb/api/report/view/project_timesheet_report/?pid=" + data.id + "&audit_status=" + audit_status;

        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
            timeout: 1000 * 600
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    projectReportData: res.data,
                    openCoffee: false,
                    openProjectReport: true,
                })
                this.getPsrSnapshotHistory(data, audit_status)
            })
            .catch(error => {
                this.setState({
                    openCoffee: false
                })
                errorMessage(this.props, error)
            });

    }

    getPsrSnapshotHistory(data, audit_status) {

        var url = "/bb/api/tender/psr/snapshot/get_snapshot_history/?pid=" + data.id;

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var years = []
                var dataTo = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.audit_status !== 11) {
                        dataTo.push(element)
                        years.push(moment(element.month_of_snapshot).format("YYYY"))
                    }
                }
                years = years.filter(onlyUniqueArr)

                // console.log(years)
                // console.log(years)
                this.setState({
                    psrSnapshots: dataTo,
                    openCoffee: false,
                    openProjectReport: true,
                    psrSnapshotsYears: years
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }

    getPsrSnapshot(data) {
        var url = "/bb/api/tender/psr/snapshot/get_snapshot/?id=" + data;

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var psrComment = []

                if (res.data.comments) {
                    psrComment = res.data.comments
                }
                this.setState({
                    projectReportData: res.data,
                    openCoffee: false,
                    openProjectReport: true,
                    psrComment: psrComment,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    deletePsrSnapshot(data) {
        var url = "/bb/api/tender/psr/snapshot/" + data + "/";

        this.setState({
            openCoffee: true,
            openProjectReport: false
        })

        Service.delete(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                this.projectReport(this.state.downloadReportData, this.state.psrAuditStatus)
                this.setState({
                    openDownloadReport: false,
                    openCoffee: true,
                    psrSnapshotHistoryId: 0,
                    psrSnapshots: [],
                    psrSnapshotSelectedYear: 0,
                    psrSnapshotsYears: [],
                })

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    putPsrCommentSnapshot(data, id) {
        var url = "/bb/api/tender/psr/snapshot/" + id + "/";

        Service.put(url, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: id,
                comments: data
            }
        })
            .then(res => {
                // console.log(res.data)

                this.props.enqueueSnackbar('Comment successfully posted.', {
                    variant: 'success'
                });

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    takeSnapshot(data, audit_status, postData) {
        if (audit_status !== 11) {
            audit_status = "10,13"
        }
        var url = "/bb/api/tender/psr/snapshot/create_snapshot/?pid=" + data.id + "&audit_status=" + audit_status;

        Service.post(url, {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData
        })
            .then(res => {
                this.props.enqueueSnackbar('Snapshot taken successfully', {
                    variant: 'success'
                });

                if (audit_status !== 11 && this.state.checkedEvpSnapshot) {
                    alert()
                    var url1 = "/bb/api/tender/evp/snapshot/create_snapshot/?pid=" + data.id + "&audit_status=" + audit_status;

                    Service.post(url1, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: postData
                    })
                        .then(res => {

                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });
                }


                this.setState({
                    postSnapshotDate: null,
                    postSnapshotName: null,
                    postSnapshotMonth: null,
                    postSnapshotDateLimit1: null,
                    postSnapshotDateLimit2: null
                })
                this.getPsrSnapshotHistory(this.state.downloadReportData, this.state.psrAuditStatus)
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleSnapshotSubmit() {

        // console.log(moment(this.state.postSnapshotMonth).format("DD-MM-YYYY"))
        if (this.state.postSnapshotName && this.state.postSnapshotName.trim() && moment(this.state.postSnapshotDate).isBetween(this.state.postSnapshotDateLimit1, this.state.postSnapshotDateLimit2)) {
            var data = {
                name: this.state.postSnapshotName,
                timesheet_last_date: moment(this.state.postSnapshotDate).format("DD-MM-YYYY"),
                month_date: moment(this.state.postSnapshotMonth).format("DD-MM-YYYY")
            }
            // console.log(data)
            this.takeSnapshot(this.state.downloadReportData, this.state.psrAuditStatus, data)
            this.setState({
                openCoffee: true,
                openSnapshot: false
            })
        } else {
            this.props.enqueueSnackbar('Actual timesheet end date should be within the month of snapshot report', {
                variant: 'warning'
            });
        }

    }

    render() {

        const handleClose4 = () => {
            this.setState({ openSnapshot: false, });
        };

        const handleChangeWithId = (event) => {
            // console.log(event.target.id, event.target.value)
            this.setState({ [event.target.id]: event.target.value });
        }

        const handleDateChange = (date) => {

            this.setState({
                postSnapshotDate: date
            })
        };

        const handleDateChange2 = (date) => {
            var startOfMonth = moment(date).clone().startOf('month');
            var endOfMonth = moment(date).clone().endOf('month');
            // console.log(startOfMonth, endOfMonth)
            this.setState({
                postSnapshotMonth: startOfMonth,
                postSnapshotDateLimit1: startOfMonth,
                postSnapshotDateLimit2: endOfMonth,
            })
        };

        const handlePsrCommentlose = () => {
            this.setState({ openComments: false, });

        };

        const handleAddPsrCommentClose = () => {
            this.setState({ openAddPsrComment: false, });

        };

        const handlePsrCommentChange = (event) => {
            this.setState({
                addPsrComment: event.target.value
            })
        };

        const handleCheckedEvpSnapshot = (event) => {
            this.setState({ [event.target.name]: event.target.checked });
        };

        const handleChangeSelect = (event) => {
            if (event.target.value === 0) {
                this.projectReport(this.state.downloadReportData, this.state.psrAuditStatus)

            } else {
                this.getPsrSnapshot(event.target.value)
            }

            this.setState({
                openProjectReport: false,
                openCoffee: true,
                psrSnapshotHistoryId: event.target.value
            })
        };

        const handleChangeSelect2 = (event) => {
            if (event.target.value === 0) {
                this.setState({
                    openDownloadReport: false,
                    openCoffee: true,
                    openProjectReport: false,
                    psrSnapshotHistoryId: 0,
                    psrSnapshots: [],
                    psrSnapshotSelectedYear: 0,
                    psrSnapshotsYears: [],
                })
                this.projectReport(this.state.downloadReportData, this.state.psrAuditStatus)
            } else {
                var defaultSelectId = 0
                for (let i = 0; i < this.state.psrSnapshots.length; i++) {
                    const element = this.state.psrSnapshots[i];
                    if (!defaultSelectId && parseInt(moment(element.month_of_snapshot).format("YYYY")) === parseInt(event.target.value)) {
                        defaultSelectId = element.id
                        break;
                    }
                }
                if (defaultSelectId !== 0) {
                    this.getPsrSnapshot(defaultSelectId)
                }

                this.setState({
                    openProjectReport: (defaultSelectId === 0),
                    openCoffee: (defaultSelectId !== 0),
                    psrSnapshotHistoryId: defaultSelectId,
                    psrSnapshotSelectedYear: parseInt(event.target.value)
                })
            }
        };

        return (
            <div>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Project Status Report for {this.state.project ? this.state.project.tender_name : null}
                        </Typography>
                        {/* <Button color="inherit">Login</Button> */}
                    </Toolbar>
                </AppBar>

                {this.state.openCoffee ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <>
                        <Box display="flex" m={2} >

                            <Box>
                                <FormControl style={{ zoom: 1, marginRight: "30px" }}>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={this.state.psrSnapshotSelectedYear}
                                        onChange={handleChangeSelect2}
                                    >
                                        <MenuItem value={0}>
                                            <em>Live</em>
                                        </MenuItem>
                                        {this.state.psrSnapshotsYears.map(item => {
                                            return <MenuItem value={item}>{item}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                            </Box>

                            {(this.state.psrSnapshotSelectedYear > 0) ? (
                                <Box >
                                    <FormControl style={{ zoom: 1, marginRight: "30px" }}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={this.state.psrSnapshotHistoryId}
                                            onChange={handleChangeSelect}
                                        >

                                            {this.state.psrSnapshots.map(item => {
                                                if (parseInt(moment(item.month_of_snapshot).format("YYYY")) === this.state.psrSnapshotSelectedYear) {
                                                    return <MenuItem value={item.id}>{moment(item.month_of_snapshot).format("MMM_YYYY") + "_" + item.name}</MenuItem>

                                                } else {
                                                    return null
                                                }
                                            })}


                                        </Select>
                                    </FormControl>

                                </Box>
                            ) : (null)}

                            {(this.state.psrSnapshotSelectedYear === 0) ? (
                                <Box >
                                    <Button color="primary" style={{ marginRight: '25px' }} size="small" onClick={() => {
                                        this.setState({
                                            openSnapshot: true
                                        })
                                    }}>Take Snapshot</Button>
                                </Box>
                            ) : (null)}

                            {(this.state.psrSnapshotHistoryId !== 0) ? (


                                <Box >

                                    <Button color="primary" style={{ marginRight: '25px' }} size="small" onClick={() => {
                                        // console.log(this.state.projectReportData)
                                        this.setState({ openComments: true })
                                    }}>Comments</Button>

                                </Box>

                            ) : (null)}

                            {(this.state.psrSnapshotHistoryId !== 0) ? (

                                <Box >
                                    <ThemeProvider theme={bbColorThemered} >
                                        <Button color="primary" style={{ marginRight: '25px' }} size="small" onClick={() => {
                                            this.deletePsrSnapshot(this.state.psrSnapshotHistoryId)
                                        }}>Delete</Button>
                                    </ThemeProvider>
                                </Box>


                            ) : (null)}

                            <Button color="primary" style={{ marginRight: '30px' }} size="small" onClick={() => {
                                window.open("https://faqs.bbparam.com/paramfaqs/availableworkbook#q3")
                            }}>Help</Button>

                            <Box>
                                <DownloadReport value={this.state.projectReportData} projName={this.state.downloadReportData.tender_name} />
                            </Box>

                        </Box>

                        <ProjectReportsTabs value={this.state.projectReportData} tenderid={this.state.downloadReportData.id} projName={this.state.downloadReportData.tender_name} psrSnapshotId={this.state.psrSnapshotHistoryId} />
                    </>
                }
                <Dialog maxWidth="sm" open={this.state.openCoffee} aria-labelledby="form-dialog-title">
                    {/* <DialogTitle id="customized-dialog-title" onClose={handleFeedbackClose}>
                                    Additional Hours Request
                                </DialogTitle> */}
                    <DialogContent>
                        <Box m={5} p={2}>
                            <CoffeeLoading />
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.openSnapshot} onClose={handleClose4} aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title1" onClose={handleClose4}>
                        <Box display="flex" >
                            <Box flexGrow={1}>
                                Take Snapshot
                            </Box>

                            <Box >
                                <IconButton onClick={handleClose4} aria-label="close" size="small">
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        </Box>

                    </DialogTitle>
                    <DialogContent>

                        <Box display="flex">
                            <TextField id="postSnapshotName" fullWidth style={{ marginBottom: '25px' }} type="text" label="Snapshot Name" onChange={handleChangeWithId} />

                        </Box>
                        <Box display="flex">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            fullWidth
                                            variant="inline"
                                            id="postSnapshotMonth"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Month of the Snapshot"
                                            helperText="Select month of the snapshot"
                                            value={this.state.postSnapshotMonth}
                                            onChange={handleDateChange2}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>

                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="postSnapshotDate"
                                            label="Actual hours upto(dd/MM/yyyy)"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={this.state.postSnapshotDate}
                                            onChange={handleDateChange}
                                            disabled={!this.state.postSnapshotMonth}
                                            minDate={this.state.postSnapshotDateLimit1}
                                            maxDate={this.state.postSnapshotDateLimit2}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            helperText="Select the date upto which the Actual hours data to be updated for the report"
                                        />

                                    </Grid>
                                </Grid>


                            </MuiPickersUtilsProvider>
                        </Box>
                        {(parseInt(this.props.match.params.audit) !== 11) ?
                            <Box display="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.checkedEvpSnapshot}
                                            onChange={handleCheckedEvpSnapshot}
                                            name="checkedEvpSnapshot"
                                            color="primary"
                                        />
                                    }
                                    label="Include EVP Snapshot"
                                />
                            </Box>
                            : null}

                        {/* <TextField id="postSnapshotDate" type="text" label="Timesheet Last Date" variant="outlined" onChange={handleChangeWithId} /> */}

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose4} color="primary">
                            Close
                        </Button>
                        <Button onClick={() => this.handleSnapshotSubmit()} color="primary">
                            Submit
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openAddPsrComment}
                    onClose={handleAddPsrCommentClose}
                    maxWidth={"sm"}
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fill you comment below"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="outlined-multiline-static"
                            label="Comment"
                            multiline
                            rows={4}
                            fullWidth
                            value={this.state.addPsrComment}
                            onChange={handlePsrCommentChange}
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddPsrCommentClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            if (this.state.addPsrComment && this.state.addPsrComment.trim()) {

                                this.setState(prevState => {
                                    var newData = {}
                                    let psrComment = [...prevState.psrComment];
                                    // console.log(psrComment)
                                    newData.user_name = user.first_name + " " + user.last_name
                                    newData.user = user.id
                                    newData.date_created = new Date()
                                    newData.comment = this.state.addPsrComment.trim();
                                    // console.log(newData)
                                    psrComment = [...[newData], ...psrComment];
                                    this.putPsrCommentSnapshot(psrComment, this.state.psrSnapshotHistoryId);
                                    return { ...prevState, psrComment: psrComment, openAddPsrComment: false };
                                });
                            } else {
                                this.props.enqueueSnackbar('Please fill the comment!', {
                                    variant: 'warning'
                                });
                            }

                        }} color="primary" autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openComments} maxWidth={"lg"} fullWidth onClose={handlePsrCommentlose} aria-labelledby="form-dialog-title">
                    <DialogContent>

                        <MaterialTable

                            title={this.state.downloadReportData.tender_name}
                            columns={[
                                {
                                    title: 'User', field: 'user_name', editable: 'never',
                                },
                                {
                                    title: 'Comment', field: 'comment',
                                },
                                {
                                    title: 'Commented On', field: 'date_created', type: 'datetime', editable: 'never', defaultSort: 'desc',
                                },

                            ]}
                            data={this.state.psrComment}
                            options={{
                                padding: "dense",
                                actionsColumnIndex: -1
                            }}
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Add Comment',
                                    isFreeAction: true,
                                    onClick: (event) => { this.setState({ openAddPsrComment: true }) },
                                },
                            ]}
                        // editable={{
                        //     onRowAdd: newData =>
                        //         new Promise(resolve => {
                        //             setTimeout(() => {
                        //                 resolve();
                        //                 if (!newData.comment || newData.comment.trim() === '') {
                        //                     this.props.enqueueSnackbar('Fill Comments', {
                        //                         variant: 'warning'
                        //                     });
                        //                 } else {

                        //                     this.setState(prevState => {
                        //                         let psrComment = [...prevState.psrComment];
                        //                         console.log(psrComment)
                        //                         newData.user_name = user.first_name + " " + user.last_name
                        //                         newData.user = user.id
                        //                         newData.date_created = new Date()
                        //                         newData.comment = newData.comment.trim();
                        //                         // console.log(newData)
                        //                         psrComment = [...[newData], ...psrComment];
                        //                         this.putPsrCommentSnapshot(psrComment, this.state.psrSnapshotHistoryId);
                        //                         return { ...prevState, psrComment: psrComment };
                        //                     });
                        //                 }

                        //             }, 600);
                        //         }),
                        // }}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handlePsrCommentlose} color="primary">
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>


            </div>
        );
    }
}

export default withSnackbar(PSRReports);