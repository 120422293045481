import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Service from "../Networking/networkutils";
import { LadderLoading } from 'react-loadingg';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { setCookie, getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { getPrimaryColor } from '../Helper/CommonThings';
import Slide from '@material-ui/core/Slide';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://balfourbeatty.com/">
                Balfour Beatty plc
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random?work,office)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class Login extends Component {

    componentDidMount() {

        // No more login page
        if (window.location.pathname === 'bbparam.com') {
            window.location.href = '/'
        }


        var pageView = window.location.pathname + "/#Unsecured Work";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        // console.log(getCookie('bb_ts_token'))
        if (getCookie("bb_ts_token")) {
            window.location.href = '/'
        }
    }

    render() {
        return (
            <div>
                <SignInSide />
            </div>
        )
    }
}

function SignInSide() {
    const classes = useStyles();
    const [wrongData, setValue] = React.useState(false);
    const [failMessage, setFail] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [token, setToken] = React.useState('');
    const [user, setUser] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [otp, setOTP] = React.useState('');
    const [remember, setRemember] = React.useState(false);
    const [otptype, setOTPType] = React.useState('app');
    const { enqueueSnackbar } = useSnackbar();
    const [openForgotPassword, setOpenForgotPassword] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [disabled, setDisabled] = React.useState(false);

    const VerifyOTP = e => {
        e.preventDefault();
        if (otptype === 'app') {
            if (otp.length === 6) {
                Service.post('/bb/api/totp/login/' + otp + '/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        if (res.data || res.data === 'true') {
                            if (remember) {
                                setCookie('bb_ts_token', token, { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },);
                            } else {
                                setCookie('bb_ts_token', token, { secure: true, 'max-age': 86400, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },);
                            }
                            // setCookie('bb_ts_token', token, {secure: true, 'max-age': 300, 'domain': window.location.host, 'samesite': 'strict', 'path': '/',},);
                            setCookie('bb_ts_user', JSON.stringify(user), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                            localStorage.setItem("load_got_user", true);
                            setValue(false)
                            enqueueSnackbar('Verification Successful!', {
                                variant: 'success'
                            });
                            window.location.href = '/'
                        } else {
                            var errormessage = 'Information is not correct!';

                            enqueueSnackbar(errormessage, {
                                variant: 'error'
                            });
                        }

                    })
                    .catch(error => {
                        var errormessage = 'Information is not correct!';

                        enqueueSnackbar(errormessage, {
                            variant: 'error'
                        });

                    });
            } else {
                enqueueSnackbar("OTP should 6 digits", {
                    variant: 'warning'
                });
            }
        } else {
            if (otp !== '') {
                Service.post('/bb/api/static/login/' + otp + '/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        if (remember) {
                            setCookie('bb_ts_token', token, { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },);
                        } else {
                            setCookie('bb_ts_token', token, { secure: true, 'max-age': 86400, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },);
                        }
                        // setCookie('bb_ts_token', token, {secure: true, 'max-age': 300, 'domain': window.location.host, 'samesite': 'strict', 'path': '/',},);
                        setCookie('bb_ts_user', JSON.stringify(user), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                        localStorage.setItem("load_got_user", true);
                        setValue(false)
                        enqueueSnackbar('Verification Successful!', {
                            variant: 'success'
                        });
                        window.location.href = '/home'

                    })
                    .catch(error => {
                        var errormessage = 'Information is not correct!';

                        enqueueSnackbar(errormessage, {
                            variant: 'error'
                        });

                    });
            } else {
                enqueueSnackbar("OTP should not empty", {
                    variant: 'warning'
                });
            }
        }

    }

    const getOtp = e => {
        Service.get('/bb/api/static/create/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                enqueueSnackbar('Verification code send to your email', {
                    variant: 'success'
                });

            })
            .catch(error => {
                var errormessage = 'Information is not correct!';

                enqueueSnackbar(errormessage, {
                    variant: 'error'
                });

            });
    }

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true)
        const data = new FormData(e.target);
        const dataJson = Object.fromEntries(data);
        var errormessage = 'Something went wrong!';
        // console.log(dataJson)
        Service.post('/rest-auth/login/', {
            data: {
                email: dataJson.email.toLowerCase(),
                password: dataJson.password
            },
        })
            .then(res => {
                setToken(res.data.key)
                //console.log(res.data)
                Service.get(`/rest-auth/user/`, {
                    headers: {
                        Authorization: "Token " + res.data.key,
                    },
                })
                    .then(res1 => {
                        setUser(res1.data)
                        // console.log(window.location.host)
                        if (!res1.data.twof_auth) {
                            if (remember) {
                                setCookie('bb_ts_token', res.data.key, { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },);
                            } else {
                                setCookie('bb_ts_token', res.data.key, { secure: true, 'max-age': 86400, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },);
                            }
                            setCookie('bb_ts_user', JSON.stringify(res1.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                            localStorage.setItem("load_got_user", true);
                            setValue(false)
                            window.location.href = '/'
                        } else {
                            setOpen(true)
                        }
                    })
                    .catch(error => {
                        setLoading(false)
                        setValue(true);
                        setFail(errormessage)
                    });
            })
            .catch(error => {
                //  console.log(error.data)
                setLoading(false)

                try {

                    if (error.data.non_field_errors) {
                        for (let i = 0; i < error.data.non_field_errors.length; i++) {
                            const element = error.data.non_field_errors[i];
                            if (i === 0) {
                                errormessage = element;
                            } else {
                                errormessage = errormessage + "\n" + element;
                            }

                        }
                    } else if (error.data.email) {
                        for (let i = 0; i < error.data.email.length; i++) {
                            const element = error.data.email[i];
                            if (i === 0) {
                                errormessage = element;
                            } else {
                                errormessage = errormessage + "\n" + element;
                            }

                        }
                    } else if (error.data.password) {
                        for (let i = 0; i < error.data.password.length; i++) {
                            const element = error.data.password[i];
                            if (i === 0) {
                                errormessage = element;
                            } else {
                                errormessage = errormessage + "\n" + element;
                            }

                        }
                    }
                } catch (error) {

                }

                setFail(errormessage)

                setValue(true);
            });
    };

    const submitForgotPassword = () => {
        if (email.length === 0) {
            enqueueSnackbar('Please enter the Email ID', {
                variant: 'warning'
            });
        } else if (!email.includes('@balfourbeatty.com')) {
            enqueueSnackbar('Please enter Balfour Beatty Email ID', {
                variant: 'warning'
            });
        } else {
            setDisabled(true)
            Service.post(`/bb/api/cron/password_change/`, {
                data: {
                    'email': email
                }
            })
                .then(res => {
                    enqueueSnackbar('We will send the new password to this email shortly.please check it!', {
                        variant: 'info'
                    })
                    setOpenForgotPassword(false)
                    setEmail('')
                    setDisabled(false)
                })
                .catch(error => {
                    console.log(error);
                    setDisabled(false)
                    if (error.status === 406) {
                        enqueueSnackbar('Email that you are entered did not exist!', {
                            variant: 'warning'
                        })
                    } else {
                        enqueueSnackbar('Something went wrong!', {
                            variant: 'error'
                        })
                    }
                });
        }
    }

    return (
        <>
            {(isLoading) ? (
                <LadderLoading color={getPrimaryColor()} />
            ) : (
                <Grid container component="main" className={classes.root}>
                    <CssBaseline />
                    <Grid item xs={false} sm={4} md={7} className={classes.image} />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <div className={classes.paper}>
                            <img alt={'Balfour Beatty'} style={{ width: '100%' }} src={process.env.PUBLIC_URL + '/bb.png'} />

                            <Avatar className={classes.avatar} >
                                <LockOutlinedIcon align="center" />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign In
                            </Typography>

                            <form className={classes.form} onSubmit={handleSubmit} noValidate>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Balfour Beatty Email Address"
                                    name="email"
                                    autoComplete="text"
                                    autoFocus
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <FormControlLabel
                                    control={<Checkbox value={remember} onChange={() => { setRemember(!remember) }} color="primary" />}
                                    label="Remember me for next 7 days"
                                />
                                {wrongData === true ? (
                                    <p style={{ color: "red", fontSize: 14 }}>
                                        {failMessage}
                                    </p>
                                ) : null}
                                <Button
                                    size='small'
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Sign In
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="https://api.bbparam.com/auth/password_reset/" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    {/* <Grid item>
        <Link href="/signup" variant="body2">
            {"Don't have an account? Sign Up"}
        </Link>
    </Grid> */}
                                </Grid>

                            </form>

                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            )}

            <Dialog open={open} onClose={() => { setOpen(false); setLoading(false) }} fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Two Factor Authentication</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter OTP in authenticator app / email
                    </DialogContentText>
                    {(otptype === 'app') ? (
                        <TextField
                            autoFocus
                            variant="outlined"
                            margin="dense"
                            name="code"
                            label="Enter OTP shown in authenticator app."
                            type="numeric"
                            id="code"
                            value={otp}
                            onChange={(e) => { setOTP(e.target.value) }}
                            fullWidth
                        />
                    ) : (
                        <TextField
                            autoFocus
                            variant="outlined"
                            margin="dense"
                            name="code"
                            label="Enter Code send to you email."
                            id="code"
                            value={otp}
                            onChange={(e) => { setOTP(e.target.value) }}
                            fullWidth
                        />
                    )}

                </DialogContent>
                <DialogActions>
                    <Button size='small' onClick={() => { setOpen(false); setLoading(false) }} color="primary">
                        Close
                    </Button>
                    {(otptype === 'app') ? (
                        <Button size='small' onClick={() => { setOTPType('email'); setOTP(''); getOtp(); }} color="primary">
                            Receive Codes via Email
                        </Button>
                    ) : (
                        <Button size='small' onClick={() => { setOTPType('app'); setOTP(''); }} color="primary">
                            Receive Codes via Authenticator
                        </Button>
                    )}
                    <Button size='small' onClick={VerifyOTP} color="primary">
                        Verify
                    </Button>
                </DialogActions >
            </Dialog >
            <Dialog open={openForgotPassword} onClose={() => setOpenForgotPassword(false)} TransitionComponent={Transition}>
                <DialogTitle>Enter your Email ID</DialogTitle>
                <Box margin={2}>

                    <DialogContentText>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            Please type your email below, Password will be sent to your email.
                        </Box>
                    </DialogContentText>
                    <DialogContent>
                        <TextField
                            autoFocus
                            variant="outlined"
                            margin="dense"
                            name="emailID"
                            label="Email ID"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                            style={{ width: '400px' }}
                            fullWidth
                        />
                    </DialogContent>
                </Box>

                <DialogActions >
                    <Button disabled={disabled} color='primary' onClick={() => submitForgotPassword()}>
                        Submit
                    </Button>
                </DialogActions>

            </Dialog>

        </>


    );
}

export default (Login)