import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { LoopCircleLoading } from 'react-loadingg';
import { withStyles, DialogContentText, DialogContent, TextField, DialogActions, Checkbox, ListItemIcon, Tabs, Tab, Box, Link } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { blue } from '@material-ui/core/colors';
import AddTender from './AddTender'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TenderSubProjects from './TenderSubProjects';
import Summary from './Summary';
// import Moment from 'react-moment';
import Alert from '@material-ui/lab/Alert';
import moment from "moment";
import { getCookie } from '../../Helper/CookieHelper';
import { json2excel } from 'js2excel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SendIcon from '@material-ui/icons/Send';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { errorMessage } from '../../Helper/ErrorMessage';
// import Moment from 'react-moment';
import ReactGA from 'react-ga';
import { getPrimaryColor, onlyUniqueArr } from '../../Helper/CommonThings';
import copy from 'copy-to-clipboard';
import ScrollToTop from 'react-scroll-to-top';
import { CsvBuilder } from 'filefy';
import GradeIcon from '@material-ui/icons/Grade';
import AddTenderRate from './AddTenderRate';
import HelpIcon from '@material-ui/icons/Help';
import ProjectRequestForm from '../../RequestForms/ProjectRequestForm';
import { getMode } from '../../MainDashBoard/ColorTheme/ColorTheme';
import AddIcon from '@material-ui/icons/Add';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import PIMLink from '../../PIM/PIMLink/PIMLink';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));
var subproj = [];
var down = []
// var userTenderer = []
// var dialogmsg = "";
// var submitdata = null;
class TenderProjects extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            data1: [],
            dataAll: [],
            rates: [],
            data2: [],
            open: false,
            isLoading: true,
            isLoading2: true,
            isLoading4: true,
            isLoadingDum: false,
            isLoading2Dum: false,
            isLoading4Dum: false,
            allData: 'null',
            openEdit: false,
            openCalc: false,
            key: 0,
            subproj1: [],
            openAddSub: false,
            updateNeed: false,
            openSend: false,
            openMore: false,
            openPIM: false,
            openTenderRate: false,
            moreOptData: {},
            usersData: [],
            approver: null,
            postComment: '',
            sendDSList: [],
            sendData: [],
            sendDeliSchData: [],
            actions: [],
            allEmEmails: "",
            tabSelect: 0,
            budchange: { 0: 'Budget', 1: 'Change', },
            wbstype: { 'ohl': 'OHL', 'cabling': 'Cabling', 'sub_struc': 'Substation Primary', 'ohl_civ_struc': 'Substation Secondary', 'sub_civ_struct': 'Substation Civ Structure', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical' },
            requestFormDialog: false,
            expand: false,
        }
        this.handleReload = this.handleReload.bind(this)
        this.pimRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ isLoading: true, isLoading2: true, isLoading4: true })
        this.fetchOps(this.state.tabSelect);
        this.setAccessIcon();
    }

    // isTenderer(id) {
    //     for (let i = 0; i < userTenderer.length; i++) {
    //         const tendererProject = userTenderer[i];
    //         if (tendererProject.opportunity_id === id) {
    //             console.log('done')
    //             return true
    //         }
    //     }
    //     return false
    // }

    fetchOps(all_project) {

        var resour = [];

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        var url = ''

        if ([1, 3].includes(all_project)) {
            url = `/bb/api/tender/project/estimates_lost_completed/`
        } else {
            url = `/bb/api/tender/project/estimates/`
        }

        // console.log(access.access.l6a && access.access.l6a.l6a_g, access.access.l6a && access.access.l6a.l6a_k)
        if (access.access.l6a && access.access.l6a.l6a_g && [2, 3].includes(all_project)) {
            url += `?filter_type=2`
        } else if (access.access.l6a && access.access.l6a.l6a_k && [0, 1].includes(all_project)) {
            url += `?filter_type=1`
        } else if ([0, 1].includes(all_project)) {
            url += `?filter_type=0`
        }

        if ([2, 3].includes(all_project)) {
            Service.get2(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    // console.log(res.data)

                    resour = res
                    if (all_project === this.state.tabSelect) {
                        this.fetchOps2(resour)
                    } else {
                        this.setState({ isLoadingDum: false, isLoading: false, isLoading2Dum: false, isLoading4Dum: false })
                    }
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            Service.get(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    // console.log(res.data)

                    resour = res
                    if (all_project === this.state.tabSelect) {
                        this.fetchOps2(resour)
                    } else {
                        this.setState({ isLoadingDum: false, isLoading: false, isLoading2Dum: false, isLoading4Dum: false })
                    }
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    fetchOps2(res) {
        var dataD = [];
        var dataD1 = [];
        var pid_ids = "0"
        var pids_arr = []
        var allEmEmails = []
        for (let i = 0; i < res.data.length; i++) {
            const element = res.data[i];

            if (!element.is_subproject && element.tenderbid_data && element.tenderbid_data.lead_dicipline !== 'trail') {

                if (element.tenderbid_data.pdm_data) {
                    allEmEmails.push(element.tenderbid_data.pdm_data.email)
                }
                // console.log(element)
                if (element.from_tenderbid) {
                    element.name = element.tender_name;
                    dataD.push(element)
                } else if (access && access.access && access.access.l6a && access.access.l6a.l6a_g) {
                    dataD.push(element)
                }

            } else if (!element.is_subproject && element.tenderbid_data && element.tenderbid_data.lead_dicipline === 'trail') {
                // console.log(element)
                if (element.from_tenderbid) {
                    element.name = element.tender_name;
                    dataD1.push(element)
                } else if ((access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
                    dataD1.push(element)
                }

            } else if (!element.is_subproject && (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
                // console.log(element)
                // if ((element.tenderbid_data && element.tenderbid_data.lead_dicipline !== 'edc')) {
                dataD1.push(element)
                // }

            }
            pid_ids = pid_ids + "," + element.id
            pids_arr.push(element.id)
            allEmEmails = allEmEmails.filter(onlyUniqueArr)
        }

        // console.log(dataD)
        this.setState({
            data: dataD,
            data1: dataD1,
            dataAll: res.data,
            isLoading: false,
            isLoadingDum: false,
            updateNeed: false,
            isLoading2Dum: false, isLoading4Dum: false,
            allEmEmails: allEmEmails.join(";"),
        });


        // Service.patch('/bb/api/tender/tasks/rolesnodata/get_data/', {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: pids_arr
        // })
        //     .then(res => {
        //         // console.log(res.data)
        //         this.setState({ data2: JSON.parse(JSON.stringify(res.data)), isLoading4: false, isLoading4Dum: false, });
        //     })
        //     .catch(error => {
        //         // console.log(error)
        //        errorMessage(this.props, error)
        //     });

        // Service.patch('/bb/api/tender/rate/get_data/', {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: pids_arr
        // })
        //     .then(res => {
        //         // console.log(res.data)
        //         if (res.data.length === 0) {
        //             this.props.enqueueSnackbar('Goto Rates tab to initiate default rates!', {
        //                 variant: 'warning'
        //             });
        //         } else {

        //             this.setState({
        //                 isLoading2: false,
        //                 isLoading2Dum: false,
        //                 rates: res.data,
        //             });
        //         }
        //     })
        //     .catch(error => {
        //        errorMessage(this.props, error)
        //     });

    }

    putOps(newData) {
        Service.put('/bb/api/tender/project/' + newData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: newData.id,
                name: newData.name,
                audit_status: newData.audit_status,
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Updated Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    setAccessIcon() {

        var actions = [];
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_d) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push(rowData => ({
                icon: 'add',
                tooltip: 'Add Deliverables Schedule',
                onClick: (event, rowData) => { this.setState({ openAddSub: true, key: 0, allData: 'null', subproj1: [rowData] }) },
                disabled: rowData.is_active
            }))
        }
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_e) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push(rowData => ({
                icon: 'book',
                tooltip: 'Project Book (Edit)',
                onClick: (event, rowData) => { this.setState({ allData: rowData, key: 0, open: true, openEdit: false }) },
                disabled: !(rowData.audit_status === 0 || rowData.audit_status === 4)
            }))
        }
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_h) || (access && access.access && access.access.l6a && access.access.l6a.l6a_i) || (access && access.access && access.access.l6a && access.access.l6a.l6a_j) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push(rowData => ({
                icon: 'more_vert',
                tooltip: 'More Options',
                onClick: (event, rowData) => { this.setState({ openMore: true, moreOptData: rowData, allData: rowData }); },

            }))
        }
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_c) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push({
                icon: () => <Button size='small' color='primary' variant="contained" startIcon={<AddIcon />}>
                    Add Tender
                </Button>,
                tooltip: 'Add Tender',
                isFreeAction: true,
                onClick: (event, rowData) => { this.setState({ open: true, key: 0, allData: 'null', subproj1: [] }) },

            })
        }
        actions.push({
            icon: () => <Button size='small' color='primary' variant="contained" startIcon={<HelpIcon />}>
                Setting up project
            </Button>,
            tooltip: 'Project Request',
            isFreeAction: true,
            onClick: (event, rowData) => { this.setState({ requestFormDialog: true }) },

        })
        // if ((access && access.access && access.access.l6a && access.access.l6a.l6a_h) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
        //     actions.push(rowData => ({
        //         icon: 'send',
        //         tooltip: 'Send for approval',
        //         onClick: (event, rowData) => { this.setState({ allData: rowData, approver: null, postComment: '' }); this.getUsers(); this.getDeliSch(rowData) },

        //     }))
        // }
        // if ((access && access.access && access.access.l6a && access.access.l6a.l6a_i) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
        //     actions.push(rowData => ({
        //         icon: 'assessment',
        //         tooltip: 'Mini Report',
        //         onClick: (event, rowData) => { window.open('/tender/mini/report/' + rowData.id) },

        //     }))
        // }
        // if ((access && access.access && access.access.l6a && access.access.l6a.l6a_j) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
        //     actions.push(rowData => ({
        //         icon: 'cloud_download',
        //         tooltip: 'Export as xlxs',
        //         onClick: (event, rowData) => { this.downloadXl(rowData) },
        //         disabled: rowData.is_active
        //     }))
        // }
        // console.log(actions)
        this.setState({ actions })
    }

    getUsers() {
        Service.get(`/bb/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {
                this.setState({ usersData: res1.data })

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    downloadXl(proj) {

        alert("This will take time depends on the tender size. Please wait until it finish if you don't see any error messages!")

        this.setState({ isLoading: true })
        down = []

        Service.get(`/bb/api/tender/project/?subproj=` + proj.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                subproj = res.data;

                var pids = "0"
                for (let i = 0; i < subproj.length; i++) {
                    const element = subproj[i];
                    pids += "," + element.id
                }
                Service.get('/bb/api/tender/tasks/rolesnodata/?pid=' + pids, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        // console.log(res.data)
                        this.setState({ data2: res.data }, () => this.downloadXl2(proj, 0));
                    })
                    .catch(error => {
                        // console.log(error)
                        errorMessage(this.props, error)
                    });
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    downloadXl2(proj, m) {
        // console.log(down)
        // console.log(m)
        // var downD = []

        const element5 = subproj[m];
        // console.log(element5)

        Service.get('/bb/api/tender/rate/?pid=' + element5.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {
                // console.log(res1.data)
                var rates = res1.data

                let down5 = {};
                // const element = wbs[i];
                console.log(this.subhourCalc(element5))
                if (parseFloat(this.subhourCalc(element5)) !== 0) {
                    down5["Deliverables Schedule ID"] = element5.id
                    down5["Budget/Change"] = this.state.budchange[element5.type]
                    down5["Deliverables Schedule Name"] = element5.name
                    down5["Deliverables Schedule From"] = element5.from_date
                    down5["Deliverables Schedule To"] = element5.to_date
                    down5["Deliverables Schedule Hours"] = this.subhourCalc(element5)
                    down5["Deliverables Schedule Amount"] = this.subcostCalc(element5, rates)

                    Service.get('/bb/api/tender/ohlwbs/?pid=' + element5.id, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res2 => {
                            var wbs = {}

                            wbs = res2.data;

                            Service.get('/bb/api/tender/wbs/tasks/?pid=' + element5.id, {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                            })
                                .then(res3 => {
                                    var tasks = {}
                                    tasks = res3.data

                                    Service.get('/bb/api/tender/tasks/roles/?pid=' + element5.id, {
                                        headers: {
                                            Authorization: "Token " + token,
                                        },
                                    })
                                        .then(res4 => {
                                            var roles = {}
                                            // console.log(res.data)
                                            roles = res4.data;

                                            for (let i = 0; i < wbs.length; i++) {
                                                let down2 = JSON.parse(JSON.stringify(down5))
                                                const element = wbs[i];


                                                if (parseFloat(this.hourCalc1(element)) !== 0) {
                                                    down2["WBS ID"] = element.id
                                                    down2["WBS"] = element.name
                                                    down2["WBS Type"] = this.state.wbstype[element.type]
                                                    down2["WBS Hours"] = this.hourCalc1(element)
                                                    down2["WBS Amount"] = this.costCalc1(element, rates)

                                                    for (let j = 0; j < tasks.length; j++) {
                                                        let down3 = JSON.parse(JSON.stringify(down2))
                                                        const element1 = tasks[j];
                                                        if (element.id === element1.t_ohlwbs && parseFloat(this.taskhourCalc(element1)) !== 0) {
                                                            down3["Task ID"] = element1.id
                                                            down3["Task"] = element1.name
                                                            down3["Task Description"] = element1.decs
                                                            down3["Task Hours"] = this.taskhourCalc(element1)
                                                            down3["Task Amount"] = this.taskcostCalc(element1, rates)
                                                            down3["Task From"] = element1.from_date
                                                            down3["Task TO"] = element1.to_date
                                                            down3["Task Remark"] = element1.remark


                                                            for (let k = 0; k < roles.length; k++) {
                                                                let down4 = JSON.parse(JSON.stringify(down3))

                                                                const element2 = roles[k];

                                                                if (element1.id === element2.t_tasks && (element2.hours) !== 0) {
                                                                    down4["Role"] = element2.name
                                                                    down4["Role Grade"] = element2.grade.toUpperCase()
                                                                    down4["Role Hours"] = element2.hours
                                                                    down4["Role Country"] = element2.country.toUpperCase()
                                                                    down4["Role Amount"] = this.rolescostCalc(element2, rates)
                                                                    down4["Role Remark"] = element2.remark

                                                                    down.push(down4)

                                                                }
                                                                // console.log(i + "  " + wbs.length + "  " + k + "  " + roles.length + "  " + j + "  " + tasks.length)
                                                                if (i === wbs.length - 1 && k === roles.length - 1 && j === tasks.length - 1) {
                                                                    if (m + 1 < subproj.length) {
                                                                        this.downloadXl2(proj, m + 1)
                                                                    } else {
                                                                        this.setState({ isLoading: false })
                                                                        // console.l/og(down)
                                                                        try {
                                                                            console.log(down)
                                                                            json2excel({
                                                                                data: down,
                                                                                name: proj.name.substring(0, 30),
                                                                                formateDate: 'dd/mm/yyyy'
                                                                            });
                                                                        } catch (e) {
                                                                            console.error('export error');
                                                                        }
                                                                    }

                                                                }

                                                            }

                                                        } else if (i === wbs.length - 1 && j === tasks.length - 1) {
                                                            if (m + 1 < subproj.length) {
                                                                this.downloadXl2(proj, m + 1)
                                                            } else {
                                                                this.setState({ isLoading: false })
                                                                // console.l/og(down)
                                                                try {
                                                                    console.log(down)
                                                                    json2excel({
                                                                        data: down,
                                                                        name: proj.name.substring(0, 30),
                                                                        formateDate: 'dd/mm/yyyy'
                                                                    });
                                                                } catch (e) {
                                                                    console.error('export error');
                                                                }
                                                            }

                                                        }

                                                    }
                                                } else if (i === wbs.length - 1) {
                                                    if (m + 1 < subproj.length) {
                                                        this.downloadXl2(proj, m + 1)
                                                    } else {
                                                        this.setState({ isLoading: false })
                                                        // console.l/og(down)
                                                        try {
                                                            console.log(down)
                                                            json2excel({
                                                                data: down,
                                                                name: proj.name.substring(0, 30),
                                                                formateDate: 'dd/mm/yyyy'
                                                            });
                                                        } catch (e) {
                                                            console.error('export error');
                                                        }
                                                    }

                                                }

                                            }
                                            // console.log(JSON.stringify(down))

                                        })
                                        .catch(error => {
                                            errorMessage(this.props, error)
                                        });
                                })
                                .catch(error => {
                                    errorMessage(this.props, error)
                                });
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });

                } else {
                    if (m + 1 < subproj.length) {
                        this.downloadXl2(proj, m + 1)
                    } else {
                        this.setState({ isLoading: false })
                        // console.log(do/wn)
                        var proj_name = ''
                        if (proj.from_tenderbid) {
                            proj_name = proj.tender_name;
                            // dataD.push(element)
                        } else if ((access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
                            proj_name = proj.name;
                            // dataD.push(element)
                        }
                        try {
                            console.log(down)
                            json2excel({
                                data: down,
                                name: proj_name.substring(0, 30),
                                formateDate: 'dd/mm/yyyy'
                            });
                        } catch (e) {
                            console.error('export error');
                        }
                    }
                }
                // console.log(res.data)

            })
            .catch(error => {
                errorMessage(this.props, error)
            });


    }

    subcostCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + this.subcostCalc2(element)
            }
        }
        return (cost);
    }

    subcostCalc2(data1) {
        for (let j = 0; j < this.state.rates.length; j++) {
            const element1 = this.state.rates[j];
            if (element1.t_pid === data1.t_pid) {
                if (data1.country === 'in' || data1.country === 'sl') {
                    for (let i = 0; i < this.state.rates[j].t_rates.length; i++) {
                        const element = this.state.rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                            return data1.hours * element.costin;
                        }
                    }
                } else {
                    for (let i = 0; i < this.state.rates[j].t_rates.length; i++) {
                        const element = this.state.rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            return data1.hours * element.costuk;
                        }
                    }
                }
                break;
            }
        }
        return 0;
    }

    subhourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    costCalc1(rowData, rates) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_wbs === rowData.id) {
                cost = cost + this.costCalc12(element, rates)
            }
        }
        return (cost);
    }

    costCalc12(data1, rates) {
        for (let j = 0; j < rates.length; j++) {
            const element1 = rates[j];
            if (element1.t_pid === data1.t_pid) {
                if (data1.country === 'in' || data1.country === 'sl') {
                    for (let i = 0; i < rates[j].t_rates.length; i++) {
                        const element = rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                            return data1.hours * element.costin;
                        }
                    }
                } else {
                    for (let i = 0; i < rates[j].t_rates.length; i++) {
                        const element = rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            return data1.hours * element.costuk;
                        }
                    }
                }
                break;
            }
        }
        return 0;
    }

    hourCalc1(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_wbs === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    taskcostCalc(rowData, rates) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_tasks === rowData.id) {
                cost = cost + this.taskcostCalc2(element, rates)
            }
        }
        return (cost);
    }

    taskcostCalc2(data1, rates) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < rates[0].t_rates.length; i++) {
                const element = rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < rates[0].t_rates.length; i++) {
                const element = rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    taskhourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_tasks === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    rolescostCalc(data1, rates) {
        for (let j = 0; j < rates.length; j++) {
            const element1 = rates[j];
            if (element1.t_pid === data1.t_pid) {
                if (data1.country === 'in' || data1.country === 'sl') {
                    for (let i = 0; i < rates[j].t_rates.length; i++) {
                        const element = rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                            return data1.hours * element.costin;
                        }
                    }
                } else {
                    for (let i = 0; i < rates[j].t_rates.length; i++) {
                        const element = rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            return data1.hours * element.costuk;
                        }
                    }
                }
                break;
            }
        }
        return 0;
    }


    // giveaccess() {

    //     var json = {
    //         email: submitdata.email,
    //         is_staff: true,
    //         is_lead: true,
    //         is_admin: !submitdata.is_admin,
    //     }

    //     Service.put("/bb/api/tender/project/" + submitdata.id + "/", {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //         data: json,
    //     })
    //         .then(res => {

    //             this.setState({ open: false, });
    //             // console.log(res.data)
    //             this.props.enqueueSnackbar('Permission changed for ' + submitdata.email, {
    //                 variant: 'success'
    //             });
    //             this.fetchOps();
    //         })
    //         .catch(error => {
    //             // console.log(error)
    //             this.props.enqueueSnackbar('Something went wrong!', {
    //                 variant: 'error'
    //             });
    //         });
    // }

    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        for (let i = 0; i < this.state.dataAll.length; i++) {
            const element = this.state.dataAll[i];
            // console.log(element.is_subproject +'   '+ element.subproject_pid +'   '+ rowData.id)
            if (element.is_subproject && element.subproject_pid === rowData.id) {
                for (let j = 0; j < this.state.data2.length; j++) {
                    const element1 = this.state.data2[j];
                    if (element1.t_pid === element.id) {
                        cost = cost + this.costCalc2(element1)
                    }
                }
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    costCalc2(data1) {
        for (let j = 0; j < this.state.rates.length; j++) {
            const element1 = this.state.rates[j];
            if (element1.t_pid === data1.t_pid) {
                if (data1.country === 'in' || data1.country === 'sl') {
                    for (let i = 0; i < this.state.rates[j].t_rates.length; i++) {
                        const element = this.state.rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                            return data1.hours * element.costin;
                        }
                    }
                } else {
                    for (let i = 0; i < this.state.rates[j].t_rates.length; i++) {
                        const element = this.state.rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            return data1.hours * element.costuk;
                        }
                    }
                }
                break;
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        for (let i = 0; i < this.state.dataAll.length; i++) {
            const element = this.state.dataAll[i];
            // console.log(element.is_subproject +'   '+ element.subproject_pid +'   '+ rowData.id)
            if (element.is_subproject && element.subproject_pid === rowData.id) {
                for (let j = 0; j < this.state.data2.length; j++) {
                    const element1 = this.state.data2[j];
                    if (element1.t_pid === element.id) {
                        cost = cost + parseFloat(element1.hours);
                    }
                }
            }
        }
        return cost.toFixed(2);
    }

    dateParse(date) {
        var formatDate = moment(date).format('DD/MM/YYYY');
        // console.log(formatDate)
        if (formatDate === 'Invalid date') {
            return 'NA';
        } else {
            return formatDate;
        }
    }

    onTagsChange = (event, values) => {
        // console.log(event.target.id.split("-")[0])
        this.setState({ [event.target.id.split("-")[0]]: values })
    }

    collectSendVersion(i) {
        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }
        var pid = this.state.sendDeliSchData[i]
        Service.get('/bb/api/tender/ohlwbs/?pid=' + pid.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                Service.get('/bb/api/tender/wbs/tasks/?pid=' + pid.id, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {

                        Service.get('/bb/api/tender/tasks/roles/?pid=' + pid.id, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res2 => {
                                Service.get('/bb/api/tender/rate/?pid=' + pid.id, {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },
                                })
                                    .then(res3 => {
                                        var toArr = {};
                                        toArr.project = pid;
                                        toArr.wbs = res.data;
                                        toArr.tasks = res1.data;
                                        toArr.roles = res2.data;
                                        toArr.rates = res3.data;
                                        var toArr1 = this.state.sendData;
                                        toArr1.push(toArr);
                                        this.setState({
                                            sendData: toArr1,
                                        });
                                        if (i === (this.state.sendDeliSchData.length - 1)) {
                                            this.requestApproval();
                                        } else {
                                            this.collectSendVersion(i + 1)
                                        }
                                        // console.log(toArr1)
                                        // this.state.sendData.push(toArr);
                                    })
                                    .catch(error => {
                                        this.setState({ isLoadingDum: false, isLoading: false, isLoading2Dum: false, isLoading4Dum: false })
                                        errorMessage(this.props, error)
                                    });

                            })
                            .catch(error => {
                                this.setState({ isLoadingDum: false, isLoading: false, isLoading2Dum: false, isLoading4Dum: false })
                                errorMessage(this.props, error)
                            });

                    })
                    .catch(error => {
                        this.setState({ isLoadingDum: false, isLoading: false, isLoading2Dum: false, isLoading4Dum: false })
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
                this.setState({ isLoadingDum: false, isLoading: false, isLoading2Dum: false, isLoading4Dum: false })
                errorMessage(this.props, error)
            });
    }

    requestApproval() {
        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        if (this.state.sendDeliSchData.length < 2) {
            this.props.enqueueSnackbar('Please select atleast one Deliverables Schedule!', {
                variant: 'warning'
            });
            this.setState({ isLoading: false, });
        } else if (this.state.postComment === '') {
            // console.log(this.state.postComment)
            this.props.enqueueSnackbar('Comment is empty!', {
                variant: 'warning'
            });
            this.setState({ isLoading: false, });
        } else {
            // console.log(this.state.allData)
            Service.post("/bb/api/tender/approval/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    tender_pid: this.state.allData.id,
                    upcometender_id: this.state.allData.from_tenderbid,
                    tender_name: this.state.allData.tender_name ? this.state.allData.tender_name : this.state.allData.name,
                    uname: user.last_name + ", " + user.first_name,
                    uemail: user.email,
                    request_to_name: this.state.approver.last_name + ", " + this.state.approver.first_name,
                    comment: this.state.postComment,
                    request_to: this.state.approver.email,
                    approve_by: this.state.approver.id,
                    domain: window.location.host,
                    staged_version: this.state.sendData,
                    projects: this.state.sendDeliSchData,
                    created_by: user.email,
                },
            })
                .then(res => {

                    this.props.enqueueSnackbar('Approval request sent!', {
                        variant: 'success'
                    });

                    this.changeShatus();

                })
                .catch(error => {
                    this.setState({ isLoadingDum: false, isLoading: false, isLoading2Dum: false, isLoading4Dum: false })
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }

    }

    deliSchShow() {
        // this.setState({ isLoadingDum: true, isLoading2Dum: true, isLoading4Dum: true })
        this.setState({
            openSend: false,
            sendData: [],
            sendDeliSchData: [],
            isLoadingDum: true, isLoading2Dum: true, isLoading4Dum: true,
        }, () => {
            var dataD = [];
            dataD.push(this.state.allData)
            if (dataD[0].audit_status === 0) {
                dataD[0].audit_status = 1
            } else if (dataD[0].audit_status === 4) {
                dataD[0].audit_status = 5
            }

            // this.collectSendVersion(this.state.allData)
            for (let i = 0; i < this.state.sendDSList.length; i++) {
                const element = this.state.sendDSList[i];
                if (element.checked) {
                    if (element.audit_status === 0) {
                        element.audit_status = 1
                    } else if (element.audit_status === 4) {
                        element.audit_status = 5
                    }

                    // this.collectSendVersion(element);
                    dataD.push(element)
                }
            }
            // console.log(dataD)
            this.setState({
                sendDeliSchData: dataD,
            }, () => {
                this.collectSendVersion(0);
            });
        })

    }

    handleReload() {
        this.setState({
            requestFormDialog: false
        })
    }

    getDeliSch(data12) {
        Service.get(`/bb/api/tender/project/?subproj=` + data12.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var toData = [];
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.audit_status === 0 || element.audit_status === 4) {
                        element.checked = true;
                        toData.push(element);
                    }
                }
                // console.log(toData)
                this.setState({ sendDSList: toData, openSend: true, });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    changeShatus() {

        var pageView = window.location.pathname + "/#TenderHome";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.patch("/bb/api/tender/project/bulk_update/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: this.state.sendDeliSchData,
        })
            .then(res => {
                Service.put('/bb/api/tender/bid/' + this.state.sendDeliSchData[0].from_tenderbid, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: { status: this.state.sendDeliSchData[0].audit_status },
                })
                    .then(res1 => {

                        this.setState({ allData: res.data, isLoading: false, isLoadingDum: true, isLoading2Dum: true, isLoading4Dum: true })

                        this.fetchOps(this.state.tabSelect)
                    })
                    .catch(error => {
                        this.setState({ isLoadingDum: true, isLoading: false, isLoading2Dum: true, isLoading4Dum: true })
                        this.fetchOps(this.state.tabSelect)
                        this.setState({ isLoading: false })
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {

                this.setState({ isLoadingDum: true, isLoading: false, isLoading2Dum: true, isLoading4Dum: true })
                this.fetchOps(this.state.tabSelect)
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    setExpand() {
        this.setState({ expand: !this.state.expand }, () => {
            if (this.pimRef && this.pimRef.current) {
                this.pimRef.current.expandAll(this.state.expand)
            }
        })
    }

    render() {
        const { classes } = this.props;
        const handleClose = () => {
            this.setState({ isLoadingDum: true, isLoading: false, isLoading2Dum: true, isLoading4Dum: true })
            this.setState({ open: false, });
            this.fetchOps(this.state.tabSelect);
        };

        const handleTabChange = (event, newValue) => {
            this.fetchOps(newValue)
            this.setState({
                isLoading: true,
                tabSelect: newValue,

            })
        };

        const handleSubClose = () => {
            this.setState({ isLoadingDum: true, isLoading: false, isLoading2Dum: true, isLoading4Dum: true })
            this.setState({ openAddSub: false, });
            this.fetchOps(this.state.tabSelect);
        };

        const handleClose1 = () => {
            this.setState({ openEdit: false, });
        };

        const handleClose2 = () => {
            this.setState({ openCalc: false, });
        };

        const handleClose5 = () => {
            this.setState({ openMore: false, });
        };

        const handleSendClose = () => {
            this.setState({ openSend: false })
        };

        const handleTenderRateClose = () => {
            this.setState({ openTenderRate: false, openMore: false })
        };

        const handlePIMClose = () => {
            this.setState({ openPIM: false, openMore: false })
        };

        // const handleRequestForm = () => {
        //     this.setState({ requestFormDialog: true })
        // }

        const handleComm = (e) => {
            this.setState({ postComment: e.target.value })
        };

        const handleCheck = (e, i) => {
            this.setState(prevState => {
                const sendDSList = [...prevState.sendDSList];
                sendDSList[i].checked = !sendDSList[i].checked;
                return { ...prevState, sendDSList };
            });
        };

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false && !columnDef.nonExport;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    var curr_data = rowData
                    var keys = columnDef.field.split(".")
                    if (columnDef.customExport === 'in_key') {

                        for (let i = 0; i < keys.length; i++) {
                            const element = keys[i];
                            curr_data = curr_data[element]
                        }
                        return curr_data
                    } else if (columnDef.customExport === 'in_key_lookup') {
                        for (let i = 0; i < keys.length; i++) {
                            const element = keys[i];
                            curr_data = curr_data[element]
                        }
                        return columnDef.lookup[curr_data]
                    } else if (columnDef.customExport === 'hold') {
                        return rowData.task_hold ? "Hold" : "Unhold"
                    } else if (columnDef.customExport === 'billable') {
                        return rowData.inc_evp ? "Billable" : "Not Billable"
                    }
                    return columnDef.render ? columnDef.render(rowData) : columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field];
                })
            );

            const builder = new CsvBuilder("estimates-" + moment().format('DD-MM-YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };

        return (
            <div>
                <ScrollToTop smooth />

                <AppBar position="static" color="default" ref={this.tabRef}>
                    <Tabs
                        value={this.state.tabSelect}
                        onChange={handleTabChange}
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="tabs"
                    >
                        <Tab label="My Estimates" />
                        <Tab label="Completed" />
                        {(access.access && access.access.l6a && access.access.l6a.l6a_g) ? (
                            <Tab label="All Estimates" />
                        ) : (
                            null
                        )}
                        {(access.access && access.access.l6a && access.access.l6a.l6a_g) ? (
                            <Tab label="All Completed" />
                        ) : (
                            null
                        )}
                    </Tabs>

                </AppBar>

                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {/* {((access && access.access && access.access.l6a && access.access.l6a.l6a_c) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) ? (
                            <Box display="flex" flexDirection="row-reverse" p={1} m={1} >
                                <Button size='small' variant="contained" color="secondary" onClick={e => { this.setState({ open: true, key: 0, allData: 'null', subproj1: [] }) }} style={{ marginBottom: '10px' }}>Add Tender</Button>
                            </Box>
                        ) : (null)} */}

                        {(this.state.updateNeed) ? (
                            <Alert severity="warning"
                                action={
                                    <Button color="inherit" size="small" onClick={() => { this.setState({ isLoadingDum: true, isLoading: false, isLoading2Dum: true, isLoading4Dum: true }); this.fetchOps(this.state.tabSelect) }}>
                                        Refresh
                                    </Button>
                                }
                            >
                                To see updated data in main tender, please click "Refresh" on right corner.
                            </Alert>
                        ) : (
                            null
                        )}

                        <MaterialTable
                            title=""
                            columns={[
                                {
                                    title: 'id', field: 'id', editable: 'never',
                                },
                                {
                                    title: 'Estimate Name', field: 'tender_name', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, editable: 'never',
                                    render: rowData => (rowData.tender_name) ? (rowData.tender_name) : rowData.name
                                }, {
                                    title: 'Created Date', field: 'date_created', editable: 'never',
                                    render: rowData => this.dateParse(rowData.date_created)
                                },
                                {
                                    title: 'Lead Discipline', field: 'tenderbid_data.lead_dicipline', editable: 'never', customExport: 'in_key_lookup',
                                    lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }
                                },
                                {
                                    title: 'HoE', field: 'tenderbid_data.region_hoe.first_name', editable: 'never',
                                    headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                                    render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.region_hoe) ? (rowData.tenderbid_data.region_hoe.last_name + ", " + rowData.tenderbid_data.region_hoe.first_name) : (null),
                                    // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                },
                                {
                                    title: 'Start Date', field: 'from_date', editable: 'never',
                                    render: rowData => this.dateParse(rowData.from_date)
                                },
                                {
                                    title: 'Finish Date', field: 'to_date', editable: 'never',
                                    render: rowData => this.dateParse(rowData.to_date)
                                },
                                {
                                    title: 'Hours', field: 'budget_hours', editable: 'never',
                                    render: rowData => rowData.budget_hours
                                },
                                {
                                    title: 'Amount', field: 'budget_cost', editable: 'never',
                                    render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.budget_cost)
                                },
                                {
                                    title: 'Stage', field: 'audit_status', editable: (access && access.access && access.access.l6a && access.access.l6a.l6a_m) ? ('onUpdate') : ('never'),
                                    lookup: {
                                        0: 'Stage 2 - Tender Development', 1: 'Stage 2 - Awaiting Approval', 2: 'Stage 2 - Submission Approved', 3: 'Stage 3 - Tender Submitted', 4: 'Stage 3a - Tender Repricing', 5: 'Stage 3a - Awaiting Approval',
                                        6: 'Stage 3a - Approved', 7: 'Stage 3a - Re-Submission', 8: 'Stage 4 - Awarded', 9: 'Stage 4 - Lost', 10: 'Stage 5 - Mobilising', 13: 'Stage 6a - Design Completed', 11: 'Stage 1a - Tender Mobilising', 12: 'Stage 1b - Tender Mobilised', 100: ''
                                    },
                                },
                                // {
                                //     title: 'Date Created', field: 'date_created', editable: 'never',
                                //     render: rowData => <Moment local format="DD/MM/YYYY">{rowData.date_created}</Moment>
                                // },
                            ]}
                            data={this.state.data}

                            actions={this.state.actions
                                // rowData => ({
                                //     icon: 'functions',
                                //     tooltip: 'Resource Allocation',
                                //     onClick: (event, rowData) => { this.setState({ allData: rowData, openCalc: true, }) },
                                //     disabled: rowData.audit_status !== 10
                                // }),

                                // rowData => ({
                                //     icon: 'check_circle',
                                //     tooltip: 'Activate',
                                //     // onClick: (event, rowData) => this.handleDialog(true, rowData),
                                //     disabled: rowData.is_active
                                // }),
                                // rowData => ({
                                //     icon: 'cancel',
                                //     tooltip: 'Inactivate',
                                //     // onClick: (event, rowData) => this.handleDialog(false, rowData),
                                //     disabled: !rowData.is_active
                                // })
                            }
                            options={{
                                padding: "dense",
                                paging: false,
                                // filtering: true,
                                actionsColumnIndex: 6,
                                exportCsv: (columns, data) => {
                                    exportCsv(columns, data)
                                },
                                exportButton: { csv: true },
                            }}
                            editable={
                                ((access && access.access && access.access.l6a && access.access.l6a.l6a_m)) ? (
                                    {

                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : (null)}
                            detailPanel={((access && access.access && access.access.l6a && access.access.l6a.l6a_b) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) ? (
                                rowData => {
                                    return (
                                        <div style={{ width: '100%' }}>
                                            <TenderSubProjects value={rowData} onReload={() => this.setState({ updateNeed: true })} projs={this.state.dataAll} roles={this.state.data2} rates={this.state.rates} />

                                        </div>
                                    )
                                }
                            ) : (null)}
                        />
                        {/* {(access && access.access && access.access.l6a && access.access.l6a.l6a_k) ? (
                            <MaterialTable
                                title="Sandpit"
                                style={{ marginTop: '15px' }}
                                columns={[
                                    {
                                        title: 'Tender Name', field: 'name', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 },
                                        render: rowData => (rowData.tender_name) ? (rowData.tender_name) : rowData.name
                                    },
                                    {
                                        title: 'From', field: 'from_date',
                                        render: rowData => this.dateParse(rowData.from_date)
                                    },
                                    {
                                        title: 'To', field: 'to_date',
                                        render: rowData => this.dateParse(rowData.to_date)
                                    },
                                    {
                                        title: 'Hours', field: 'budget_hours', editable: 'never',
                                        render: rowData => rowData.budget_hours
                                    },
                                    {
                                        title: 'Amount', field: 'budget_cost', editable: 'never',
                                        render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.budget_cost)
                                    },
                                    {
                                        title: 'Gate Stage', field: 'audit_status', editable: 'never',
                                        lookup: {
                                            0: 'Stage 2 - Tender Development', 1: 'Stage 2 - Awaiting Approval', 2: 'Stage 2 - Submission Approved', 3: 'Stage 3 - Tender Submitted', 4: 'Stage 3a - Tender Repricing', 5: 'Stage 3a - Awaiting Approval',
                                            6: 'Stage 3a - Approved', 7: 'Stage 3a - Re-Submission', 8: 'Stage 4 - Awarded', 9: 'Stage 4 - Lost', 10: 'Stage 5 - Mobilising', 13: 'Stage 6a - Design Completed', 100: ''
                                        },
                                    },

                                ]}
                                data={this.state.data1}
                                actions={[
                                    // rowData => ({
                                    //     icon: 'functions',
                                    //     tooltip: 'Resource Allocation',
                                    //     onClick: (event, rowData) => { this.setState({ allData: rowData, openCalc: true, }) },
                                    //     disabled: rowData.audit_status !== 10
                                    // }),
                                    rowData => ({
                                        icon: 'add',
                                        tooltip: 'Add Deliverables Schedule',
                                        onClick: (event, rowData) => { this.setState({ openAddSub: true, key: 0, allData: 'null', subproj1: [rowData] }) },
                                        disabled: rowData.is_active
                                    }),
                                    rowData => ({
                                        icon: 'book',
                                        tooltip: 'Project Book',
                                        onClick: (event, rowData) => { this.setState({ allData: rowData, key: 0, open: true, openEdit: false }) },
                                        disabled: rowData.is_active
                                    }),
                                    rowData => ({
                                        icon: 'cloud_download',
                                        tooltip: 'Export as xlxs',
                                        onClick: (event, rowData) => { this.downloadXl(rowData) },
                                        disabled: rowData.is_active
                                    }),
                                    // rowData => ({
                                    //     icon: 'check_circle',
                                    //     tooltip: 'Activate',
                                    //     // onClick: (event, rowData) => this.handleDialog(true, rowData),
                                    //     disabled: rowData.is_active
                                    // }),
                                    // rowData => ({
                                    //     icon: 'cancel',
                                    //     tooltip: 'Inactivate',
                                    //     // onClick: (event, rowData) => this.handleDialog(false, rowData),
                                    //     disabled: !rowData.is_active
                                    // })
                                ]}
                                options={{
                                    padding: "dense",
                                    // filtering: true,
                                    actionsColumnIndex: 6
                                }}
                                detailPanel={rowData => {
                                    return (
                                        <TenderSubProjects value={rowData} onReload={() => this.setState({ updateNeed: true })} projs={this.state.dataAll} roles={this.state.data2} rates={this.state.rates} />
                                    )
                                }}

                            />
                        ) : (null)} */}

                        {(access.access.l6a && access.access.l6a.l6a_g) ? (<Button onClick={() => {
                            copy(this.state.allEmEmails)
                            this.props.enqueueSnackbar('Emails copied to clipboard!', {
                                variant: 'success'
                            });
                        }}>Copy all EM Email ID's</Button>) : (null)}



                        <Dialog fullScreen open={this.state.openAddSub} onClose={handleSubClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleSubClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        {(this.state.allData === 'null' && this.state.subproj1.length === 1) ? (
                                            "Add Deliverables Schedule"
                                        ) : (
                                            ''
                                        )}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <AddTender page={this.state.key} onprojedit={false} tender={this.state.subproj1[0]} value={this.state.allData} subproj={this.state.subproj1} />
                        </Dialog>

                        <Dialog fullScreen open={this.state.open} onClose={handleClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar} >
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        {(this.state.allData === 'null' && this.state.subproj1.length === 0) ? (
                                            "Add Tender"
                                        ) : (
                                            this.state.allData.name
                                        )}
                                    </Typography>
                                </Toolbar>
                            </AppBar>

                            <AddTender page={this.state.key} onprojedit={true} value={this.state.allData} subproj={this.state.subproj1} />
                        </Dialog>
                        <Dialog onClose={handleClose1} aria-labelledby="simple-dialog-title" open={this.state.openEdit}>
                            <DialogTitle id="simple-dialog-title">Choose your edit</DialogTitle>
                            <List>
                                <ListItem autoFocus button onClick={() => this.setState({ key: 0, open: true, openEdit: false })}>

                                    <ListItemText primary="Project Edit" />

                                </ListItem>
                                {/* <ListItem autoFocus button onClick={() => this.setState({ key: 1, open: true, openEdit: false })}>

                                        <ListItemText primary="Rates" />

                                    </ListItem>
                                   
                                    <ListItem autoFocus button onClick={() => this.setState({ key: 2, open: true, openEdit: false })}>

                                        <ListItemText primary="Deliverables Package" />

                                    </ListItem> */}
                            </List>
                        </Dialog>
                        <Dialog fullScreen open={this.state.openCalc} onClose={handleClose2} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose2} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Summary for {this.state.allData.name}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Summary value={this.state.allData} />
                        </Dialog>

                        <Dialog open={this.state.openSend} onClose={handleSendClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Send for approval</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Select Deliverable Schedule that should go for approval
                                </DialogContentText>
                                <List className={classes.root}>
                                    {this.state.sendDSList.map((list, index) => {
                                        const labelId = `checkbox-list-label-${list.id}`;

                                        return (
                                            <ListItem key={list.id} role={undefined} dense button onClick={() => handleCheck(list, index)} >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        key={list.id}
                                                        edge="start"
                                                        checked={list.checked}
                                                        tabIndex={-1}
                                                        color="primary"
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={list.name} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                                <Autocomplete
                                    id="approver"
                                    options={this.state.usersData}
                                    getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                    style={{ width: '100%' }}
                                    onChange={this.onTagsChange}
                                    renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                />
                                <TextField id="outlined-basic3" label="Comment" variant="outlined"
                                    value={this.state.postComment}
                                    onChange={handleComm}
                                    fullWidth
                                    style={{ marginTop: '15px' }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button size='small' onClick={handleSendClose} color="primary">
                                    Cancel
                                </Button>
                                <Button size='small' onClick={e => this.deliSchShow()} color="primary">
                                    Submit Request
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog onClose={handleClose5} aria-labelledby="simple-dialog-title" open={this.state.openMore}>
                            <DialogTitle id="simple-dialog-title">{this.state.moreOptData.tender_name ? this.state.moreOptData.tender_name : this.state.moreOptData.name}</DialogTitle>
                            <List>
                                <ListItem button onClick={() => { this.setState({ openTenderRate: true }) }}>
                                    <ListItemIcon>
                                        <GradeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Custom Rate Card" />
                                </ListItem>
                                {((access && access.access && access.access.l6a && access.access.l6a.l6a_h) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) ? (
                                    <ListItem button onClick={() => { this.setState({ openMore: false, allData: this.state.moreOptData, approver: null, postComment: '' }); this.getUsers(); this.getDeliSch(this.state.moreOptData) }}>
                                        <ListItemIcon>
                                            <SendIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Send for Approval" />
                                    </ListItem>
                                ) : (
                                    null
                                )}
                                {((access && access.access && access.access.l6a && access.access.l6a.l6a_i) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) ? (
                                    <ListItem button onClick={() => { this.setState({ openMore: false, }); window.open('/tender/mini/report/' + this.state.moreOptData.id) }}>
                                        <ListItemIcon>
                                            <AssessmentIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Mini Report" />
                                    </ListItem>
                                ) : (null)}

                                {((access && access.access && access.access.l6a && access.access.l6a.l6a_j) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) ? (
                                    <ListItem button onClick={() => { this.setState({ openMore: false, }); this.downloadXl(this.state.moreOptData) }}>
                                        <ListItemIcon>
                                            <CloudDownloadIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Export as xlxs" />
                                    </ListItem>
                                ) : (null)}

                                {((access && access.access && access.access.l6a && access.access.l6a.l6a_u) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) ? (
                                    <ListItem button onClick={() => { this.setState({ openPIM: true, openMore: false }) }}>
                                        <ListItemIcon>
                                            <IntegrationInstructionsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="PIM" />
                                    </ListItem>
                                ) : (null)}

                                <ListItem button onClick={() => { this.setState({ openMore: false }) }}>
                                    <ListItemIcon>
                                        <CloseIcon color="error" />
                                    </ListItemIcon>
                                    <ListItemText primary="Close" />
                                </ListItem>
                            </List>
                        </Dialog>

                        <Dialog fullScreen open={this.state.openTenderRate} onClose={handleTenderRateClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleTenderRateClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Tender Rate Card for {this.state.allData.name}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <AddTenderRate tender={this.state.allData} />
                        </Dialog>

                        <Dialog open={this.state.requestFormDialog} onClose={() => { this.setState({ requestFormDialog: false }) }} TransitionComponent={Transition}>
                            <DialogTitle><Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                <Box flexGrow={1}>
                                    <Typography variant='h5'>Project Request</Typography>
                                </Box>
                                <Box>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            window.open('https://faqs.bbparam.com/paramfaqs/paramforms')
                                        }}
                                        color={getMode() ? 'secondary' : 'primary'}
                                    >
                                        FAQ's
                                    </Link>
                                </Box>
                            </Box></DialogTitle>
                            <DialogContent>
                                <ProjectRequestForm reload={this.handleReload} reloadProp={true} />
                            </DialogContent>
                            {/* <DialogActions>
                                <Button size='small' onClick={handleRequestForm} color='primary'>
                                    Close
                                </Button>
                                <Button  size='small' color='primary'>
                                    Submit
                                </Button>
                            </DialogActions> */}
                        </Dialog>

                        <Dialog fullScreen open={this.state.openPIM} onClose={handlePIMClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                                        <Box display={'flex'} flexDirection={'row'} flexGrow={1} alignItems={'center'}>
                                            <IconButton edge="start" color="inherit" onClick={handlePIMClose} aria-label="close">
                                                <CloseIcon />
                                            </IconButton>
                                            <Typography variant="h6" className={classes.title}>
                                                PIM Integration for {this.state.allData.name}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Button
                                                variant='outlined'
                                                onClick={() => this.setExpand()}
                                                size='small'
                                                color='inherit'
                                                endIcon={this.state.expand === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                                style={{ marginLeft: '20px', marginRight: '20px' }}>
                                                {this.state.expand === false ? 'Expand All' : 'Collapse All'}
                                            </Button>
                                        </Box>

                                    </Box>
                                </Toolbar>
                            </AppBar>
                            <PIMLink ref={this.pimRef} projectData={this.state.allData} />
                        </Dialog>

                    </div>
                )}
                {(this.state.isLoadingDum || this.state.isLoading2Dum || this.state.isLoading4Dum) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (null)}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(TenderProjects));