import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from "../../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import TenderDetail from './TenderDetail';
import { Box } from '@material-ui/core';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class Tenders extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            data1: [],
            columns: [],
            isLoading: true,
            isLoadin2: true,
            clients: [],
            clients1: [],
            regions: [],
        }
    }

    componentDidMount() {

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        this.fetchOps();
    }

    fetchOps() {

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        Service.get('/bb/api/tender/bid/unsecured_workbook/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data12 = []
                var data21 = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.lead_dicipline !== 'trail') {
                        if ((element.lead_dicipline === 'ohl' || element.lead_dicipline === 'cabling' || element.lead_dicipline === 'substation' || element.lead_dicipline === 'multi_utilities' || element.lead_dicipline === 'technical') && (access && access.access.l7a && access.access.l7a.l7a_d)) {
                            data12.push(element);
                        } else if ((element.lead_dicipline === 'edc') && (access && access.access.l7a && access.access.l7a.l7a_e)) {
                            data12.push(element);
                        } else if ((access && access.access.l7a && access.access.l7a.l7a_c)) {
                            data12.push(element);
                        }
                    } else if (element.lead_dicipline === 'trail') {
                        data21.push(element);
                    }
                }

                this.setState({ isLoading2: false, data: JSON.parse(JSON.stringify(data12)), data1: JSON.parse(JSON.stringify(data21)) }, () => {
                    Service.get('/bb/api/clients/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res => {

                            var cli = (res.data);
                            var clients = {};
                            var clients1 = {};
                            for (let i = 0; i < cli.length; i++) {
                                const element = cli[i];
                                if (element.client_section === 'trail') {
                                    clients1[element.id] = element.name;
                                } else {
                                    clients[element.id] = element.name;
                                }

                            }
                            // console.log(clients)
                            this.setState({ isLoading: false, clients: clients, clients1: clients1, })
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });
                    Service.get('/bb/api/region/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res => {

                            var cli = (res.data);
                            var clients = {};

                            for (let i = 0; i < cli.length; i++) {
                                const element = cli[i];
                                clients[element.id] = element.name;

                            }
                            // console.log(clients)
                            this.setState({ isLoading: false, regions: clients, })
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postOps(data) {

        var pageView = window.location.pathname + "/#Unsecured Work";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }
        // console.log(data.client)
        // console.log(data.opportunity === undefined)
        if (data.opportunity === undefined || data.opportunity === '' || data.client === undefined) {
            if (data.opportunity === undefined || data.opportunity === '') {
                setTimeout(() => {
                    this.setState({ isLoading: false, isLoading2: true })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Opportunity field is empty!', {
                        variant: 'error'
                    });
                }, 200)
            } else {
                setTimeout(() => {
                    this.setState({ isLoading: false, isLoading2: true })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Client field is empty!', {
                        variant: 'error'
                    });
                }, 200)
            }


        } else {

            if (data.client === 0) {
                delete data['client']
            }

            Service.post('/bb/api/tender/bid/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.setState({ isLoading: false, isLoading2: true })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Tender added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    putOps(data) {

        var pageView = window.location.pathname + "/#Unsecured Work";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        if (data.opportunity === undefined || data.opportunity === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Opportunity field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else {
            Service.put('/bb/api/tender/bid/' + data.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {

                    this.props.enqueueSnackbar('Tender updated successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: true, })
                    errorMessage(this.props, error)
                    this.fetchOps();
                });
        }
    }

    render() {
        return (
            <div>
                {(this.state.isLoading || this.state.isLoading2) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            key={3}
                            title="Unsecured workbook"
                            columns={[
                                { title: 'CRM #', field: 'crm', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },

                                { title: 'Project', field: 'opportunity', headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 }, },
                                {
                                    title: 'Client', field: 'client',
                                    lookup: this.state.clients, headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                },
                                {
                                    title: 'Region', field: 'region',
                                    lookup: this.state.regions, headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                },
                                // { title: 'Bit lead', field: 'bit_load', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                                // { title: 'Lead Engineer', field: 'lead_eng', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                                // { title: 'Contract Value (£m)', field: 'value', type: 'numeric', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                // { title: 'ITT Start', field: 'itt_start', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                // { title: 'Gate 3', field: 'gate_3', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                // { title: 'Tender Submission', field: 'submission', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                // { title: 'Contract Award', field: 'contract_award', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                // { title: 'Contract Completion', field: 'contract_completion', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                // { title: 'Tender Weeks', field: 'weeks', type: 'numeric', headerStyle: { minWidth: 100 }, cellStyle: { minWidth: 100 }, },
                                {
                                    title: 'Lead Discipline', field: 'lead_dicipline',
                                    headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                    lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }
                                },
                                {
                                    title: 'Go/No Go', field: 'stage',
                                    lookup: {
                                        '0': 'Prospect', '1': 'Go', '3': 'No Go',
                                    },
                                    headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                },
                                {
                                    title: 'Stage', field: 'status', editable: 'never',
                                    lookup: {
                                        0: 'Gate 2 - In Development', 1: 'Gate 2 - Awaiting Approval', 2: 'Gate 2 - Approved', 3: 'Gate 3 - Submitted', 4: 'Gate 3a - Re-Pricing', 5: 'Gate 3a - Awaiting Approval',
                                        6: 'Gate 3a - Approved', 7: 'Gate 3a - Submitted', 8: 'Gate 4 - Awarded', 9: 'Gate 4 - Lost', 10: 'Gate 5 - Mobilised', 100: ''
                                    }, headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                },
                            ]}
                            data={this.state.data}
                            detailPanel={rowData => {
                                return (
                                    <Box m={1} p={1}>
                                        <TenderDetail value={rowData} />
                                    </Box>
                                )
                            }}
                            icons={{
                                Add: props => <Button color='primary' size='small' variant="contained" startIcon={<AddIcon />}>
                                    Add project
                                </Button>
                            }}
                            options={{
                                padding: "dense",
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50],
                                addRowPosition: 'first',
                            }}
                            editable={
                                ((access && access.access && !access.access.l7a.l7a_b) && (access && access.access && access.access.l7a.l7a_f)) ? (
                                    {
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : ((access && access.access && access.access.l7a.l7a_b) && (access && access.access && !access.access.l7a.l7a_f)) ? (
                                    {
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        newData.created_by = user.email;
                                                        this.postOps(newData);
                                                        data.push(newData);
                                                        // this.postData(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                    }
                                ) : ((access && access.access && access.access.l7a.l7a_b) && (access && access.access && access.access.l7a.l7a_f)) ? (
                                    {
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        newData.created_by = user.email;
                                                        this.postOps(newData);
                                                        data.push(newData);
                                                        // this.postData(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : (null)}
                        />
                        {/* {(access && access.access && access.access.l7a.l7a_g) ? (
                            <MaterialTable
                                key={4}
                                style={{ marginTop: '15px' }}
                                title="Sandpit"
                                columns={[
                                    { title: 'CRM #', field: 'crm', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                    {
                                        title: 'Gate Stage', field: 'status', editable: 'never',
                                        lookup: {
                                            0: 'Stage 2 - Tender Development', 1: 'Stage 2 - Awaiting Approval', 2: 'Stage 2 - Submission Approved', 3: 'Stage 3 - Tender Submitted', 4: 'Stage 3a - Tender Repricing', 5: 'Stage 3a - Awaiting Approval',
                                            6: 'Stage 3a - Approved', 7: 'Stage 3a - Re-Submission', 8: 'Stage 4 - Awarded', 9: 'Stage 4 - Lost', 10: 'Stage 5 - Mobilising', 11: 'Stage 1a - Tender Mobilising', 12: 'Stage 1b - Tender Mobilised', 13: 'Stage 6a - Design Completed', 100: ''
                                        }, headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                    },
                                    { title: 'Opportunity', field: 'opportunity', headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 }, },
                                    {
                                        title: 'Client', field: 'client',
                                        lookup: this.state.clients1, headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                    },
                                    // { title: 'Bit lead', field: 'bit_load', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                                    // { title: 'Lead Engineer', field: 'lead_eng', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                                    // { title: 'Contract Value (£m)', field: 'value', type: 'numeric', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                    // { title: 'ITT Start', field: 'itt_start', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                    // { title: 'Gate 3', field: 'gate_3', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                    // { title: 'Tender Submission', field: 'submission', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                    // { title: 'Contract Award', field: 'contract_award', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                    // { title: 'Contract Completion', field: 'contract_completion', type: 'date', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, },
                                    // { title: 'Tender Weeks', field: 'weeks', type: 'numeric', headerStyle: { minWidth: 100 }, cellStyle: { minWidth: 100 }, },
                                    {
                                        title: 'Lead Discipline', field: 'lead_dicipline',
                                        headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                        lookup: { 'trail': 'Demo', }
                                    },
                                    // {
                                    //     title: 'Dicipline', field: 'edc_dicipline',
                                    //     headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                    //     lookup: { 'mep': 'MEP', 'bim': 'BIM', 'dig': 'DIG', 'pln': 'PLN', 'csd': 'CSD', 'gis': 'GIS' }
                                    // },
                                    {
                                        title: 'Gate Stage', field: 'stage',
                                        lookup: {
                                            '0': 'Prospect', '1': 'Go', '3': 'No Go',
                                        },
                                        headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                    },
                                ]}
                                options={{
                                    padding: "dense",
                                }}
                                data={this.state.data1}
                                detailPanel={rowData => {
                                    return (
                                        <Box m={1} p={1}>
                                            <TenderDetail value={rowData} />
                                        </Box>
                                    )
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise(resolve => {

                                            setTimeout(() => {
                                                resolve();
                                                this.setState(prevState => {
                                                    const data = [...prevState.data1];
                                                    newData.created_by = user.email;
                                                    newData.lead_dicipline = 'trail'
                                                    newData.client = 0;
                                                    this.postOps(newData);
                                                    data.push(newData);
                                                    // this.postData(newData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data1 = [...prevState.data1];
                                                        // console.log(data)
                                                        data1[data1.indexOf(oldData)] = newData;
                                                        this.putOps(newData)
                                                        return { ...prevState, data1 };
                                                    });
                                                }
                                            }, 600);
                                        }),
                                }}
                            />
                        ) : (null)} */}

                    </div>
                )}
            </div>
        )
    }
}

export default withSnackbar(Tenders);