import React, { Component } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import './Gantt.css';
import moment from 'moment'
import Drawer from '@material-ui/core/Drawer';
import RoleVsResourceToggle from '../../TeamTaskAllocation/RoleVsResourceToggle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default class TeamGantt extends Component {

    constructor() {
        super()
        this.state = {
            modal: false,
            selectedValue: 'day',
            switchdate: false,
            menu: false,
            selectedTask: {}
        }

    }

    // showDate(day) {
    //     var date = new Date(2021, 03, day)
    //     var position = gantt.posFromDate(date)
    //     gantt.scrollTo(position)
    // }

    componentWillUnmount() {
        gantt.clearAll();
    }

    componentDidMount() {

        const { tasks } = this.props;
        // console.log(tasks, 'Gantt mount')
        gantt.config.drag_move = false;
        gantt.config.readonly = true;
        gantt.plugins({ tooltip: true, marker: true })

        // var dateToStr = gantt.date.date_to_str(gantt.config.task_date);
        // var markerId = gantt.addMarker({
        //     start_date: new Date(), //a Date object that sets the marker's date
        //     css: "today", //a CSS class applied to the marker
        //     text: "Today", //the marker title
        //     title: dateToStr(new Date()) // the marker's tooltip
        // })

        // console.log(this.props.baselineDates, 'baseline date flag')
        // var scaleHeight = gantt.config.scale_height;
        // var textFilter = [
        //     "<div class='gantt-sub-header' style='line-height:" + scaleHeight / 2 + "px'>",
        //     "<div>Name</div>",
        //     "<div>Search: <input data-text-filter type='text' oninput='gantt.$doFilter(this.value)'></div>",
        //     "</div>"
        // ].join("");

        gantt.config.columns = [
            { name: "text", label: 'Name', tree: true, minWidth: 300, width: 300, template: this.myFunc },
            { name: "start_date", label: this.props.baselineDates ? 'Actual Start' : "BL Start", align: "center", width: 100 },
            { name: "end_date", label: this.props.baselineDates ? 'Actual Finish' : "BL Finish", align: "center", width: 100 },
            // { name: "duration", label: "Duration", align: "center" },
            // { name: "add", label: "", width: 44, minWidth: 125, width: 100 },
            {
                name: "action", label: "Action", template: function (task) {
                    return (
                        // `<Button data-action="edit" color="primary">Edit</Button>
                        task.rowType === 'task' ? `<span class="material-icons" style="margin: 5px;cursor:pointer" data-action="edit">edit_note</span>` : ''
                    );
                },
                width: 70
            }
        ]

        var filterValue = "";
        var delay;

        gantt.$doFilter = function (value) {
            // console.log(value, 'do filter')
            filterValue = value;
            clearTimeout(delay);
            delay = setTimeout(function () {
                gantt.render();
            }, 200)
        }
        gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
            // console.log(filterValue,'filtervalue before task')
            if (!filterValue) return true;

            var normalizedText = task.searchKey.toLowerCase();
            var normalizedValue = filterValue.toLowerCase();
            return normalizedText.indexOf(normalizedValue) > -1;
        });



        gantt.config.work_time = true;
        gantt.config.correct_work_time = true;



        // gantt.config.open_tree_initially = true;
        // gantt.config.initial_scroll = false;


        gantt.attachEvent("onTaskClick", (id, e) => {
            var button = e.target.closest("[data-action]")
            if (button) {
                var action = button.getAttribute("data-action");
                switch (action) {
                    case "edit":
                        // gantt.showLightbox(id);
                        // alert(id + ' - add your own code')
                        // console.log(id);
                        this.fetchTask(id)

                        break;
                    case "add":
                        gantt.createTask(null, id);
                        break;
                    case "delete":
                        gantt.confirm({
                            title: gantt.locale.labels.confirm_deleting_title,
                            text: gantt.locale.labels.confirm_deleting,
                            callback: function (res) {
                                if (res)
                                    gantt.deleteTask(id);
                            }
                        });
                        break;
                    default:
                        break;
                }
                return false;

            }
            return true;
        });

        var zoomConfig = {
            levels: [
                {
                    name: "day",
                    scale_height: 27,
                    min_column_width: 80,
                    scales: [
                        { unit: "day", step: 1, format: "%d %M" }
                    ]
                },
                {
                    name: "week",
                    scale_height: 50,
                    min_column_width: 50,
                    scales: [
                        {
                            unit: "week", step: 1, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%d %M");
                                var endDate = gantt.date.add(date, 6, "day");
                                var weekNum = gantt.date.date_to_str("%W")(date);
                                return "#" + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        },
                        { unit: "day", step: 1, format: "%j %D" }
                    ]
                },
                {
                    name: "month",
                    scale_height: 50,
                    min_column_width: 120,
                    scales: [
                        { unit: "month", format: "%F, %Y" },
                        { unit: "week", format: "Week #%W" }
                    ]
                },
                {
                    name: "quarter",
                    height: 50,
                    min_column_width: 90,
                    scales: [
                        { unit: "month", step: 1, format: "%M" },
                        {
                            unit: "quarter", step: 1, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%M");
                                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        }
                    ]
                },
                {
                    name: "year",
                    scale_height: 50,
                    min_column_width: 30,
                    scales: [
                        { unit: "year", step: 1, format: "%Y" }
                    ]
                }
            ]
        };



        // gantt.config.task_height = 10;
        gantt.config.row_height = 30;
        gantt.config.show_unscheduled = true;
        gantt.ext.zoom.init(zoomConfig);
        gantt.ext.zoom.setLevel("quarter");
        // console.log(gantt.ext.zoom)
        // console.log(gantt.ext.zoom.getLevels())
        gantt.clearAll();
        gantt.refreshData()
        gantt.init(this.ganttContainer);

        gantt.parse(tasks);
        gantt.showDate(new Date())
        // gantt.templates.timeline_cell_class = function (task, date) {
        //     // console.log(date, task)
        //     if (!gantt.isWorkTime({ date: date, task: task })) {
        //         return "weekend";
        //     }
        // };
        // gantt.dateFormat = {
        //     en: "%d-%m-%Y"
        // };
        gantt.templates.tooltip_text = function (start, end, task) {
            return task.text + "<br/>" + moment(task.start_date).format('DD/MM/YYYY') + " - " + moment(task.end_date).format('DD/MM/YYYY');
        };
    }

    myFunc = (task) => {
        // console.log(task)
        if (task.type === 'project')
            return "<div class='project'>" + task.text + "</div>";
        return task.text;
    }

    fetchTask(id) {
        let idArr = String(id).split('t');

        if (idArr[0] === "") {
            let id2 = Number(idArr[1]);
            // console.log(idArr, id2)
            let allData = this.props.allData;
            let value = null;
            // console.log(allData.task)
            for (let i = 0; i < allData.task.length; i++) {
                if (id2 === allData.task[i].id) {
                    value = allData.task[i]
                    break;
                }
            }

            if (value) {
                // this.value = value
                this.setState({ selectedTask: value })
                // console.log(this.value)
                this.setState({ menu: true });
            }

        }

    }
    handleClose = () => {
        this.setState({ modal: false })
    }

    handleChange = (event) => {
        let value = event.target.value
        this.setState({ selectedValue: value })
        // this.setScaleConfig(value);
        // gantt.render();
    }

    zoomIn() {
        gantt.ext.zoom.zoomIn()
    }

    zoomOut() {
        gantt.ext.zoom.zoomOut()
    }

    expandAll() {
        gantt.eachTask(function (task) {
            task.$open = true
        });
        gantt.render();
    }

    collapseAll() {
        gantt.eachTask(function (task) {
            task.$open = false
        });
        gantt.render();
    }

    userAdded = (newUser) => {
        // console.log('adding user in root data', this.state.selectedTask)
        let users = this.state.selectedTask.users;
        users.push(newUser);
        this.setState({ selectedTask: { ...this.state.selectedTask, users: users } })
    }

    search = (value) => {
        gantt.$doFilter(value)
    }

    render() {

        const toggleDrawer = (event) => {
            this.setState({ menu: false })
        };
        return (
            <>
                {/* <div>Search: <input data-text-filter type='text' onInput={(e)=>gantt.$doFilter(e.target.value)} /></div> */}
                <div
                    ref={(input) => { this.ganttContainer = input }}
                    style={{ width: '100%', height: '100%' }}
                ></div>

                <Drawer anchor={'right'} open={this.state.menu} onClose={toggleDrawer}>
                    <div style={{ width: window.innerWidth / 2 + 'px' }}>
                        {this.state.selectedTask.project ? <div>
                            <p style={{ margin: '15px', color: '#444', fontWeight: 'bold' }}>
                                {'Task : ' + this.state.selectedTask.decs ? this.state.selectedTask.decs : this.state.selectedTask.name}
                            </p>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Project Date</TableCell>
                                            <TableCell align="left">Start</TableCell>
                                            <TableCell align="left">Finish</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={1}>
                                            <TableCell component="th" scope="row">
                                                Baseline
                                            </TableCell>
                                            <TableCell align="left">{this.state.selectedTask.project.from_date ? moment(this.state.selectedTask.project.from_date).format('DD/MM/YYYY') : '-'}</TableCell>
                                            <TableCell align="left">{this.state.selectedTask.project.to_date ? moment(this.state.selectedTask.project.to_date).format('DD/MM/YYYY') : '-'}</TableCell>
                                        </TableRow>
                                        <TableRow key={2}>
                                            <TableCell component="th" scope="row">
                                                Actual/Expected
                                            </TableCell>
                                            <TableCell align="left">{this.state.selectedTask.project.t_act_exp_start ? moment(this.state.selectedTask.project.t_act_exp_start).format('DD/MM/YYYY') : '-'}</TableCell>
                                            <TableCell align="left">{this.state.selectedTask.project.t_act_exp_finish ? moment(this.state.selectedTask.project.t_act_exp_finish).format('DD/MM/YYYY') : '-'}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div> : null}
                        {/* <TaskLevelTabs value={this.value} team={[]} /> */}
                        {/* <TaskLevelDetails task={this.state.selectedTask} allUsers={this.props.allUsers} userAdded={this.userAdded}/> */}
                        <RoleVsResourceToggle task={this.state.selectedTask} allUsers={this.props.allUsers} userAdded={this.userAdded} teamOrProj={this.props.teamOrProj} />
                    </div>
                </Drawer>
            </>
        );
    }
}