import React, { Component } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import MaterialTable from 'material-table';
import moment from "moment";
import { Box, Button, TextField, IconButton, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { CsvBuilder } from 'filefy';
import { errorMessage } from '../../Helper/ErrorMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { AppBar, Dialog, Toolbar, DialogContent } from '@mui/material'
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import TimesheetReportTab from './TimesheetReportTab';
import { stringToDate } from '../../Helper/CommonThings';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));
var index = null

class TeamsTimesheets extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: false,
            startDate: new Date(),
            endDate: new Date(),
            weeksData: [],
            selectedWeeks: null,
            datesTitle: false,
            weeksTitle: false,
            userTimesheetReportDialog: false,
            selectedTeam: null,
            dateValue: [],
            company: props.company ? props.company : 0,
            location: props.location
        }
    }

    componentDidMount() {
        this.fetchOps()
        this.weekList()
    }

    fetchOps() {
        if (this.props.value) {
            this.fetchOps2(this.props.value[0], this.props.value[1], true)
        }
    }

    fetchOps1() {
        this.setState({
            isLoading: true,
            datesTitle: false,
            weeksTitle: false,
            selectedWeeks: null,
        })
        var date1 = moment(this.state.startDate).format('DD-MM-YYYY')
        var date2 = moment(this.state.endDate).format('DD-MM-YYYY')
        var url = "/bb/api/report/view/teams_timesheet_report/?start=" + date1 + "&end=" + date2
        if (this.props.uid) {
            url = "/bb/api/report/view/teams_timesheet_report/?start=" + date1 + "&end=" + date2 + "&uid=" + this.props.uid
        } else if (this.props.gid) {
            url = "/bb/api/report/view/teams_timesheet_report/?start=" + date1 + "&end=" + date2 + "&gid=" + this.props.gid
        }

        if (this.state.company && this.state.company !== 0) {
            url += "&company=" + this.state.company
        }

        if (this.state.location >= 0) {
            url += "&office_loc=" + this.state.location
        }

        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    // console.log((element.chargeable / (element.leave + element.sick + element.bank_holiday + element.chargeable + element.tenders + element.overheads)))
                    if (element.chargeable) {
                        element.chargable_percent = ((element.chargeable / (element.leave + element.sick + element.training + element.bank_holiday + element.chargeable + element.tenders + element.overheads)) * 100).toFixed(1) + "%"
                        element.chargable_percent_num = ((element.chargeable / (element.leave + element.sick + element.training + element.bank_holiday + element.chargeable + element.tenders + element.overheads)) * 100).toFixed(1)

                    } else {
                        element.chargable_percent = "0%"
                        element.chargable_percent_num = 0

                    }
                }
                // console.log(res.data)
                this.setState({ data: res.data, isLoading: false, datesTitle: true })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps2(date1_str, date2_str, weekData) {
        this.setState({
            isLoading: true,
            datesTitle: false,
            weeksTitle: false
        })
        var date1 = date1_str
        var date2 = date2_str
        var url = "/bb/api/report/view/teams_timesheet_report/?start=" + date1 + "&end=" + date2
        if (this.props.uid) {
            url = "/bb/api/report/view/teams_timesheet_report/?start=" + date1 + "&end=" + date2 + "&uid=" + this.props.uid
        } else if (this.props.gid) {
            url = "/bb/api/report/view/teams_timesheet_report/?start=" + date1 + "&end=" + date2 + "&gid=" + this.props.gid
        }

        if (weekData) {
            url += "&week_data=1"
        }

        if (this.state.company && this.state.company !== 0) {
            url += "&company=" + this.state.company
        }

        if (this.state.location >= 0) {
            url += "&office_loc=" + this.state.location
        }

        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    // console.log((element.chargeable / (element.leave + element.sick + element.bank_holiday + element.chargeable + element.tenders + element.overheads)))
                    if (element.chargeable) {
                        element.chargable_percent = ((element.chargeable / (element.leave + element.sick + element.training + element.bank_holiday + element.chargeable + element.tenders + element.overheads + element.non_billable)) * 100).toFixed(1) + "%"
                        element.chargable_percent_num = ((element.chargeable / (element.leave + element.sick + element.training + element.bank_holiday + element.chargeable + element.tenders + element.overheads + element.non_billable)) * 100).toFixed(1)

                    } else {
                        element.chargable_percent = "0%"
                        element.chargable_percent_num = 0

                    }
                }
                this.setState({ data: res.data, isLoading: false, weeksTitle: true })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps3() {
        if (!this.state.selectedWeeks) {
            this.props.enqueueSnackbar("Please select a week!", {
                variant: 'warning'
            });
        } else {
            this.fetchOps2(this.state.selectedWeeks.start, this.state.selectedWeeks.end, true)
        }
    }

    weekList() {
        var weeks_list = []
        var year_start = moment().subtract(1, 'years')
        var start_date = year_start

        while (start_date <= moment().add(1, 'week')) {

            weeks_list.push({
                name: moment(start_date).format('YYYY') + " Week " + (start_date.endOf('week').isoWeek()) + " (" + (start_date.startOf('week').format('DD-MM-YYYY') + " - "
                    + start_date.endOf('week').format('DD-MM-YYYY')) + ")",
                start: start_date.startOf('week').format('DD-MM-YYYY'),
                end: start_date.endOf('week').format('DD-MM-YYYY'),
            })
            start_date = start_date.endOf('week').add(1, 'day')
        }
        this.setState({
            weeksData: weeks_list.reverse()
        }, () => {
            if (this.props.value && this.props.value.length !== 0) {
                for (let i = 0; i < this.state.weeksData.length; i++) {
                    const element = this.state.weeksData[i];
                    if (element.start === this.props.value[0] && element.end === this.props.value[1]) {
                        this.setState({
                            selectedWeeks: element
                        })
                        index = i
                        break
                    }
                }

            }
        })
    }

    handleDateChange = (date) => {
        this.setState({
            startDate: date
        })
    }

    handleDateChange2 = (date) => {
        this.setState({
            endDate: date
        })
    }

    onSelectedWeeks = (event, values) => {
        if (values) {
            index = this.state.weeksData.findIndex((element) => element.name === values.name)
            this.setState({
                selectedWeeks: values,
                weeksTitle: false,
                startDate: stringToDate(values.start, "dd-mm-yyyy", "-"),
                endDate: stringToDate(values.end, "dd-mm-yyyy", "-")
            });
        } else {
            this.setState({
                selectedWeeks: null,
                weeksTitle: false,
                startDate: new Date(),
                endDate: new Date(),
            });
            index = null
        }
    }

    handleFront = () => {
        index = index + 1
        this.setState({ selectedWeeks: this.state.weeksData[index] }, () => {
            this.setState({
                startDate: stringToDate(this.state.selectedWeeks.start, "dd-mm-yyyy", "-"),
                endDate: stringToDate(this.state.selectedWeeks.end, "dd-mm-yyyy", "-")
            })
            this.fetchOps2(this.state.selectedWeeks.start, this.state.selectedWeeks.end, true)
        });
    }

    handleBack = () => {
        index = index - 1
        this.setState({ selectedWeeks: this.state.weeksData[index] }, () => {
            this.setState({
                startDate: stringToDate(this.state.selectedWeeks.start, "dd-mm-yyyy", "-"),
                endDate: stringToDate(this.state.selectedWeeks.end, "dd-mm-yyyy", "-")
            })
            this.fetchOps2(this.state.selectedWeeks.start, this.state.selectedWeeks.end, true)
        });
    }

    openReport = (rowData) => {
        if (this.state.data.length > 1) {
            this.setState({ selectedTeam: rowData })
            if (this.state.weeksTitle && this.state.selectedWeeks) {
                this.setState({ dateValue: [this.state.selectedWeeks.start, this.state.selectedWeeks.end] }, () => { this.setState({ userTimesheetReportDialog: true }) })
            } else if (this.props.value) {
                this.setState({ dateValue: this.props.value }, () => { this.setState({ userTimesheetReportDialog: true }) })
            } else if (this.state.datesTitle && this.state.startDate && this.state.endDate) {
                this.setState({ dateValue: [moment(this.state.startDate).format('DD-MM-YYYY'), moment(this.state.endDate).format('DD-MM-YYYY')] }, () => { this.setState({ userTimesheetReportDialog: true }) })
            }
        }
    }

    lastWeekComparison(item1, key) {
        let curr_week = item1[key]
        let last_week = item1["last_week_" + key]
        if (curr_week !== null && last_week !== null) {
            if (curr_week > last_week) {
                return <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'} >
                    <b>{item1[key]}% </b>
                    <Box display="flex" flexDirection="column" alignItems='center' height={'100%'} justifyContent={'center'} marginLeft={'10px'}>
                        <div class="up-arrow"></div>
                        <p style={{ fontSize: "10px", color: 'green', marginBottom: 0, marginTop: 0 }}>{(curr_week - last_week)}%</p>
                    </Box>
                </Box >
            } else if (curr_week < last_week) {
                return <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'} >
                    <b>{item1[key]}% </b>
                    <Box display="flex" flexDirection="column" alignItems='center' height={'100%'} justifyContent={'center'} marginLeft={'10px'}>
                        <p style={{ fontSize: "10px", color: 'red', marginBottom: 0, marginTop: 0 }}>{(last_week - curr_week)}%</p>
                        <div class="down-arrow"></div>
                    </Box>
                </Box>
            } else {
                return <b>{item1[key]}%</b>
            }
        } else {
            return null
        }
    }

    filterCompany(company) {
        let startDate = moment(new Date()).format('DD-MM-YYYY')
        let endDate = moment(new Date()).format('DD-MM-YYYY')
        let startDate1 = moment(this.state.startDate).format('DD-MM-YYYY')
        let endDate1 = moment(this.state.endDate).format('DD-MM-YYYY')
        if (startDate === startDate1 && endDate === endDate1 && !this.state.selectedWeeks) {
            this.setState({ company: company }, () => {
                this.fetchOps()
            })
        } else if (!this.state.selectedWeeks) {
            this.setState({ company: company }, () => {
                this.fetchOps1()
            })
        } else {
            this.setState({ company: company }, () => {
                this.fetchOps3()
            })
        }
    }

    filterLocation(location) {
        let startDate = moment(new Date()).format('DD-MM-YYYY')
        let endDate = moment(new Date()).format('DD-MM-YYYY')
        let startDate1 = moment(this.state.startDate).format('DD-MM-YYYY')
        let endDate1 = moment(this.state.endDate).format('DD-MM-YYYY')
        if (startDate === startDate1 && endDate === endDate1 && !this.state.selectedWeeks) {
            this.setState({ location: location }, () => {
                this.fetchOps()
            })
        } else if (!this.state.selectedWeeks) {
            this.setState({ location: location }, () => {
                this.fetchOps1()
            })
        } else {
            this.setState({ location: location }, () => {
                this.fetchOps3()
            })
        }
    }

    render() {

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    return (columnDef.render && columnDef.editable === 'never') ? (rowData[columnDef.field] + "%") : rowData[columnDef.field];
                    // return rowData[columnDef.field];
                })
            );
            const builder = new CsvBuilder("Teams Timesheet Report ( " + moment(this.state.startDate).format('DD-MM-YYYY') + " - " + moment(this.state.endDate).format('DD-MM-YYYY') + " ).csv");

            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };

        const handleDialogClose = () => {
            this.setState({ userTimesheetReportDialog: false, selectedTeam: null })
        };

        return (
            <div style={{}}>
                {(!this.props.value) ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Box
                            display="flex"
                            justifyContent={'center'}
                            alignItems="center"
                            p={1}
                            m={1}
                        >

                            <Box p={1}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Start Date"
                                    format="dd/MM/yyyy"
                                    value={this.state.startDate}
                                    onChange={this.handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Box>

                            <Box p={1}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog1"
                                    label="End Date"
                                    format="dd/MM/yyyy"
                                    value={this.state.endDate}
                                    onChange={this.handleDateChange2}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Box>

                            <Box p={1}>
                                <Button variant="contained" color="primary" onClick={() => this.fetchOps1()}>Fetch Data</Button>
                            </Box>

                            <Autocomplete
                                // multiple
                                id="checkboxes-tags-demo"
                                options={this.state.weeksData}
                                value={this.state.selectedWeeks}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={this.onSelectedWeeks}
                                // fullWidth
                                style={{ width: 400, marginLeft: '10px' }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Select Weeks" placeholder="Favorites" />
                                )}
                            />
                            <Button style={{ marginLeft: '10px' }} variant="contained" color="primary" onClick={() => this.fetchOps3()}>Fetch Data</Button>
                            {/* </Box> */}

                        </Box>


                    </MuiPickersUtilsProvider>
                ) : (null)}

                <MaterialTable
                    title={(this.state.weeksTitle && this.state.selectedWeeks) ?
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <Box>
                                <b>{this.state.selectedWeeks.name}</b>
                            </Box>
                            <Box marginLeft={'10px'}>
                                <Button
                                    size='small'
                                    variant='contained'
                                    disabled={!index ? true : false}
                                    color='primary'
                                    onClick={() => this.handleFront()}
                                    startIcon={<ArrowBackIosIcon fontSize='small' />}>
                                    Prev week
                                </Button>
                            </Box>
                            <Box marginLeft={'10px'}>
                                <Button
                                    size='small'
                                    variant='contained'
                                    disabled={this.state.weeksData.length === (index + 1)}
                                    color='primary'
                                    onClick={() => this.handleBack()}
                                    endIcon={<ArrowForwardIosIcon fontSize='small' />}>
                                    next week
                                </Button>
                            </Box>
                        </Box> : (this.state.datesTitle && this.state.startDate && this.state.endDate) ? (moment(this.state.startDate).format('DD-MM-YYYY') + " - " + moment(this.state.endDate).format('DD-MM-YYYY')) : null}
                    columns={[
                        { title: 'Team ID', field: 'id' },
                        {
                            title: 'Name', field: 'name',
                        },
                        {
                            title: "Total Submitted Hours (A)", field: "total_timesheet_submitted",
                        },

                        {
                            title: 'Leaves', field: 'total_leave',

                        },
                        // {
                        //     title: 'Sick', field: 'sick',

                        // },
                        {
                            title: 'Bank Holiday', field: 'bank_holiday',

                        },
                        {
                            title: 'Available Hours (B)', field: 'available_hours',

                        },
                        {
                            title: 'Training', field: 'training',

                        },
                        {
                            title: 'Non-Billable', field: 'overheads',

                        },
                        {
                            title: 'Billable', field: 'chargeable',

                        },
                        {
                            title: 'Non-Billable Projects', field: 'non_billable',

                        },

                        {
                            title: 'Tenders', field: 'tenders',

                        },

                        {
                            title: 'Productivity % on A', field: 'productivity_a', cellStyle: {
                                textAlign: 'center'
                            }, editable: 'never',
                            // render: rowData => rowData.productivity_a + "%"
                            render: rowData => this.lastWeekComparison(rowData, "productivity_a")
                        },
                        {
                            title: 'Productivity % on B', field: 'productivity_b', cellStyle: {
                                textAlign: 'center'
                            }, editable: 'never',
                            render: rowData => this.lastWeekComparison(rowData, "productivity_b")
                        },
                        {
                            title: 'Billability % on A', field: 'billability_a', cellStyle: {
                                textAlign: 'center'
                            }, editable: 'never',
                            render: rowData => this.lastWeekComparison(rowData, "billability_a")
                        },
                        {
                            title: 'Billability % on B', field: 'billability_b', cellStyle: {
                                textAlign: 'center'
                            }, editable: 'never',
                            render: rowData => this.lastWeekComparison(rowData, "billability_b")
                        },
                        // {
                        //     title: 'Utilisation (Chargeable)', field: 'chargable_percent', defaultSort: 'desc',
                        //     customSort: (a, b) => a.chargable_percent_num - b.chargable_percent_num

                        // },

                    ]}
                    onRowClick={(event, rowData) => {
                        this.openReport(rowData)
                    }}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    // onFilterChange={(filters) => {
                    //     console.log('onFilterChange', filters);
                    // }}
                    options={{
                        padding: "dense",
                        search: true,
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        filtering: true,
                        pageSizeOptions: [10, 25, 50],
                        paging: false,
                        exportButton: { csv: true },
                        exportCsv: (columns, data) => {
                            exportCsv(columns, data)
                        },
                        maxBodyHeight: window.innerHeight - 355,
                    }}
                />

                <Dialog disableEnforceFocus fullScreen open={this.state.userTimesheetReportDialog} onClose={handleDialogClose} TransitionComponent={Transition}>
                    <AppBar position='relative'  >
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {this.state.selectedTeam ? this.state.selectedTeam.name : null}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <TimesheetReportTab gid={this.state.selectedTeam?.id} dateValue={this.state.dateValue} companyFilter={this.state.company} locFilter={this.state.location} />
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
}

export default withSnackbar(TeamsTimesheets)