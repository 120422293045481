import { Card, Grid, Paper } from '@material-ui/core';
import { Box, Button, CardContent, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Slide, TextField, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import Service from '../../Networking/networkutils';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getCookie } from '../../Helper/CookieHelper';
import CloseIcon from '@mui/icons-material/Close';
import MaterialTable from 'material-table';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';
import EditTaskDetails from '../../EditTaskDetails/EditTaskDetails';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

const token = getCookie("bb_ts_token");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AWPIMDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            isLoading: true,
            isEdit: false,
            updateDisabled: false,
            openHistory: false,
            historyData: [],
            openEdit: false,
            selectedTask: null
        };
        this.refreshData = this.refreshData.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        if (!this.props.data) {
            return
        }
        Service.get(`/bb/api/p6/activity/update/get_p6_activity_with_tasks/?id=${this.props.data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let data = res.data
                data.copy_reason_for_change = data.reason_for_change
                data.user_has_access = data.list_task.some(task => task.user_has_access)
                this.setState({
                    data: data,
                    isLoading: false
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    onClose = () => {
        let updated_act_exp_start = this.state.data.updated_act_exp_start
        let updated_act_exp_finish = this.state.data.updated_act_exp_finish
        var fields = ''
        if ((!updated_act_exp_start && !updated_act_exp_finish) || (updated_act_exp_start && updated_act_exp_finish)) {
            this.props.handleClose()
        } else {
            fields = !updated_act_exp_start ? 'Expected Start' : 'Expected Finish'
            this.props.enqueueSnackbar(`Please update ${fields}`, { variant: 'warning' })
        }
    }

    discardChanges = () => {
        let data = this.state.data
        data.reason_for_change = data.copy_reason_for_change
        this.setState({ data, isEdit: false })
    }

    handleReasonChange = (e) => {
        let data = this.state.data
        data.reason_for_change = e.target.value
        this.setState({
            data: data
        })
    }

    saveChanges = () => {
        let data = this.state.data

        if (!data.reason_for_change) {
            this.props.enqueueSnackbar("Please add reason for change", { variant: 'warning' })
        } else {
            Service.patch(`/bb/api/p6/activity/update/update_p6_activity_data/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    item_id: data.id,
                    reason_for_change: data.reason_for_change
                }
            })
                .then(res => {
                    this.setState({ data: data, isEdit: false }, () => {
                        this.getData()
                        if (this.props.refreshChart) {
                            this.props.refreshChart()
                        }
                    })
                    this.props.enqueueSnackbar("Updated Successfully", { variant: 'success' })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    setDefault = (data) => {
        Service.patch('/bb/api/p6/activity/update/update_p6_activity_date/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                if (this.props.refreshChart) {
                    this.props.refreshChart()
                }
                this.getData()
                this.props.enqueueSnackbar('Data Updated Successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    checkConditions = (data) => {
        let updated_act_exp_start = this.state.data.updated_act_exp_start
        let updated_act_exp_finish = this.state.data.updated_act_exp_finish
        var fields = ''
        if ((!updated_act_exp_start && !updated_act_exp_finish) || (updated_act_exp_start && updated_act_exp_finish)) {
            this.props.checkConditions('update', data)
        } else {
            fields = !updated_act_exp_start ? 'Expected Start' : 'Expected Finish'
            this.props.enqueueSnackbar(`Please update ${fields}`, { variant: 'warning' })
        }
    }

    updateComp = () => {
        this.forceUpdate()
    }

    getUpdates = () => {
        this.setState({ updateDisabled: true })
        Service.get(`/bb/api/p6/activity/update/update_p6_data/?p6_id=${this.props.data.id}`, {
            headers: { Authorization: "Token " + token, },
        })
            .then(res => {
                let response = res.data
                var fields = ''
                if (response.default_baseline_comp_percnt) {
                    fields = 'Complete %'
                }
                if (response.default_act_exp_start) {
                    fields = fields ? fields + ', Expected Start' : 'Expected Start'
                }
                if (response.default_act_exp_finish) {
                    fields = fields ? fields + ', Expected Finish' : 'Expected Finish'
                }
                if (fields) {
                    this.props.enqueueSnackbar(`${fields} Updated Successfully!`, { variant: 'success' })
                    if (this.props.refreshChart) {
                        this.props.refreshChart()
                    }
                } else {
                    this.props.enqueueSnackbar('No updates found', { variant: 'info' })
                }
                this.setState({ updateDisabled: false })
                this.getData()
            })
            .catch(error => {
                console.log(error)
                this.setState({ updateDisabled: false })
                errorMessage(this.props, error)
            });
    }

    getHistory = () => {
        Service.get(`/bb/api/p6/activity/update/get_p6_activity_data/?p6_id=${this.props.data.id}`, {
            headers: { Authorization: "Token " + token, },
        })
            .then(res => {
                this.setState({ historyData: res.data, openHistory: true })
            })
            .catch(error => {
                console.log(error)
                this.setState({ updateDisabled: false })
                errorMessage(this.props, error)
            });
    }

    refreshData = () => {
        this.getData()
        this.setState({ openEdit: false })
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", width: "100%" }}>
                            <CircularProgress color='primary' />
                        </Box>
                        :
                        this.state.data ?
                            <Box sx={{ margin: 2, display: 'flex', flexDirection: "column" }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="h6" >
                                            {this.state.data.name + " (" + this.state.data.p6_id + ") "}
                                        </Typography>
                                    </Box>

                                    <Tooltip title="History">
                                        <IconButton size='small' onClick={() => this.getHistory()}>
                                            <HistoryIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Close">
                                        <IconButton size='small' onClick={() => this.onClose()}>
                                            <CloseIcon fontSize='small' color='error' />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                <Paper elevation={3} sx={{ padding: 2, marginTop: '10px' }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', width: '100%', padding: '10px' }}>
                                        <Box display={'flex'} flexDirection='column' justifyContent={'center'} padding="5px">
                                            <Box display={'flex'} flexDirection='row' alignItems={'center'} >
                                                <b>P6 Baseline Start:</b>&nbsp;{this.state.data.from_date ? moment(this.state.data.from_date).format('DD-MM-YYYY') : "NA"}
                                            </Box>
                                            <Box display={'flex'} flexDirection='row' marginTop='5px' alignItems={'center'} >
                                                <b>P6 Baseline Finish:</b>&nbsp;{this.state.data.to_date ? moment(this.state.data.to_date).format('DD-MM-YYYY') : "NA"}
                                            </Box>
                                        </Box>
                                        <Box display={'flex'} flexDirection='row' alignItems={'center'} >
                                            <b>P6 %:</b>&nbsp;{Number(this.state.data.percent_complete) ? this.state.data.percent_complete + '%' : "NA"}
                                        </Box>
                                        <Box display={'flex'} flexDirection='column' justifyContent={'center'} padding="5px">
                                            <Box display={'flex'} flexDirection='row' alignItems={'center'} >
                                                <b>P6 Expected Start:</b>&nbsp;{this.state.data.act_exp_start ? moment(this.state.data.act_exp_start).format('DD-MM-YYYY') : null}
                                            </Box>
                                            <Box display={'flex'} flexDirection='row' marginTop='5px' alignItems={'center'} >
                                                <b>P6 Expected Finish:</b>&nbsp;{this.state.data.act_exp_finish ? moment(this.state.data.act_exp_finish).format('DD-MM-YYYY') : null}
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', width: '100%', marginTop: '5px', padding: '10px' }}>
                                        <Box display={'flex'} flexDirection='row' alignItems={'center'} >
                                            <b>Updated %:</b>&nbsp;{this.state.data.updated_comp_percnt !== null ? this.state.data.updated_comp_percnt + '%' : "NA"}
                                        </Box>
                                        <Box display={'flex'} flexDirection='row' alignItems={'center'} >
                                            <b>Updated Expected Start:</b>&nbsp;{this.state.data.updated_act_exp_start ? moment(this.state.data.updated_act_exp_start).format('DD-MM-YYYY') : "NA"}
                                        </Box>
                                        <Box display={'flex'} flexDirection='row' marginTop='5px' alignItems={'center'} >
                                            <b>Updated Expected Finish:</b>&nbsp;{this.state.data.updated_act_exp_finish ? moment(this.state.data.updated_act_exp_finish).format('DD-MM-YYYY') : "NA"}
                                        </Box>
                                    </Box>


                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center', marginTop: '2px', padding: '10px' }}>
                                        {this.state.data.updated_by_id ?
                                            <Box sx={{ flexGrow: 1 }}>
                                                Last updated by &nbsp;<b>{this.state.data.updated_by_name}</b>&nbsp; on {moment(this.state.data.updated_on).utc().format('DD-MM-YYYY HH:mm:ss')}
                                            </Box> : null}
                                        <Button disabled={this.state.updateDisabled} startIcon={<TrackChangesIcon fontSize='small' />} size='small' variant='contained' color='primary' onClick={() => this.getUpdates()}>Update as Configured</Button>
                                    </Box>



                                    {this.props.data?.is_linked ?
                                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'center,', width: '100%', marginTop: '2px', padding: '10px' }}>
                                            <Box sx={{ marginBottom: '5px' }}>
                                                <b>Reason for Change:</b>
                                            </Box>
                                            {!this.state.data.user_has_access ?
                                                <Box display="flex" flexDirection='row' width='100%' alignItems={'center'}>
                                                    <TextField
                                                        size='small'
                                                        disabled
                                                        fullWidth
                                                        label="Reason"
                                                        variant="outlined"
                                                        value={this.state.data?.reason_for_change}
                                                        multiline
                                                        rows={2} />
                                                </Box>
                                                : this.state.isEdit ?
                                                    <Box display="flex" flexDirection='row' width='100%' alignItems={'center'}>
                                                        <TextField
                                                            size='small'
                                                            fullWidth
                                                            label="Reason"
                                                            variant="outlined"
                                                            value={this.state.data?.reason_for_change}
                                                            onChange={(e) => { this.handleReasonChange(e) }}
                                                            multiline
                                                            rows={2} />
                                                        <Box>
                                                            <Tooltip title="Save Changes">
                                                                <IconButton size='small'>
                                                                    <CheckIcon fontSize="small" onClick={(e) => {
                                                                        this.saveChanges()
                                                                    }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                        <Box>
                                                            <Tooltip title="Cancel Changes">
                                                                <IconButton size='small' onClick={(e) => {
                                                                    this.discardChanges()
                                                                }}>
                                                                    <CloseIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <Box flexWrap={'wrap'} width='100%' onClick={(e) => { this.setState({ isEdit: !this.state.isEdit }) }} display={'flex'} flexDirection={'row'} alignItems='center' style={{ cursor: 'pointer', marginTop: '5px', boxSizing: 'border-box', border: '2px dotted', borderRadius: '10px', height: 50, width: '100%', padding: '5px' }}>
                                                        {!this.state.data?.reason_for_change ? '' : this.state.data?.reason_for_change}
                                                    </Box>}
                                        </Box> : null}
                                </Paper>
                                {this.props.data?.is_linked ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", width: "100%", marginTop: '10px' }}>
                                        <Box sx={{ marginBottom: '5px', fontSize: '18px' }}>
                                            <b>PaRaM Tasks</b>
                                        </Box>
                                        <Grid container spacing={2} sx={{ height: window.innerHeight, overflowY: 'auto' }}>
                                            {this.state.data.list_task.map((task, index) => {
                                                return (
                                                    <Grid item xs={12} sm={6} key={index}>
                                                        <Card elevation={10} sx={{ padding: 2 }}>
                                                            <CardContent>
                                                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                                                                    <Box sx={{ fontSize: '12px' }}>
                                                                        <b>{task.wbs_name}</b>
                                                                    </Box>
                                                                    <Box sx={{ fontSize: '12px' }}>
                                                                        <b>{task.ds_name}</b>
                                                                    </Box>
                                                                    <Box sx={{ fontSize: '16px', display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <b>{task.name}</b>
                                                                        </Box>
                                                                        <IconButton size='small' onClick={() => this.setState({ openEdit: true, selectedTask: JSON.parse(JSON.stringify(task)) })}>
                                                                            <EditIcon fontSize='small' />
                                                                        </IconButton>
                                                                    </Box>
                                                                    <Box sx={{ margin: 1 }}>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                            <b>Complete %:</b>&nbsp;&nbsp;
                                                                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                                                {task.comp_percnt !== null ? task.comp_percnt + '%' : 'NA'}
                                                                                <Checkbox
                                                                                    color='primary'
                                                                                    size='small'
                                                                                    id='default_baseline_percentage'
                                                                                    disabled={task.comp_percnt === null || !task.user_has_access}
                                                                                    checked={task.default_baseline_comp_percnt}
                                                                                    onChange={(e) => this.setDefault({ item_id: task.link_id, 'default_baseline_comp_percnt': !task.default_baseline_comp_percnt })} />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2px' }}>
                                                                            <b>Expected Start:</b>&nbsp;&nbsp;
                                                                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                                                {!task.act_exp_start ? "NA" : moment(task.act_exp_start).format("DD-MM-YYYY")}
                                                                                <Checkbox
                                                                                    size='small'
                                                                                    color='primary'
                                                                                    id='default_act_exp_start'
                                                                                    checked={task.default_act_exp_start}
                                                                                    disabled={!task.act_exp_start || !task.user_has_access}
                                                                                    onChange={(e) => this.setDefault({ item_id: task.link_id, 'default_act_exp_start': !task.default_act_exp_start })} />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2px' }}>
                                                                            <b>Expected Finish:</b>&nbsp;&nbsp;
                                                                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                                                {!task.act_exp_finish ? 'NA' : moment(task.act_exp_finish).format("DD-MM-YYYY")}
                                                                                <Checkbox
                                                                                    color='primary'
                                                                                    size='small'
                                                                                    id='default_act_exp_finish'
                                                                                    checked={task.default_act_exp_finish}
                                                                                    disabled={!task.act_exp_finish || !task.user_has_access}
                                                                                    onChange={(e) => this.setDefault({ item_id: task.link_id, 'default_act_exp_finish': !task.default_act_exp_finish })} />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                )
                                            })
                                            }
                                        </Grid>
                                    </Box> : null}
                            </Box > :
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", width: "100%" }}>
                                <h3>No Data Found</h3>
                            </Box>
                }

                <Dialog
                    open={this.state.openHistory}
                    fullWidth={true}
                    maxWidth='xl'
                    onClose={() => this.setState({ openHistory: false })}
                    TransitionComponent={Transition}>
                    <DialogTitle>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Typography variant='h6' style={{ flexGrow: 1 }}>History of {this.state.data?.name + " (" + this.state.data?.p6_id + ") "}</Typography>
                            <IconButton size='small' onClick={() => this.setState({ openHistory: false })}>
                                <CloseIcon fontSize='small' color='error' />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent >
                        <MaterialTable
                            columns={[
                                // { title: 'Name', field: 'name' },
                                // { title: 'P6 ID', field: 'p6_id' },
                                {
                                    title: 'Imported On', field: 'date_created',
                                    render: rowData => rowData.date_created ? moment(rowData.date_created).utc().format('DD-MM-YYYY HH:mm:ss') : ''
                                }, {
                                    title: 'Complete %', field: 'percent_complete',
                                    render: rowData => rowData.percent_complete !== null ? rowData.percent_complete + '%' : "NA"
                                },
                                {
                                    title: 'Expected Start', field: 'act_exp_start',
                                    render: rowData => rowData.act_exp_start ? moment(rowData.act_exp_start).format('DD-MM-YYYY') : "NA"
                                }, {
                                    title: 'Expected Finish', field: 'act_exp_finish',
                                    render: rowData => rowData.act_exp_finish ? moment(rowData.act_exp_finish).format('DD-MM-YYYY') : "NA"
                                }, {
                                    title: 'Updated %', field: 'updated_comp_percnt',
                                    render: rowData => rowData.updated_comp_percnt !== null ? rowData.updated_comp_percnt + '%' : "NA"
                                }, {
                                    title: 'Updated Expected Start', field: 'updated_act_exp_start',
                                    render: rowData => rowData.updated_act_exp_start ? moment(rowData.updated_act_exp_start).format('DD-MM-YYYY') : "NA"
                                }, {
                                    title: 'Updated Expected Finish', field: 'updated_act_exp_finish',
                                    render: rowData => rowData.updated_act_exp_finish ? moment(rowData.updated_act_exp_finish).format('DD-MM-YYYY') : "NA"
                                }, {
                                    title: 'Updated By', field: 'updated_by_id',
                                    render: rowData => rowData.updated_by_id ? rowData.updated_by_last_name + ", " + rowData.updated_by_first_name : "NA"
                                }, {
                                    title: 'Updated On', field: 'updated_on',
                                    render: rowData => rowData.updated_on ? moment(rowData.updated_on).utc().format('DD-MM-YYYY HH:mm:ss') : ''
                                }, {
                                    title: 'Reason for Change', field: 'reason_for_change',
                                },
                            ]}
                            data={this.state.historyData}
                            options={{
                                search: false,
                                paging: false,
                                filtering: true,
                                sorting: true,
                                toolbar: false,
                            }}
                        />
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.openEdit}
                    fullWidth={true}
                    maxWidth='xs'
                    onClose={() => this.setState({ openEdit: false })}
                    TransitionComponent={Transition}>
                    <DialogTitle>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Typography variant='h6' style={{ flexGrow: 1 }}>{this.state.selectedTask?.name}</Typography>
                            <IconButton size='small' onClick={() => this.setState({ openEdit: false })}>
                                <CloseIcon fontSize='small' color='error' />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent >
                        <EditTaskDetails data={this.state.selectedTask} refreshData={this.refreshData} />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(AWPIMDrawer);